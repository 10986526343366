import * as THREE from 'three'
import Constants from "../../Constants.js"
import { getObjectFromRootByName, getRootNodeFromObject } from "../../HelperFunctions"
export default class transformSnappingManager {

    /**
     * Struct that contains references to all the event callbacks for this module.
     */
    events = {
        scope : null,

        onMouseDown () {
            this.scope.#onMouseDown();
        },
        onMouseMove () {
            this.scope.#onMouseMove();
        },
        onMouseUp () {
            this.scope.#onMouseUp();
        }
    }

    /**
     * Snap Tool is used to Snap object to point / surface
     * @param {ObjectPlacementManager} objectPlacementManager - The object placement manager instance.
     * @param {SceneCreator} sceneCreator - The main scene creator instance.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {THREE.Vector2} mouse - Reference to vector2 containing mouse position in NDC (normalized device coordinates). 
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(objectPlacementManager, sceneCreator, scene, mouse, managersDict) {
        this.sceneCreator = sceneCreator;
        this.objectPlacementManager = objectPlacementManager;
        this.mouse = mouse;
        this.scene = scene;
        this.events.scope = this;
        this.setupRequiredManagers(managersDict);
        this.intersectableObjects = null;
        this.enabled = false;
    }

    /**
     * Setup all helper managers required in this module
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    setupRequiredManagers(managersDict) {
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
    }

    /**
     * Set list of intersectable objects
     */
    setIntersectableObjects = () => {
        const selection = this.objectPlacementManager.getSelection();
        this.intersectableObjects = this.intersectableObjects = this.scene.children.filter( ( item ) => { return ( item != selection.object && item.type == "Scene" && !(item.userData && item.userData.isGrid) ) } );
    }

    /**
     * Enable / disable Snap Point mode
     * @param {Boolean} state - The bool to enable snap to point mode 
     */
    enableSnapToPointMode(state) {
        this.enabled = state;
        if (state) {
            this.setIntersectableObjects();
        }
        else {
            this.disposeTransformSnappingManager();
        }
        
    }

    /**
     * Dispose of the manager
     */
    disposeTransformSnappingManager() {
        if (this.currentDot) {
            this.sceneCreator.disposeScene(this.currentDot);
            this.scene.remove(this.currentDot);
            this.currentDot = null;
        }
    }

    /**
     * Create a dot geometry to highlight the point where the pointer is in scene for snap to point mode
     * @param {THREE.Vector3} position 
     */
    createDot(position) {
        const dotGeometry = new THREE.SphereGeometry(0.007, 16, 16);
        const dotMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true, depthTest: false});
        let dot = new THREE.Mesh(dotGeometry, dotMaterial);
        dot.position.copy(position);
        dot.renderOrder = 10;
        const cameraPosition = this.sceneCreator.activeCamera.position.clone();
        const scale = cameraPosition.distanceTo(dot.position);
        dot.scale.set(scale, scale, scale);
        dot.userData.sourcePosition = cameraPosition;
        return dot;
    }

    /**
     * Update the position of the indicator dot in the scene
     * @param {THREE.Geometry} dot - The dot geometry
     * @param {THREE.Vector3} position - The position to move the dot to
     */
    updateDot(dot, position) {
        dot.position.copy(position);
        const cameraPosition = this.sceneCreator.activeCamera.position.clone();
        const scale = cameraPosition.distanceTo(dot.position);
        dot.scale.set(scale, scale, scale);
        dot.userData.sourcePosition = cameraPosition;
    }

    /**
     * Snap to surface handler
     */
    snapToSurface = () => {
        this.setIntersectableObjects();
        const selection = this.objectPlacementManager.getSelection();
        const parent = selection.object.parent;
        selection.refreshSelectionTransform();
        this.objectPlacementManager.updateParent(selection.object, this.scene);
        selection.object.updateMatrixWorld();
        let assetObj = getObjectFromRootByName(selection.object, selection.object.name) || selection.object;
        let center = new THREE.Box3().setFromObject(assetObj).getCenter().clone();
        this.objectPlacementManager.updateParent(selection.object, parent);
        selection.object.updateMatrixWorld();
        let placementInfo = this.getSnapToSurfaceIntersection(center);
        if (placementInfo) {
            placementInfo.intersectionPoint = new THREE.Vector3(selection.worldPosition.x, placementInfo.intersectionPoint.y, selection.worldPosition.z);    
            this.moveToPlacement(placementInfo);
        }
    }

    /**
     * Fetch the vertical intersection for snap to surface
     * @param {THREE.Vector3} originPosition - The position to cast the intersection ray from 
     */
    getSnapToSurfaceIntersection(originPosition) {
        const raycastDir = new THREE.Vector3(0,-1,0);
        let raycastIntersections = this.raycastManager.setAndIntersectAll(originPosition, raycastDir, this.intersectableObjects);
        let placementInfo = this.getPlacementInfoFromIntersections(raycastIntersections);
        return placementInfo;
    }

    /**
     * Fetch intersection for snap to point mode
     */
    getSnapToPointIntersection() {
        let raycastPoint = new THREE.Vector2().copy(this.mouse);
        let raycastIntersections = this.raycastManager.setFromCameraAndIntersect(raycastPoint, this.sceneCreator.activeCamera, 
            this.intersectableObjects, true);
        let placementInfo = this.getPlacementInfoFromIntersections(raycastIntersections);
        return placementInfo;
    }

    /**
     * Fetch and parse raycast intersection results
     * @param {List} raycastIntersections - The list of raycast results
     */
    getPlacementInfoFromIntersections = (raycastIntersections) => {
        let placementInfo = null;
        if (raycastIntersections && raycastIntersections.length > 0) {
            raycastIntersections = this.objectPlacementManager.filterIntersectionResults(raycastIntersections);
            if (raycastIntersections && raycastIntersections.length > 0) {
                let raycastResult = raycastIntersections[0];
                placementInfo = {
                    parent: null,
                    intersectionPoint: null
                }
                placementInfo.intersectionPoint = raycastResult.point;
                if (raycastResult.object.userData.isSceneAsset) {
                    placementInfo.parent = getRootNodeFromObject(this.scene, raycastResult.object );
                }
                else {
                    placementInfo.parent = this.scene;
                }
            }
        }
        return placementInfo;
    }

    /**
     * Move the object to the new placement
     * @param {Object} placementInfo - The object containing placement details to move the object to
     */
    moveToPlacement = (placementInfo) => {
        this.objectPlacementManager.processJump(placementInfo.intersectionPoint, placementInfo.parent, true);
    }

    /**
     * Capture mouse down event
     */
    #onMouseDown = () => {
        this.active = true;
    }

    /**
     * Capture mouse move event 
     */
    #onMouseMove = () => {
        this.currentPlacement = this.getSnapToPointIntersection();
        if (this.currentPlacement) {
            if (!this.currentDot) {
                this.currentDot = this.createDot(this.currentPlacement.intersectionPoint);
                this.scene.add(this.currentDot);
            }
            else {
                this.updateDot(this.currentDot, this.currentPlacement.intersectionPoint);
            }
        }
        else {
            this.disposeTransformSnappingManager();
        }
    }

    /**
     * Capture mouse up event
     */
    #onMouseUp = () => { 
        if (this.active) {
            if (this.currentPlacement) {
                this.moveToPlacement(this.currentPlacement);
            }
            this.objectPlacementManager.setSnapToPointMode(false);
        }
        this.active = false;
    }
}