import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Carousel, Tooltip, Card, message, Image } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, FullscreenOutlined, HeartOutlined,
    HeartFilled } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import ImageZoomAdmin from '../ImageZoomAdmin';
import ImageViewerContext from '../../ContextFiles/ImageViewerContext';
import FileConstants from '../../../../FileConstants';
import './ImageViewer.scss';

const image_style = { objectFit: "contain", width: "100%", height: "70vh" };
const image_style_blur = { objectFit: "contain", width: "100%", height: "70vh", "filter": "blur(3px)" };

const ImageListType = ({ imagesArray, getImageFromSrc, uniqueFilename, selectImage }) => {

    return (
        <>
            {imagesArray.map((image, index) => {
                const imageUrl = getImageFromSrc(image, true);
                return (
                    <Card className={`product-img-grid-card display-flex ${uniqueFilename === image.unique_filename ? 'selected' : ''}`}
                        onClick={() => selectImage(image.unique_filename)} key={index}>
                        <img className='product-img-grid-img' src={imageUrl} alt={image.camera_name} style={{ objectFit: 'contain' }} />
                    </Card>
                );
            })}
        </>
    );
};

const ImageViewerCarousel = (props) => {
    const { type, selectedImg, imgAccessedFromCarousel, setImgAccessedFromCarousel, handleImageNameUpdate,resetRenderNameField,
        handleAutoWhiteSwitch, image_list, renderFlagState, renderInProgressTooltip, getImageFromSrc, handleDelete, handleDownload,
        renderCollaborationTooltip, expandImage, changeVisibility, image_visible, white_balance_renders_switch, renderReportButton,
        setSelectedImgFromCarousel, handleGenerateHighResImage, render_generation, isImageVisible, handleFavorite} = useContext(ImageViewerContext);

    const [currentImg, setCurrentImg] = useState(null);
    const [uniqueFilename, setUniqueFilename] = useState(null);
    const [carouselIndex, setCarouselIndex] = useState(0)
    let carouselRef = React.createRef();

    useEffect(() => {
        setCurrentImg(selectedImg);
        setUniqueFilename(selectedImg?.unique_filename);
    }, [selectedImg, ...Object.values(white_balance_renders_switch), ...Object.values(image_visible)]);


    useEffect(() => {
        if (imgAccessedFromCarousel === false && uniqueFilename && currentImg && image_list?.length > 0) {
            const imageIndex = image_list.findIndex((img) => img.unique_filename === uniqueFilename);
            if (carouselRef && imageIndex !== -1) {
                carouselRef.goTo(imageIndex, true);
                setCurrentImg(image_list[imageIndex]);
                setCarouselIndex(imageIndex)
            }
        }
    }, [currentImg, uniqueFilename, imgAccessedFromCarousel, image_list, carouselRef]);


    const selectImage = (unique_filename) => {
        resetRenderNameField()
        setUniqueFilename(unique_filename);
        const imageIndex = image_list.findIndex((img) => img.unique_filename === unique_filename);
        if (imageIndex !== -1 && carouselRef && image_list?.length > 0) {
            carouselRef.goTo(imageIndex, true);
            setSelectedImgFromCarousel(image_list[imageIndex]);
            setCarouselIndex(imageIndex)
            setCurrentImg(image_list[imageIndex]);
            setImgAccessedFromCarousel(true);
        }
    };

    const onChangeCarousel = (before, to) => {
        if (image_list?.length > 0) {
            if (before == (image_list.length - 1) && to == 0 && carouselRef) {
                carouselRef.goTo(0, true);
                const selectedImage = image_list[to];
                setCarouselIndex(to)
                setSelectedImgFromCarousel(selectedImage);
                setCurrentImg(selectedImage);
                setUniqueFilename(selectedImage.unique_filename);
                setImgAccessedFromCarousel(true);
            } else if (before == 0 && to == (image_list.length - 1) && carouselRef) {
                carouselRef.goTo(image_list.length - 1, true);
                const selectedImage = image_list[image_list.length - 1];
                setCarouselIndex(image_list.length - 1)
                setSelectedImgFromCarousel(selectedImage);
                setCurrentImg(selectedImage);
                setUniqueFilename(selectedImage.unique_filename);
                setImgAccessedFromCarousel(true);
            }
        }
    };

    const next = () => {
        resetRenderNameField()
        if (image_list?.length > 0) {
            let imageIndex = image_list.findIndex((img) => img.unique_filename === uniqueFilename);
            if ((imageIndex + 1) == image_list.length) {
                selectImage(image_list[0].unique_filename);
                setCarouselIndex(0)
            } else {
                selectImage(image_list[imageIndex + 1].unique_filename);
                setCarouselIndex(imageIndex + 1)
            }
        }
    };

    const prev = () => {
        resetRenderNameField()
        if (image_list?.length > 0) {
            let imageIndex = image_list.findIndex((img) => img.unique_filename === uniqueFilename);
            if ((imageIndex - 1) < 0) {
                selectImage(image_list[image_list.length - 1].unique_filename);
                setCarouselIndex(image_list.length - 1)
            } else {
                selectImage(image_list[imageIndex - 1].unique_filename);
                setCarouselIndex(imageIndex - 1)
            }
        }
    };

    const renderAutoWhiteBalance = () => {
        if (type == "lifestyles" && currentImg?.status != "pending" && render_generation == true) {
            return (white_balance_renders_switch[currentImg.unique_filename] != undefined ?
                <Col span={5}>
                    <div className="justify-in-end" style={{ paddingTop: 12 }}>
                        <span className='manrope f-12 black-33 mr-8'>Auto white balance</span>
                        {handleAutoWhiteSwitch(currentImg)}
                    </div>
                </Col> : "")
        }
        return "";
    };

    const lifestyleImageOptions = () => {
        if (type == "lifestyles" && currentImg?.status != "pending" && render_generation == true) {
            return (
                <Row style={{ paddingTop: 20 }}>
                    <Col span={12}>
                        <div className='justify-in-start'>
                            {renderReportButton(currentImg)}
                            {handleDelete(currentImg)}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='justify-in-end'>
                            {handleDownload(currentImg)}
                            {handleGenerateHighResImage(currentImg)}
                        </div>
                    </Col>
                </Row>)
        }
        return "";
    }

    const displaySiloOptions = () => {
        if (type == "silos" && currentImg?.status != "pending") {
            return (
                <Row style={{ paddingTop: 20 }}>
                    <Col span={12}>
                        <div className='justify-in-start'>
                            {renderReportButton(currentImg)}
                            {handleDelete(currentImg)}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='justify-in-end'>
                            {handleDownload(currentImg)}
                        </div>
                    </Col>
                </Row>)
        }
        return "";
    }

    const displayCollaborationOptions = (image) => {
        if (type == "lifestyles" && image.status == 'rendered' && render_generation == true) {
            return renderCollaborationTooltip(image);
        }
        return "";
    }

    const displayFlagState = (image) => {
        if (type == "lifestyles") {
            if (image.status == 'pending' || (!image.generated_by_admin && !image.flagged_by_user && !image.flag_rejected_by_admin)) {
                return "";
            } else {
                return renderFlagState(image);
            }
        }
        return "";
    }

    const displayImageInFullscreen = (image) => {
        if ((type == "lifestyles" && image.status == 'rendered') || (type == "silos" && image.image_status == 'completed')) {
            return <FullscreenOutlined className='lifestyle-image-expand' onClick={() => expandImage(image.unique_filename)} />
        }
        return "";
    }

    const renderFavIcon = (image) => {
        if ((type == "lifestyles" && image.status == 'rendered') || (type == "silos" && image.image_status == 'completed')) {
            return image.isFavourite ? (
                <Tooltip title={<span className="manrope f-12 white">Unfavorite</span>}>
                <div
                    className="pointer-event-auto img-fav-icon-modal white"
                    onClick={(e) => handleFavorite(image)}
                >
                    <HeartFilled style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            ) : (
                <Tooltip title={<span className="manrope f-12 white">Favorite</span>}>
                <div
                    className="pointer-event-auto img-fav-icon-modal white"
                    onClick={(e) => handleFavorite(image)}
                >
                    <HeartOutlined style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            );
        }
    }

    const getImageStyles = (image) => {
        if ((type == "lifestyles" && image.status == 'rendered') || (type == "silos" && image.image_status == 'completed')) {
            return image_style;
        }
        return image_style_blur;
    }


    return (
        currentImg ?
            <Row>
                <Col md={7} lg={7} xl={5} className='display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list'>
                    <ImageListType
                        imagesArray={image_list}
                        selectImage={selectImage}
                        uniqueFilename={uniqueFilename}
                        getImageFromSrc={getImageFromSrc}
                    />
                </Col>
                <Col md={17} lg={17} xl={19} className="w-100" style={{ paddingLeft: 20 }}>
                    <Row>
                        <Col span={19}>
                            <div className="display-flex justify-start align-baseline" >
                                {handleImageNameUpdate(currentImg)}
                            </div>
                        </Col>
                        {renderAutoWhiteBalance()}
                    </Row>
                    <div>
                        <Button className="carousel-btns left-btn" onClick={prev} style={{ marginLeft: 20 }}>
                            <ArrowLeftOutlined className="arrow" />
                        </Button>
                        <Carousel
                            autoplay={false}
                            ref={node => (carouselRef = node)}
                            className="w-100"
                            beforeChange={onChangeCarousel}>
                            {image_list.map((image, index) => (
                                <Card key={index} className='lifestyle-carousel-card' style={{ height: 100 }}>
                                     {carouselIndex === index ? (
                                        <>
                                    {displayCollaborationOptions(image)}
                                    {renderInProgressTooltip(image)}
                                    {displayFlagState(image)}
                                    <div className="overlay-container">
                                        {renderFavIcon(image)}
                                        <div>{displayImageInFullscreen(image)}</div>
                                    </div>
                                    <div>
                                        <ImageZoomAdmin
                                            custom_class={getImageStyles(image)}
                                            compare={true}
                                            image={getImageFromSrc(image)}
                                            notShowToolButtons={true} />
                                    </div>
                                    <div className="carousel-index-display">
                                        <span className="manrope f-14 w-500">{index + 1} of {image_list.length}</span>
                                    </div>
                                    { <>
                                        {(isImageVisible === undefined || isImageVisible === true) && (
                                            <Image
                                                preview={{
                                                    visible: image_visible[image.unique_filename.split(".")[0]],
                                                    onVisibleChange: changeVisibility,
                                                }}
                                                className="new-lifestyle-image-settings"
                                                src={getImageFromSrc(image)}
                                            />
                                        )}
                                    </> }
                                    </>) : ''}
                                </Card>
                            ))}
                        </Carousel>
                        <Button className="carousel-btns right-btn" onClick={next}>
                            <ArrowRightOutlined className="arrow" />
                        </Button>
                    </div>
                    {lifestyleImageOptions()}
                    {displaySiloOptions()}
                </Col>
            </Row> : ""
    );
};

export default ImageViewerCarousel;
