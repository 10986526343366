
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class NormalLoginFormDummy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      username: props.registerUsername,
      password: props.registerPassword,
      isLoading: false
    };
    console.log(props);
  }

  componentDidMount() {
    if(this.props.registerUsername !== undefined && this.props.registerPassword !== undefined){
      this.props.onSubmit(this.props.registerUsername, this.props.registerPassword);
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.username, this.state.password);
  }

  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  }

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  componentWillUnmount = () => {
    this.props.clearCache();
  }

  handleSubmit = e => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.props.onSubmit(this.state.username, this.state.password);
    }, 500);
  };

  render() {
    return (
      <Row className='justify-on-mobile'>
        <Col span={12} className='hide-on-mobile'>
          <img className="branding-image" src={'/img/introImage.png'} alt="ALL3D BRAND"/>
        </Col>
        <Col xs={22} sm={22} md={24} lg={12} className='web-desktop-width'>
            <div style = {{marginTop: 80}}>
            <LoadingOutlined spin />
            </div>
            <div className='mb-40'>
              <h3 className='manrope f-24 w-700 text-center'>Logging in</h3>
            </div>
        </Col>
        <Col md={24} lg={12} className='show-on-mobile justify-in-center'>
            <img className="branding-image login-page-mobile-img" src={'/img/introImageMobile.png'} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => state;
export default withRouter(connect(
  mapStateToProps
)(NormalLoginFormDummy)
)
