import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip, Card, Dropdown, Button, Menu, message } from 'antd';
import { DownOutlined, DownloadOutlined, UpCircleOutlined, DownCircleOutlined, RightOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import ImageMagnify from '../../CustomerComponents/ImageMagnify/ImageMagnify';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';
import './ArtistTestModelViewer.scss';
import ProductGoogleViewer from '../../CustomerComponents/ProductGoogleViewer';
import DottedLoader from '../../DottedLoader';


const google_viewer_style = { height: 'calc(100vh - 200px)', width: '100%' };
const google_viewer_style_fullscreen = { height: '100vh', width: '100%' };

const image_style = {
  width: '100%',
  height: 'calc(100vh -  282px)',
  objectFit: 'contain',
};
const fullscreen_image_style = {
  width: '100vw',
  height: '100vh',
  objectFit: 'contain',
};

function ArtistTestModelViewer({
  test_id,
  perspective_renders,
  adminScrollCheck,
  compareView,
  glbGenerated,
  fbxGenerated,
  usdzGenerated,
  platform
}) {
  const [, updateState] = React.useState();
  const [viewType, setViewType] = useState(null);
  const [previewImage, setPreviewImage] = useState(true);
  const [borderState, setBorderState] = useState(false);
  const [imageSelected, setImageSelected] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [image, setImage] = useState(null);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [selectedImage, setSelectedImage] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [collapseViewer, setCollapseViewer] = useState(false);



  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const menu = (
    <Menu style={{ width: "210px" }}>
      <Menu.Item key={0}>
        <a
          className="manrope f-12"
          href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI + `test_files/${test_id}.zip`}
        >
          Download 3DS Max Bundle{" "}
          <DownloadOutlined style={{ float: "right" }} />
        </a>
      </Menu.Item>
      {glbGenerated ? (
        <Menu.Item key={1}>
          <a
            className="manrope f-12"
            href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI + `glb/${glbGenerated}`}
          >
            Download GLB <DownloadOutlined style={{ float: 'right' }} />
          </a>
        </Menu.Item>
      ) : (
        ''
      )}
      {fbxGenerated ? (
        <Menu.Item key={2}>
          <a
            className="manrope f-12"
            href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI + `fbx/${fbxGenerated}`}
          >
            Download FBX <DownloadOutlined style={{ float: 'right' }} />
          </a>
        </Menu.Item>
      ) : (
        ''
      )}
      {usdzGenerated ? (
        <Menu.Item key={3}>
          <a
            className="manrope f-12"
            href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI + `usdz/${usdzGenerated}`}
          >
            Download USDZ <DownloadOutlined style={{ float: 'right' }} />
          </a>
        </Menu.Item>
      ) : (
        ''
      )}
    </Menu>
  );

  const hideViewer = () => {
    setCollapseViewer(true);
  };
  const showViewer = () => {
    setCollapseViewer(false);
  };

  const setFullScreen = () => {
    if (!isFullScreen) {
      message.info('Press ESC to exit full screen');
    }
    setIsFullScreen(!isFullScreen);
  };

  const view2DImage = (image) => {
    setViewType('2D');
    const temp_img = new Image();
    temp_img.src = require('../../../../assets/images/blank.jpeg');
    // Set blank image first
    setImage(temp_img.src);
    setSelectedImage(temp_img.src);
    forceUpdate();
    display_loader_and_set_image(image);
  };

  const display_loader_and_set_image = (image_src) => {
    const img = new Image();
    img.onload = function() {
      setImage(this.src);
      setSelectedImage(
          this.src ||
          (perspective_renders &&
            perspective_renders[0].includes('Dimensions') ?
            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
            `dimensional_renders/${test_id}/${perspective_renders[0]}`
            :
            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
              `perspective_renders/${test_id}/${perspective_renders[0]}`),
      );
      forceUpdate();
    };
    img.src = image_src;
  };

  useEffect(() => {
    if (perspective_renders.length > 0) {
      perspective_renders[0].includes('Dimensions') ?
        view2DImage(
            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
              `dimensional_renders/${test_id}/${perspective_renders[0]}`,
        ) :
        view2DImage(
            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
              `perspective_renders/${test_id}/${perspective_renders[0]}`,
        );
    }
  }, [perspective_renders, test_id]);

  const checkScroll = () => {
    const elem = document.getElementById('pdp-scrollable');
    const carousel_elem = document.getElementById('pdp-carousel');
    if (elem != null) {
      elem.style.scrollBehavior = 'unset';

      const y1 = elem.scrollTop;
      elem.scrollTop += 1;
      const y2 = elem.scrollTop;
      elem.scrollTop -= 1;
      const y3 = elem.scrollTop;
      elem.scrollTop = y1;

      const x1 = elem.scrollLeft;
      elem.scrollLeft += 1;
      const x2 = elem.scrollLeft;
      elem.scrollLeft -= 1;
      const x3 = elem.scrollLeft;
      elem.scrollLeft = x1;
      const right = document.getElementById('right-button-scr');
      const left = document.getElementById('left-button-scr');
      const nextValue = elem.scrollLeft - 273;

      if (elem.style.left == '94px') {
        elem.style.left = '94px';
      } else {
        if (elem.scrollLeft == 0) {
          elem.style.left = '20px';
        }
      }
      if (right != null && (x1 !== x2 || x2 !== x3)) {
        right.style.display = 'inline-block';
        if (left != null) {
          if (elem.style.left == '20px' && elem.scrollLeft == 0) {
            left.style.display = 'none';
          }
        }
        if (elem.style.left == '20px' && nextValue <= 0) {
          // for right scroll
          if (window.innerWidth <= 1500 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1320 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1200 && compareView) {
            elem.style.width = '100%';
          }

          if (compareView) {
            carousel_elem.style.width = '80%';
          }
        } else {
          // for left scroll
          if (window.innerWidth <= 1500 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1320 && compareView) {
            elem.style.width = '100%';
          }
          if (window.innerWidth <= 1200 && compareView) {
            elem.style.width = '100%';
          }
          if (compareView) {
            carousel_elem.style.width = '80%';
          }
        }
      }

      if (!compareView && adminScrollCheck) {
        elem.style.width = '100%';
        elem.style.left = '20px';
        if (window.innerWidth <= 1300) {
          elem.style.width = '100%';
        }
      }

      if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
        right.style.display = 'none';
      }
      if (right != null && !(x1 !== x2 || x2 !== x3)) {
        right.style.display = 'none';
      }
      // ---- NEED FOR LATER USE ----
      // console.log("H",x1 !== x2 || x2 !== x3);
      // console.log("V",y1 !== y2 || y2 !== y3);
    }
  };

  const leftScroll = () => {
    const elem = document.getElementById('pdp-scrollable');
    if (elem != null) {
      elem.style.scrollBehavior = 'smooth';
      elem.scrollLeft -= 273;
      const left = document.getElementById('left-button-scr');
      const right = document.getElementById('right-button-scr');
      if (left != null && elem.scrollLeft == 0) {
        left.style.display = 'none';
      }

      if (right != null) {
        right.style.visibility = 'visible';
        elem.style.left = '20px';
      }
    }
  };

  const rightScroll = () => {
    const elem = document.getElementById('pdp-scrollable');
    if (elem != null) {
      elem.style.scrollBehavior = 'smooth';
      elem.scrollLeft += 273;
      const left = document.getElementById('left-button-scr');
      const right = document.getElementById('right-button-scr');
      elem.style.left = '94px';
      if (right != null) {
        if (left != null) {
          left.style.display = 'inline-block';
        }

        const newScrollLeft = elem.scrollLeft;
        const divWidth = elem.offsetWidth;
        const scrollwidth = elem.scrollWidth;
        if (newScrollLeft + 2 >= scrollwidth - divWidth) {
          right.style.visibility = 'hidden';
        }
      }
    }
  };

  useEffect(() => {
    checkScroll();
    forceUpdate();
  }, [compareView, adminScrollCheck]);

  useEffect(() => {
    checkScroll();
    forceUpdate();
  }, []);

  React.useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  });

  return (
        <>
          {
            perspective_renders.length > 0 ?
          <Card
            className={
              isFullScreen ? 'product-full-screen' : 'product-viewer-normal'
            }
            bodyStyle={{ padding: isFullScreen ? 0 : 12 }}
            style={{
              zIndex: isFullScreen ? 5 : 'unset',
            }}
            id="pdp-card"
          >
            <div
              style={{
                height: 'max-content',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* 2D dimensions */}
                {viewType == '2D' &&
                  (compareView ? (
                    <ImageZoomAdmin
                      compare={true}
                      style={{ cursor: 'zoom-in' }}
                      custom_class={
                        isFullScreen ? fullscreen_image_style : image_style
                      }
                      image={image ? image.replace('.tiff', '.jpg') : null}
                      className="image-zoom"
                    />
                  ) : (
                    <ImageZoomAdmin
                      compare={true}
                      style={{ cursor: 'zoom-in' }}
                      custom_class={
                        isFullScreen ? fullscreen_image_style : image_style
                      }
                      image={image ? image.replace('.tiff', '.jpg') : null}
                      className="image-zoom"
                    />
                  ))}
              </div>

              {/* 3D dimensions */}
              {viewType == '3D' && (
                <div>
                  <ProductGoogleViewer
                    custom_style={
                      isFullScreen ?
                        google_viewer_style_fullscreen :
                        google_viewer_style
                    }
                    gltf={
                        ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI + `glb/${glbGenerated}`
                    }
                  />
                </div>
              )}

              {/* AR Link */}

              {viewType == 'view_in_room' && (
                <div className="position-ar-link">
                  <div
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '50px',
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    <p className="product-h2">
                      Open the following link in your Apple Device
                    </p>
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '20px',
                      justifyContent: 'space-around',
                    }}
                  >
                    <h3
                      className="apply-Avenir"
                      style={{ wordBreak: 'break-word', textAlign: 'center' }}
                    >
                      <a
                        href={
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                          `usdz/${usdzGenerated}`
                        }
                      >
                        {ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                          `usdz/${usdzGenerated}`}
                      </a>
                    </h3>
                  </div>
                </div>
              )}

              <div
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  top: '20px',
                  right: '20px',
                  left: '',
                }}
                id="components-dropdown-demo-dropdown-button"
              >
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="modal-okay square font-14">
                    Download Links <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>

            <div className="pdp-carousel-scroll" id="pdp-carousel">
              {!collapseViewer ? (
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      Hide image viewer
                    </span>
                  }
                >
                  <DownCircleOutlined
                    className="left-coll-icon"
                    onClick={hideViewer}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      Show image viewer
                    </span>
                  }
                >
                  <UpCircleOutlined
                    className="right-coll-icon"
                    onClick={showViewer}
                  />
                </Tooltip>
              )}
              {!collapseViewer && (
                <div
                  onClick={leftScroll}
                  className="icon-con-left"
                  id="left-button-scr"
                >
                  <RightOutlined className="left-button-scr" />
                </div>
              )}
              {!collapseViewer ? (
                <div id="pdp-scrollable" className="pdp-scrollable">
                  {perspective_renders.map((render, index) => {
                    return (
                      <Card
                        key={index}
                        onClick={() => {
                          render.includes('Dimensions') ?
                            view2DImage(
                                ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                                  `dimensional_renders/${test_id}/${render}`,
                            ) :
                            view2DImage(
                                ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                                  `perspective_renders/${test_id}/${render}`,
                            );
                          setPreviewImage(true);
                          setBorderState(false);
                          setImageSelected();
                        }}
                        bodyStyle={{
                          padding: '2px',
                          minWidth: 40,
                          textAlign: '-webkit-center',
                        }}
                        className="product-thumbnail-card"
                        style={{
                          border:
                            image ==
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                                `perspective_renders/${test_id}/${render}` &&
                            borderState == false ?
                              '1px solid #333333' :
                              '1px solid #F0F0F0',
                        }}
                      >
                        <img
                          className="product-thumbnail-img"
                          src={
                            render.includes('Dimensions')?
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                            `dimensional_renders/${test_id}/${render}`
                            :
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                            `perspective_renders/${test_id}/${render}`
                          }
                          alt="Clickable Product Image"
                        />
                      </Card>
                    );
                  })}
                  <Tooltip title={'3D'} placement="bottomLeft">
                    <Card
                      className="pdp-card"
                      bodyStyle={{
                        padding: '2px',
                        minWidth: 40,
                        textAlign: '-webkit-center',
                      }}
                      style={{
                        border:
                          viewType == '3D' ?
                            '1px solid #333333' :
                            '1px solid #f0f0f0',
                        marginRight: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <div
                        className="product-view-icon"
                        onClick={() => {
                          setViewType('3D');
                          setPreviewImage(true);
                          setBorderState(true);
                          setImageSelected('3D');
                          setImage(
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                              `glb/${glbGenerated}`,
                          );
                        }}
                      >
                        <span
                          style={{
                            background: 'url(/img/3d.jpg)',
                            objectFit: 'contain',
                            height: '40px',
                            width: '40px',
                          }}
                        >
                          <b style={{ color: 'white' }}></b>
                        </span>
                      </div>
                    </Card>
                  </Tooltip>

                  <Tooltip title={'AR'} placement="right">
                    <Card
                      className="pdp-card"
                      bodyStyle={{
                        padding: '2px',
                        minWidth: 40,
                        textAlign: '-webkit-center',
                      }}
                      style={{
                        border:
                          viewType == 'view_in_room' ?
                            '1px solid #333333' :
                            '1px solid #f0f0f0',
                        marginRight: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <div
                        className="product-view-icon"
                        onClick={() => {
                          setViewType('view_in_room');
                          setPreviewImage(true);
                          setBorderState(true);
                          setImageSelected('AR');
                          setImage(
                            ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.ARTIST_TEST_BASE_URI +
                              `usdz/${usdzGenerated}`,
                          );
                        }}
                      >
                        <span
                          style={{
                            background: 'url(/img/ar.jpg)',
                            objectFit: 'contain',
                            height: '40px',
                            width: '40px',
                          }}
                        >
                          <b style={{ color: 'white' }}></b>
                        </span>
                      </div>
                    </Card>
                  </Tooltip>
                </div>
              ) : (
                ''
              )}
              {!collapseViewer && (
                <div
                  style={{
                    display:
                      !compareView && adminScrollCheck ?
                        'none' :
                        'inline-block',
                    marginBottom: '10px',
                  }}
                  onClick={rightScroll}
                  className="icon-con"
                  id="right-button-scr"
                >
                  <RightOutlined className="right-button-scr" />
                </div>
              )}
            </div>
            <Tooltip
              title={!isFullScreen ? 'Full Screen' : 'Exit Full Screen'}
              placement="left"
            >
              <img
                src={
                  isFullScreen ?
                    '/img/fullScreenIcon-2.svg' :
                    '/img/fullScreenIcon.jpg'
                }
                alt="Fullscreen Icon"
                style={{
                  position: 'absolute',
                  bottom: '20px',
                  cursor: 'pointer',
                  opacity: '0.8',
                  borderRadius: '4px',
                  right: '20px',
                }}
                className="product-full-screen-icon"
                onClick={() => setFullScreen()}
              />
            </Tooltip>
          </Card>
          :
          <div/>
          }
        </>
  );
}

export default ArtistTestModelViewer;
