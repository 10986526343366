import React from 'react';
import PropTypes from 'prop-types';
import { LockOutlined, SyncOutlined, CodeOutlined,LoadingOutlined } from '@ant-design/icons';
import { Input, Button, Checkbox, Row, Col, Form } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class EmailVerificationForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
      verificationCode: '',
    };
  }

  onSubmit = (event) => {
    // event.preventDefault();
    this.props.onSubmit(this.state.verificationCode);
  }

  changeVerificationCode = (event) => {
    this.setState({ verificationCode: event.target.value });
  }

  render = () => {
    const formDiv = {
      'display': 'flex',
      'justifyContent': 'space-around',
      'margin-top': '40px',
    }

    const formStyle = {
      'background-color': 'white',
      'borderRadius': '5px'

    }

    const logoStyle = {
      'display': 'flex',
      'justifyContent': 'space-around',
      'marginBottom': '30px'
    }
    return <Row className='justify-on-mobile'>
        <Col span={12} className='hide-on-mobile'>
          <img className="branding-image" src={'/img/introImage.png'} />
        </Col>
        <Col xs={22} sm={22} md={24} lg={12} className='web-desktop-width'>
          <div className="login-frm">
            <div style={formStyle}>
              <div style={formDiv}>
                <div style={formStyle}>
                <Form onFinish={this.onSubmit} onFinishFailed={(e) => console.log(e) } className="login-form">
                <h3 className="manrope f-24 w-700 black-00">
                      Verify email  
                    </h3>
                  <p className='manrope f-14 w-500 black-00'>Please check your email for verification code</p>
                    <Form.Item
                      name="code"
                      rules= {[{ required: true, message: <span className='manrope f-14 red'>Enter verification code</span> },
                        {pattern: '^[0-9]{1,6}$', message: <span className='manrope f-14 red'>Verification code must consist of 6 digits</span>},
                        {pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: <span className='manrope f-14 red'>Spaces are not allowed, whitespace found at the end</span>},
                      ]}
                    >
                      <Input  className='manrope f-14 w-400 placeholder black '
                              onChange={this.changeVerificationCode}
                              placeholder="Verification code"
                          />
                    </Form.Item>
                    <Form.Item >
                      <Button disabled={this.state.isLoading} htmlType="submit" className="modal-okay square font-16 center-btn mt-16">
                        Submit {this.state.isLoading ? <LoadingOutlined spin /> : ''}
                      </Button>
                    </Form.Item>
                    <div className="manrope f-14 red text-center mt-10 mb-20">{this.props.error}</div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={24} lg={12} className='show-on-mobile justify-in-center'>
            <img className="branding-image login-page-mobile-img" src={'/img/introImageMobile.png'} />
          </Col>
      </Row>
    
    }
 
}
EmailVerificationForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

export default EmailVerificationForm;
