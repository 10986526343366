import React, { useState, useEffect } from 'react';
import { Modal, Card, Button, Row, Col, message, Tooltip, Switch, Empty, Menu, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, FlagOutlined, LoadingOutlined, DownOutlined, HeartOutlined,
    HeartFilled } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import { Gallery } from "react-grid-gallery";
import * as Utilities from '../../Utilities';
import axios from 'axios';
import GenerateHighResModal from "./GenerateHighResModal"
import ComplaintModal from './ComplaintModal';
import _ from "lodash"
import ImageViewerContext from '../../ContextFiles/ImageViewerContext';
import ImageViewerTabs from '../ImageViewerCarousel/ImageViewerTabs';
import ImageViewerCarousel from '../ImageViewerCarousel/ImageViewerCarousel';
import { isEqual, isEqualWith } from 'lodash-es';

const CUSTOMER_USERNAME = localStorage.getItem('username')
const COMPANY_ID = localStorage.getItem('company_id')

export default function LifestylesList({ projectId, selectedResolutionFilter, downloadLifestyleImagesArchive,
    setSelectedResolutionFilter,lifestyleRenders = [], projectMetadata, setProjectMetadata, white_balanced_images,
    setLifestyleRenders, loadingLifestyles, searchValue, populateSceneImages, handleFavorite }) {
    
    const [visibleImage, setVisibleImage] = useState([])
    const [expandImage, setExpandImage] = useState(false)
    const [deleteLifestyleModal, setDeleteLifestyleModal] = useState(false)
    const [deleteLifestyleLoader, setDeleteLifestyleLoader] = useState(false)
    const [deleteLifestyleSceneId, setDeleteLifestyleSceneId] = useState(null)
    const [deleteLifestyleCameraName, setDeleteLifestyleCameraName] = useState(null)
    const [filteredLifestyleRenders, setFilteredLifestyleRenders] = useState([])
    const [collabButtonLoader, setCollabButtonLoader] = useState(false)
    const [collaborationDisplayName, setCollaborationDisplayName] = useState('')
    const [collaborationCameraName, setCollaborationCameraName] = useState('')
    const [collaborationSceneId, setCollaborationSceneId] = useState(null)
    const [highResModalVisible, setHighResModalVisible] = useState(false)
    const [selectedHighResRender, setSelectedHighResRender] = useState(null)
    const [confirmHighResModal, setConfirmHighResModal] = useState(false)
    const [whiteBalancedImages, setWhiteBalancedImages] = useState({});
    const [complaintModal, setComplaintModal] = useState(false);
    const [editableRenderNameId, setEditableRenderNameId] = useState(null)
    const [renderNameBeingEdited, setRenderNameBeingEdited] = useState(null)
    const [renderNameButtonLoader, setRenderNameButtonLoader] = useState(false)
    const [sceneImageModal, setSceneImageModal] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);
    const [imgAccessedFromCarousel, setImgAccessedFromCarousel] = useState(false);
    const [selectedImgIndex, setSelectedImgIndex] = useState('');
    const [selectedImgComplaint, setSelectedImgComplaint] = useState(null);
    const [isImageVisible, setIsImageVisible] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const tabs = [
        {
            type: 'lifestyle_images',
            title: `Lifestyle Images`,
            component: <ImageViewerCarousel />
        }
    ];
    

    useEffect(() => {
        if (selectedResolutionFilter == 'all') {
            setFilteredLifestyleRenders(lifestyleRenders)
        }
        else {
            let filtred_renders = lifestyleRenders.filter(item => item.resolution == selectedResolutionFilter);
            setFilteredLifestyleRenders(filtred_renders)
        }
    }, [selectedResolutionFilter, lifestyleRenders]);


    useEffect(() => {
        setWhiteBalancedImages(white_balanced_images);
    }, [white_balanced_images]);


    const onImageExpand = (name) => {
        let temp_arr = visibleImage;
        lifestyleRenders.map(x => {
            if (x.unique_filename == name) {
                temp_arr[x.unique_filename] = true;
            } else {
                temp_arr[x.unique_filename] = false;
            }
        });

        setVisibleImage(temp_arr)
        setExpandImage(true)
        setIsImageVisible(true)
    }

    const createCollaborateEntity = () => {
        let payload = {
            'scene_id': collaborationSceneId,
            'camera_name': collaborationCameraName,
            'display_name': collaborationDisplayName,
            'created_by': localStorage.getItem('username'),
            'last_modified_by': localStorage.getItem('username'),
            'state': {}
        }
        payload['is_msprovider'] = FileConstants.isMSProvider;

        if (projectId)
            payload['project_id'] = projectId

        axios.post(ENVIRONMENT.CREATE_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('Response', res);
                if (res.data && res.data.body) {
                    collaborateCloseConfirmModal();
                    let collaboration_id = res.data.body.collaboration_entity_id;
                    let url = '/collaborate-tool/' + collaboration_id;
                    if (projectId) {
                        url = `${url}?origin=/projects/${projectId}&&project_id=${projectId}`
                    }
                    let new_window = window.open(url, '_blank');
                    if (new_window) {
                        new_window.focus();
                    } else {
                        window.location.href = url;
                    }
                }
            })
    }

    const openCollaborateTool = () => {
        setCollabButtonLoader(true)

        let payload = {
            'scene_id': collaborationSceneId,
            'camera_name': collaborationCameraName
        }
        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('res', res)
                if (res.data && res.data.length == 0) {
                    createCollaborateEntity();
                } else {
                    collaborateCloseConfirmModal();
                    let collaboration_id = res.data.collaboration_entity_id;
                    let url = '/collaborate-tool/' + collaboration_id;
                    if (projectId) {
                        url = `${url}?origin=/projects/${projectId}&&project_id=${projectId}`
                    }
                    
                    let new_window = window.open(url, '_blank');
                    if (new_window) {
                        new_window.focus();
                    } else {
                        window.location.href = url;
                    }
                }
            });
    }

    const changeVisibility = (visible, prevVisible) => {
        let temp_arr = [];
        if (complaintModal) {
            lifestyleRenders.map(x => {
                temp_arr[x.unique_filename] = false;
            });

            setVisibleImage(temp_arr)
            setExpandImage(false)
            setIsImageVisible(false)
        }
        else {
            if (expandImage) {
                lifestyleRenders.map(x => {
                    if (visibleImage[x.unique_filename] && !visible) {
                        temp_arr[x.unique_filename] = false;
                    }
                    else if (visibleImage[x.unique_filename] && visible) {
                        temp_arr[x.unique_filename] = true;
                    } else {
                        temp_arr[x.unique_filename] = false;
                    }
                });

                setVisibleImage(temp_arr)
                setExpandImage(false)
                setIsImageVisible(false)

            } else {
                lifestyleRenders.map(x => {
                    temp_arr[x.unique_filename] = false;
                });
                setVisibleImage(temp_arr)
                setExpandImage(false)
                setIsImageVisible(false)
            }
        }
    }

    const openDeleteLifestyleModal = (filename, scene_id) => {
        setDeleteLifestyleSceneId(scene_id)
        setDeleteLifestyleCameraName(filename)
        setDeleteLifestyleModal(true)
    }

    function getResolutionType(image_width, image_height) {
        const width = parseInt(image_width, 10);
        const height = parseInt(image_height, 10);
        
        const maxDimension = Math.max(width, height);
        if (maxDimension >= 0 && maxDimension <= 1024) {
            return '1K';
        } else if (maxDimension >= 1025 && maxDimension <= 2048) {
            return '2K';
        } else if (maxDimension >= 2049) {
            return '4K';
        }
    }

    const deleteLifestyle = () => {
        let old_images = _.cloneDeep(lifestyleRenders)
        setDeleteLifestyleLoader(true)
        let deletedRender = null;
        let cameraToBeDeleted = deleteLifestyleCameraName;
        let renders = lifestyleRenders
            .filter(item => item.scene_id == deleteLifestyleSceneId)
            .map(item => ({ ...item }));

        renders.forEach(item => {
            delete item.request_link
            delete item.unique_filename
            delete item.scene_id
            delete item.resolution
        })

        for (let render of renders) {
            if (render.filename == cameraToBeDeleted) {
                deletedRender = render;
                render['status'] = 'hidden';
                render['deleted_by'] = CUSTOMER_USERNAME;
            }
        }

        let payload = {
            "scene_id": deleteLifestyleSceneId,
            "final_renders": renders
        };

        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then((response) => {
                let metadata_payload = {"action": 'update_metadata' ,"project_id" : projectId}
                axios.post(ENVIRONMENT.UPDATE_PROJECT, metadata_payload)
            })
            .catch((error) => {
                message.error('Error in deleting Lifestyle Image');
            });

        for (let imageObj of lifestyleRenders) {
            if (imageObj.filename == cameraToBeDeleted && imageObj.scene_id == deleteLifestyleSceneId) {
                imageObj.status = 'hidden';
            }
        }

        let resolution = getResolutionType(deletedRender.image_width, deletedRender.image_height)
        let metadata = _.cloneDeep(projectMetadata);
        metadata['lifestyle_count'][resolution] = metadata['lifestyle_count'][resolution] - 1;
        setProjectMetadata(metadata);

        message.success('Lifestyle Image Deleted.');
        
        setLifestyleRenders(lifestyleRenders);
        populateSceneImages(lifestyleRenders);
        setSelectedResolutionFilter(selectedResolutionFilter);
        setDeleteLifestyleModal(false);
        setDeleteLifestyleLoader(false);
        setSceneImageModal(false);
    }

    const isComplaintInprogress = (render) => {
        let complaint_inprogress = false;
        if (render.flagged_by_user) {
            complaint_inprogress = true
            for (let final_render of lifestyleRenders) {
                if (final_render.scene_id == render.scene_id && final_render.revision_of == render.filename) {
                    complaint_inprogress = false;
                    break;
                }
            }
        }
        return complaint_inprogress
    }

    const getCollaborationDisplayName = (camera_name, display_name) => {
        let collab_display_name = display_name;
        if (!display_name) {
            collab_display_name = 'Untitled ' + camera_name.split('.')[0].split('_')[1]
        }
        return collab_display_name;
    }

    const collaborateConfirmModal = (camera_name, display_name, scene_id) => {
        setCollaborationCameraName(camera_name)
        setCollaborationDisplayName(getCollaborationDisplayName(camera_name, display_name))
        setCollaborationSceneId(scene_id)
    }

    const collaborateCloseConfirmModal = () => {
        setCollabButtonLoader(false)
        setCollaborationCameraName('')
        setCollaborationDisplayName('')
        setCollaborationSceneId('')
    }

    const getCollaborationStatusTooltip = (final_render) => {
        /* 
        This function returns the status string for tooltip on collab icon hover
        */
        let collab_status = 'Collaborate';
        if (final_render.collaboration_id && final_render.collaboration_resolved) {
            // collaboration is resolved
            collab_status = 'Collaboration Resolved';
        }
        else if (final_render.collaboration_id && !final_render.collaboration_resolved) {
            collab_status = `Collaboration In Progress<br/>Total Comments: ${final_render.annotation_count || 0}`
        }

        return collab_status
    }

    const addToWhiteBalancedImages = (filename) => {
        let white_balance_images = { ...whiteBalancedImages };
        white_balance_images[filename] = true;
        setWhiteBalancedImages(white_balance_images)
    }

    const removeFromWhiteBalancedImages = (filename) => {
        let white_balance_images = { ...whiteBalancedImages };
        white_balance_images[filename] = false;
        setWhiteBalancedImages(white_balance_images)
    }

    const getWhiteBalancedImageUrl = (originalImageLink, whiteBalanceImageFilename) => {
        let url_parts = originalImageLink.split('/')
        url_parts.pop()
        url_parts.push(whiteBalanceImageFilename)
        
        let white_balanced_url = url_parts.join('/')
        return white_balanced_url
    }

    const openComplaintModal = (obj) => {
        setExpandImage(false);
        setComplaintModal(true);
        setSelectedImgComplaint(obj);
    }

    const checkIfComplaintInProgress = (data) => {
        if (!data.complaint_status || (data.complaint_status && data.complaint_status != "rejected" && data.complaint_status != "resolved")) {
            return false;
        }
        return true;
    }

    const checkIfFlagIsDisabled = (x) => {
        if (FileConstants.isMSProvider) {
            if (x.flagged_by_user !== undefined) {
                return true
            }
        } else if (x.flagged_by_user !== undefined) {
            if (x.generated_by_admin && x.captured_by != CUSTOMER_USERNAME) {
                return false;
            } else {
                return true;
            }
        }
    }

    const renameSceneRender = (elem_id, render = null) =>{
        setRenderNameButtonLoader(true)
        let scene_render_field = document.getElementById('render-name');
        if(scene_render_field && scene_render_field.innerText.trim().length)
        {
            // if the render name string does not consist only on white spaces
            scene_render_field.contentEditable = false;
            let scene_render_name = scene_render_field.innerText;

            const renderIndex = lifestyleRenders.findIndex(item=>item.unique_filename==elem_id)
            const lifestyleRendersCopy = [...lifestyleRenders];

            lifestyleRendersCopy[renderIndex]["display_name"] = scene_render_name
            setLifestyleRenders(lifestyleRendersCopy)
            if (selectedImg) {
                setSelectedImg(lifestyleRendersCopy[renderIndex])
            }
            populateSceneImages(lifestyleRendersCopy);

            if(scene_render_name != renderNameBeingEdited && scene_render_name != ''){
                let payload = {
                    'scene_id': elem_id.split('_')[0], // elem_id is the unique_filename, which is scene_id before the filename, separated by _
                    'render_name': {}
                }
                payload['render_name'] = {
                    [lifestyleRendersCopy[renderIndex]['filename']]: scene_render_name 
                }
                axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then( (response) => {
                    if(response.status == 200){
                        message.success('Lifestyle image renamed')
                        resetRenderNameField()
                    }
                });
            }

            if(scene_render_name == '' || scene_render_name == renderNameBeingEdited){
                // User tried to update empty name, reset the field. Same Behaviour as pressing ESC
                let scene_render_field = document.getElementById('render-name');
                scene_render_field.innerText = renderNameBeingEdited
                scene_render_field.contentEditable = false;
                resetRenderNameField()
            }
            setRenderNameBeingEdited('')
        }
        else if(scene_render_field && !scene_render_field.innerText.trim().length){
            // render name only consists on white spaces
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = renderNameBeingEdited
            resetRenderNameField()
        } else {
            setRenderNameButtonLoader(false)
        }
    }

    const handleEnterForSceneRenderRename = (keyCode, id, render) => {
        if(keyCode == 13){
            // Enter is Pressed
            renameSceneRender(id, render)
        }
        else if(keyCode == 27){
            // ESC is pressed
            let scene_render_field = document.getElementById('render-name');
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = renderNameBeingEdited;
            
            resetRenderNameField()
        }
    }

    const makeSceneRenderNameEditable = (id) => {
        let scene_render_field = document.getElementById('render-name');
        scene_render_field.contentEditable = true;
        setEditableRenderNameId(id)
        setRenderNameBeingEdited(scene_render_field.innerText)
    }

    const customImageThumbnail = ({ item, imageProps }) => {
        // You can access the original props passed to the image component via imageProps
        return (
            <div style={{ position: 'relative' }}>
                <img
                    {...imageProps} // Spread the original image props to preserve default behavior
                    src={item.thumbnail ? item.thumbnail : item.src}
                    style={{ ...imageProps.style, filter: item.status == 'pending' ? 'blur(3px)' : 'none' }} // Add custom styles
                />
            </div>
        );
    };

    const getImgDisplayName = (render) => {
        if (render.display_name) {
            return render.display_name;
        }
        return `Untitled ${render.filename.split('.')[0].split('_')[1]}`;
    }

    const getCollaborateImageFilepath = (render) => {
        /* 
        This function returns the image icon name for the collaborate icon based on the status
        of the collaboration i.e. create new, resolved, inprogress
        */
        let collab_icon_filename = '/img/collaborate_tool/new-new-collab.svg';
        if(render.collaboration_id && render.collaboration_resolved){
        // collaboration is resolved
            collab_icon_filename = '/img/collaborate_tool/comment-circle-white.svg';
        }
        else if(render.collaboration_id && !render.collaboration_resolved){
            collab_icon_filename = '/img/collaborate_tool/comment-circle-inprogress.svg'
        }

        return collab_icon_filename 
    }

    const getFlagIcon = (render) => {
        /* 
        This function returns the image icon name for the flag state based on the status
        of the flag i.e. rejected, resolved, flagged
        */
        let flag_icon_filename = '/img/flag-icon.svg';
        if (!render.generated_by_admin && !render.flagged_by_user && !render.flag_rejected_by_admin) {
            return "";
        } else if (render.flagged_by_user) {
            return flag_icon_filename;
        } else if (render.flagged_by_user != undefined && !render.flagged_by_user) {
            if (render.flag_rejected_by_admin) {
                flag_icon_filename = '/img/yellow-flag-icon.svg';
            } else if (render.revision_of != undefined && render.generated_by_admin) {
                flag_icon_filename = '/img/green-flag-icon.svg';
            }
        } 
        
        return flag_icon_filename; 
    }

    const getFlagStatus = (render) => {
        /*This function returns the status string for tooltip on flag icon hover*/
        let flag_status = 'Issue Reported';
        if (!render.generated_by_admin && !render.flagged_by_user && !render.flag_rejected_by_admin) {
            return "";
        } else if (render.flagged_by_user) {
            return flag_status;
        } else if (render.flagged_by_user != undefined && !render.flagged_by_user) {
            if (render.flag_rejected_by_admin) {
                flag_status = 'Issue Reviewed & Resolved';
            } else if (render.revision_of != undefined && render.generated_by_admin) {
                flag_status = 'Issue Resolved';
            }
        }
        return flag_status 
    }

    const getThumbnailLink = (originalLink) => {
        const parts = originalLink.split(".");
        return parts.slice(0, -1).join(".") + "_512." + parts.at(-1);
    }

    const getImageFromSrc = (render, thumbnailLink = false) => {
        if (render.status == 'rendered') {
            let imageUrl = whiteBalancedImages[render.unique_filename] ? getWhiteBalancedImageUrl(render.request_link, render.white_balanced_image) : render.request_link;
            if(thumbnailLink && !whiteBalancedImages[render.unique_filename])
                imageUrl = getThumbnailLink(imageUrl)
            return imageUrl
        }
        return render.preview_render ? render.request_link.split('/').slice(0, -1).concat(render.preview_render.replace('.tiff', '.jpg')).join('/') : '';
    }


    const renderInProgressTooltip = (render) => {
        return (render.status == "pending" ? <Tooltip
            title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: render?.preview_render.includes("preview") ? 'Render in Progress' : 'High Res Render In Progress' }}></span>}>
            <div
                className={`lifestyle-image-collaborate-icon white border justify-in-start`} style={{ width: 'fit-content' }}>
                <img
                    className='collaborate-img'
                    src={'/img/time-clock-img.svg'}
                    alt="In Progress Icon"
                />
                <span className={`manrope f-14 w-500`}>
                    {render?.preview_render.includes("preview") ? "Render In Progress" : "High Res Render In Progress"}
                </span>
            </div>
        </Tooltip> : "");
    }

    const renderFlagState = (render) => {
        return (<Tooltip
            title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: getFlagStatus(render) }}></span>}>
            <div
                className={`lifestyle-image-collaborate-icon white border justify-in-start`} style={{ width: 'fit-content', left: 150, cursor: 'default', opacity: 0.6 }}>
                <img
                    className='collaborate-img'
                    style={{ height: 22, width: 22 }}
                    src={getFlagIcon(render)}
                    alt="Flag Icon"
                />
                <span className={`manrope f-14 w-500 ${FileConstants.flag_text_color[getFlagStatus(render)]}`}>
                    {getFlagStatus(render)}
                </span>
            </div>
        </Tooltip>);
    }

    const handleDownload = (obj) => {
        return (
            <span>
                <Dropdown overlay={(<Menu style={{ width: "165px" }}>
                    <Menu.Item key={1}>
                        <a className='manrope f-12' target="_blank" onClick={() => downloadLifestyleImagesArchive()}> All Lifestyle Images </a>
                    </Menu.Item>
                    <Menu.Item key={2} onClick={() => {
                        downloadSingleImage(obj);
                    }}>
                        <span className='manrope f-12'> This Image Only</span>
                    </Menu.Item>
                    {COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID ?
                    <Menu.Item key={3} onClick={() => {
                        let fname = getImgDisplayName(obj);
                        let download_link = getImageFromSrc(obj);
                        let filename = fname + '.' + obj.filename.split('.')[obj.filename.split('.').length - 1];
                        if (whiteBalancedImages[obj.unique_filename]) {
                            filename = (fname + '.' + obj.filename.split('.')[obj.filename.split('.').length - 1]).replace(".", "_white_balanced.");
                        }
                        if (obj.filename.includes('.tiff')) {
                            download_link.replace('.jpg', '.tiff')
                        }
                        Utilities.generateAndDownloadWatermarkedImage(download_link, filename);
                    }}>
                        <span className='manrope f-12'> Watermarked Image</span>
                    </Menu.Item>: ''}
                </Menu>)} trigger={['click']}>
                    <Button
                        className="modal-okay square font-16 h-40 "
                        data-tut="reactour__stil_images_button">
                        Download
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </span>)
    }

    const downloadSingleImage = (img) => {
        let fname = getImgDisplayName(img);
        let download_link = getImageFromSrc(img);
        let filename = fname + '.' + img.filename.split('.')[img.filename.split('.').length - 1];
        if (whiteBalancedImages[img.unique_filename]) {
            filename = (fname + '.' + img.filename.split('.')[img.filename.split('.').length - 1]).replace(".", "_white_balanced.");
        }
        if (img.filename.includes('.tiff')) {
            download_link.replace('.jpg', '.tiff')
        }
        Utilities.downloadFile(download_link, filename)
    }

    const filterImages = () => {
        let array =  _.cloneDeep(filteredLifestyleRenders);
        array.forEach(obj => {
            obj.width = obj.image_width;
            obj.height = obj.image_height;
            obj.display_name = getImgDisplayName(obj);
            obj.customOverlay = (<div className='custom-overlay'>
                {obj.status == 'pending' ?
                <Tooltip title={<span className='manrope f-12 white'>{obj.preview_render.includes("preview") ? 'Render in Progress' : 'High Res Render in Progress'}</span>}>
                    <div className={`pointer-event-auto collaborate-icon white`}>
                        <div className='collaborate-img thumbnail-lifestyle display-flex'>
                        <img src={'/img/time-clock-img.svg'} />
                    </div>
                    </div>
                </Tooltip> : ''}
                {obj.status != 'pending' && 
                <Tooltip title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: getCollaborationStatusTooltip(obj) }}></span>}>
                    <div style={{cursor: 'pointer' }} 
                    className={`pointer-event-auto collaborate-icon ${obj.white_balanced_image != undefined ? `` : `right`} ${obj.collaboration_resolved ? 'green' : obj.collaboration_id == undefined ? 'white' : ''} `}
                    onClick={(e) => { e.stopPropagation(); collaborateConfirmModal(obj.filename, obj.display_name, obj.scene_id);}}>
                        <div className='collaborate-img thumbnail-lifestyle display-flex'>
                            <img src={getCollaborateImageFilepath(obj)} />
                            {obj.annotation_count > 0 && <div className={`ml-4 manrope f-12 ${obj.collaboration_resolved ? 'white' : obj.collaboration_id == undefined ? 'black-14' : 'white'}`}>{obj.annotation_count}</div>}
                        </div>
                    </div>
                </Tooltip>}
                {obj.status == 'pending' || (!obj.generated_by_admin && !obj.flagged_by_user && !obj.flag_rejected_by_admin) ? "" :
                <Tooltip title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: getFlagStatus(obj) }}></span>}>
                    <div className={`pointer-event-auto collaborate-icon white flag${obj.annotation_count > 0 ?'-60': ''}`} style={{cursor: 'default', opacity: 0.6 }}>
                        <div className='collaborate-img thumbnail-lifestyle display-flex'>
                            <img style={{width: 14, height: 14}} src={getFlagIcon(obj)} />
                        </div>
                    </div>
                </Tooltip>}
                {obj.status != 'pending' &&  
                <div className="fav-and-more-icons">
                    <Dropdown
                        overlay={(
                            <Menu>
                                <Menu.Item key="1" 
                                onClick={() => { collaborateConfirmModal(obj.filename, obj.display_name, obj.scene_id);}}>
                                    Collaborate
                                </Menu.Item>
                                <Menu.Item key="2"
                                    disabled={!((obj.collaboration_id && obj.collaboration_resolved) || obj.collaboration_id === null)}
                                    onClick={() => {
                                        setSelectedHighResRender(obj)
                                        if (isComplaintInprogress(obj)) {
                                            setConfirmHighResModal(true)
                                        }
                                        else {
                                            setHighResModalVisible(true)
                                        }
                                    }}
                                    >
                                    <Tooltip title={((obj.collaboration_id && obj.collaboration_resolved) || obj.collaboration_id === null) ? '' : 
                                    <span className='manrope f-12 white'>Collaboration In Progress</span> }>
                                        <span className="menu-item-text">Generate Any High-Res Image</span>
                                    </Tooltip>
                                </Menu.Item>
                                <Menu.Item key="3" 
                                onClick={() => {downloadSingleImage(obj)}}
                                >
                                    <span className="menu-item-text">Download</span>
                                </Menu.Item>
                                <Menu.Item key="4" 
                                    disabled={checkIfComplaintInProgress(obj) || checkIfFlagIsDisabled(obj)}
                                    onClick={!checkIfFlagIsDisabled(obj) || FileConstants.isMSProvider ? 
                                        () => checkIfComplaintInProgress(obj) ? "" : 
                                        checkIfFlagIsDisabled(obj) ? ()=>{} : 
                                    openComplaintModal(obj): ()=>{}}>
                                    <Tooltip
                                        title={getTooltipTitle(obj)}>
                                        <span className="menu-item-text">Report</span>
                                    </Tooltip>
                                </Menu.Item>
                                <Menu.Item 
                                onClick={() => openDeleteLifestyleModal(obj.filename, obj.scene_id) }
                                    key="5" >
                                    <span className="menu-item-text">Delete</span>
                                </Menu.Item>
                            </Menu>
                        )}
                        trigger={['hover']} className='pointer-event-auto'>
                        <div className="img-more-info" onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                            <img src={'/img/more.svg'} />
                        </div>
                    </Dropdown>
                    <div className={`pointer-event-auto img-fav-icon white`}>
                        {obj.isFavourite ? (
                            <Tooltip
                            title={<span className="manrope f-12 white">Unfavorite</span>}
                            >
                            <HeartFilled
                                style={{ fontSize: "19px" }}
                                onClick={(e) => {
                                console.log("here :");
                                e.stopPropagation();
                                handleFavorite(obj, 'lifestyle');
                                }}
                            />
                            </Tooltip>
                        ) : (
                            <Tooltip
                            title={<span className="manrope f-12 white">Favorite</span>}
                            >
                            <HeartOutlined
                                style={{ fontSize: "19px" }}
                                onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite(obj, 'lifestyle');
                                }}
                            />
                            </Tooltip>
                        )}
                    </div>
                    </div>
                }

                <div className='hover-info'>
                    <div className="image-banner">
                        <Tooltip title={<span className='manrope f-12 white'>{getImgDisplayName(obj)}</span>}>
                            <p className="manrope f-12 w-700 lh-140 clamp-text w-100 image-banner-text justify-in-start">
                                {getImgDisplayName(obj)}
                            </p>
                        </Tooltip>
                        <p className="manrope f-12 image-banner-text">{obj.width}x{obj.height} <span className='manrope upper-case'>{obj.filename.split('.')[1]}</span></p>
                    </div>
                </div>
            </div>);
            obj.src = getImageFromSrc(obj);
            obj.thumbnail = getImageFromSrc(obj, true);
        })
        let searchTerm = searchValue.toLowerCase();
        return array.filter((image) => {
            // Filter based on search term
            const nameMatches = image.display_name.toLowerCase().includes(searchTerm);

            return (nameMatches);
        });
    };

    const openSceneImageModal = (index) => {
        setSceneImageModal(true);
        setSelectedImg(filterImages()[index]);
        setSelectedImgIndex(index);
        setImgAccessedFromCarousel(false);
    }

    const handleCancel = () => {
        setSceneImageModal(false);
        resetRenderNameField()
    }

    const resetRenderNameField = () => {
        let scene_render_field = document.getElementById('render-name');
        scene_render_field.contentEditable = false;
        setEditableRenderNameId(null)
        setRenderNameButtonLoader(false)
        setRenderNameBeingEdited(null)
    }

    const handleMouseEnter = () => {
        if (!editableRenderNameId) {
            setTooltipVisible(true)
        }
    };
    
    const handleMouseLeave = () => {
        setTooltipVisible(false)
    };


    const handleImageNameUpdate = (x) => {
        const isSmallScreen = window.innerWidth <= 1430;
        const styles = {
            textContainer: {
                marginBottom: 11,
                textTransform: 'uppercase',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                whiteSpace: isSmallScreen ? 'nowrap' : 'normal',
            },
            dateContainer: {
                marginBottom: 11,
                display: 'flex',
                justifyContent: 'flex-end',
                whiteSpace: isSmallScreen ? 'nowrap' : 'normal',
                alignItems: 'end',
                gap: '3px'
            },
        };

        return (
            <div style={{ 
                display: "flex",
                flexDirection: "row",
                width:"90%",
            }}>
                <Tooltip placement='topLeft' title={<span className='manrope f-12 white'>{getImgDisplayName(x)}</span>}>
                <h4
                    className={`manrope f-20 scene-render-name${editableRenderNameId == x.unique_filename ? `-editable` : ``} lifestyle-name-settings`}
                    id='render-name'
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                    onKeyDown={(e) => {e.stopPropagation(); handleEnterForSceneRenderRename(e.keyCode, x.unique_filename, x) }}>
                        {getImgDisplayName(x)}
                </h4>
                </Tooltip>
                {editableRenderNameId == x.unique_filename ?
                    <Button disabled={renderNameButtonLoader} loading={renderNameButtonLoader} className="save-button font-12" 
                    onClick={() => { renameSceneRender(x.unique_filename, x) }}
                    style={{ marginLeft: 15, display: 'flex', justifyContent: 'flex-end' }}>
                        Save
                    </Button>
                    :
                    <div onClick={() => { makeSceneRenderNameEditable(x.unique_filename) }} className="edit-icon-data-small edit-icon-blue" >
                        <EditOutlined />
                    </div>}
                <Tooltip title={<span className="manrope f-12 white">{x.image_width}x{x.image_height}</span>}>
                    <div className="manrope f-14 black-33 ml-16" style={styles.textContainer }>
                        {` ${x.image_width} x ${x.image_height} `}
                        {x.filename.split('.')[1]}
                    </div>
                </Tooltip>
                <Tooltip title={<span className="manrope f-12 white">{new Date(x.time_updated).toDateString()}</span>}>
                    <div className="manrope f-14 grey-8c ml-16" 
                    style={styles.dateContainer}>
                        <span>Created on {new Date(x.time_updated).toDateString()}</span> 
                        {
                            x.captured_by ? 
                                <span>
                                    by <span style={{ fontWeight: 'bold' }}>{x.original_captured_by ? x.original_captured_by : x.captured_by}</span>
                                </span>
                            : ''
                        }
                    </div>
                </Tooltip>
            </div>);
    }

    const handleAutoWhiteSwitch = (x) => {
        return (x.white_balanced_image != undefined ?
            <div className="lifestyle-img-auto-white-switch">
                <Tooltip title={whiteBalancedImages[x.unique_filename] != undefined &&
                    whiteBalancedImages[x.unique_filename] == true ? "Auto White Balanced Image: On" : "Auto White Balanced Image: Off"}>
                    <Switch checked={whiteBalancedImages[x.unique_filename]}
                        onClick={(check) => {

                            if (check) {
                                addToWhiteBalancedImages(x.unique_filename)
                            }
                            else {
                                removeFromWhiteBalancedImages(x.unique_filename)
                            }
                        }}
                        checkedChildren={<img src="/img/white_balance_icon_white.svg"></img>}
                        unCheckedChildren={<img src="/img/white_balance_icon.svg"></img>} />
                </Tooltip>
            </div> : ""
        )
    };

    const renderCollaborationTooltip = (image) => {
        return (
            <Tooltip
                title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: getCollaborationStatusTooltip(image) }}></span>}
            >
                <div
                    className={`lifestyle-image-collaborate-icon ${image.white_balanced_image != undefined ? `` : `right`} ${image.collaboration_resolved ? 'green' : image.collaboration_id == undefined ? 'white border' : ''}`}
                    onClick={() => collaborateConfirmModal(image.filename, image.display_name, image.scene_id)}
                >
                    <img
                        className='collaborate-img'
                        src={getCollaborateImageFilepath(image)}
                        alt="Collaboration Icon"
                    />
                    <span
                        className={`manrope f-14 w-500 ${image.collaboration_resolved || image.collaboration_id != undefined ? 'text-white' : ''}`}
                    >
                        Collaborate
                    </span>
                </div>
            </Tooltip>
        );
    };

    const renderReportButton = (x) => {
        return (
            <Tooltip title={getTooltipTitle(x)}>
                <span>
                <Button
                    disabled={checkIfComplaintInProgress(x) || checkIfFlagIsDisabled(x)}
                    className="red-icon-button-container mr-8"
                    onClick={!checkIfFlagIsDisabled(x) || FileConstants.isMSProvider ? 
                        () => checkIfComplaintInProgress(x) ? "" : 
                        checkIfFlagIsDisabled(x) ? ()=>{} : 
                    openComplaintModal(x.filename, x.scene_id, x.platform): ()=>{}}>
                    <FlagOutlined />
                </Button>
                </span>
            </Tooltip>
        );
    };

    const handleDelete = (x) => {
        if (!x || !x.filename) return null;

        return (
            <Button
                onClick={() => openDeleteLifestyleModal(x.filename, x.scene_id)}
                className="red-icon-button-container mr-8">
                <DeleteOutlined />
            </Button>
        );
    };

    const  handleGenerateHighResImage = (x) => {
        if (!x || !x.filename) return null;

        const isDisabled = ((x.collaboration_id && x.collaboration_resolved) || x.collaboration_id === null)

        const handleClick = () => {
            setSelectedHighResRender(x)
            if (isComplaintInprogress(x)) {
                setConfirmHighResModal(true)
            }
            else {
                setHighResModalVisible(true)
            }
        };


        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <Tooltip title={<span className='manrope f-12 white'>{!isDisabled ? "Can't Generate high res image as a collaboration is in progress." : "Generate Any High Res Image"}</span>}>
                    <span>
                        <Button
                            className="modal-okay square font-16 pd-6-14 h-40"
                            onClick={handleClick}
                            disabled={!isDisabled}
                            style={{ marginLeft: 15 }}
                            data-tut="reactour__stil_images_button">
                            <img src='/img/carbon_cloud-download.png' style={{ marginRight: 5 }}></img>
                            Generate Any High-Res Image
                        </Button>
                    </span>
                </Tooltip>
            </div>
        );
    };

    const getTooltipTitle = (x) => {
        const isFlagDisabled = checkIfFlagIsDisabled(x);

        if (!isFlagDisabled || FileConstants.isMSProvider) {
            if (checkIfComplaintInProgress(x)) {
                return "You can't flag this image unless your complaint is resolved.";
            } else if (isFlagDisabled) {
                return "You cannot flag this image";
            }
        } else {
            return "You cannot flag more than once";
        }
    };


    const setSelectedImgFromCarousel = (selected_image) => {
        setSelectedImg(selected_image);
    }

    const filteredImages = filterImages();
    return (<>
    {
        loadingLifestyles ?
        <div>
            <LoadingOutlined style={{ fontSize: 50 }} className="loading-center"/>
        </div>
        :
        (filteredLifestyleRenders?.length == 0 || filteredImages?.length == 0) ?
        <Empty 
        style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }}
        description={`No lifestyles to show`}
        className="manrope f-14"/>
        :
        <div className='mb-60'>
            <Gallery
                thumbnailImageComponent={customImageThumbnail}
                onClick={(index) => openSceneImageModal(index)}
                rowHeight={300}
                margin={8}
                images={filteredImages}
                enableImageSelection={false}/>
        </div>}

        <Modal
            className="modal-share-success"
            width={600}
            destroyOnClose={true}
            visible={deleteLifestyleModal}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-14" onClick={() => {
                        setDeleteLifestyleModal(false)
                        setDeleteLifestyleCameraName(null)
                        setDeleteLifestyleSceneId(null)
                    }}>
                        Cancel
                    </Button>
                    <Button disabled={deleteLifestyleLoader} style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => deleteLifestyle()}>
                        {deleteLifestyleLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span> Okay </span>}
                    </Button>
                </span>
            ]}
            centered={true}
        >
            <div style={{ margin: "0px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <h2 className="manrope f-16 black-00 w-600">You're about to delete a Lifestyle Image</h2>
                    <p className="manrope f-14 black-55">This will remove your lifestyle image from your scene. Are you sure you want to delete?</p>
                </div>
            </div>
        </Modal>

        <Modal
            className="modal-space"
            destroyOnClose={true}
            width={700}
            visible={collaborationCameraName}
            closable={false}
            bodyStyle={{ padding: 0 }}
            footer={[
                <span className="justify-in-end">
                    <Button disabled={collabButtonLoader} style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={collaborateCloseConfirmModal}>
                        Cancel
                    </Button>
                    <Button disabled={collabButtonLoader} style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={openCollaborateTool}>
                        {collabButtonLoader ? <span>Please wait.. <LoadingOutlined spin /></span> : <span>Go to tool </span>}
                    </Button>
                </span>
            ]}
        >
            <div style={{ padding: 30, display: 'flex' }}>
                <Col span={24}>
                    <h2 className="modal-heading manrope f-14 black-00 w-600">Go to collaborate tool? </h2>
                    <p className="manrope f-12 black-33">This tool will allow you to post any feedback you have for this render. </p>
                </Col>
            </div>
        </Modal>

        <GenerateHighResModal confirmHighResModal={confirmHighResModal} setConfirmHighResModal={setConfirmHighResModal}
        renders={lifestyleRenders} setModalVisible={setHighResModalVisible}
        modalVisible={highResModalVisible} selectedRender={selectedHighResRender} />
        <ComplaintModal selectedImgComplaint={selectedImgComplaint} setSelectedImgComplaint={setSelectedImgComplaint} complaintModal={complaintModal} setComplaintModal={setComplaintModal} renders={lifestyleRenders}
        checkIfComplaintInProgress={checkIfComplaintInProgress} checkIfFlagIsDisabled={checkIfFlagIsDisabled} />

        <ImageViewerContext.Provider
        value={{
            type: "lifestyles",
            isVisible: sceneImageModal,
            handleOk: handleCancel,
            handleCancel: handleCancel,
            defaultActiveTabKey: "lifestyle_images",
            tabs: tabs,
            selectedImg: selectedImg,
            setSelectedImg: setSelectedImg,
            imgAccessedFromCarousel: imgAccessedFromCarousel,
            setImgAccessedFromCarousel: setImgAccessedFromCarousel,
            selectedImgIndex: selectedImgIndex,
            setSelectedImgIndex: setSelectedImgIndex,
            image_list: filteredLifestyleRenders,
            renderFlagState: renderFlagState,
            renderInProgressTooltip: renderInProgressTooltip,
            getImageFromSrc: getImageFromSrc,
            handleImageNameUpdate: handleImageNameUpdate,
            resetRenderNameField: resetRenderNameField,
            handleAutoWhiteSwitch: handleAutoWhiteSwitch,
            renderCollaborationTooltip: renderCollaborationTooltip,
            expandImage: onImageExpand,
            changeVisibility: changeVisibility,
            image_visible: visibleImage,
            white_balance_renders_switch: whiteBalancedImages,
            renderReportButton: renderReportButton,
            handleDelete: handleDelete,
            handleGenerateHighResImage: handleGenerateHighResImage,
            handleDownload: handleDownload,
            setSelectedImgFromCarousel: setSelectedImgFromCarousel,
            render_generation: true,
            isImageVisible: isImageVisible,
            handleFavorite: handleFavorite
        }}>
            <ImageViewerTabs/>
        </ImageViewerContext.Provider>
    </>)
}