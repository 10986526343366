import React, { useState, useEffect } from "react";
import { Modal, Button, Checkbox, message, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';

const QAChecklistModal = (props) => {
  const [error, setError] = useState("");
  const rejectionDetails = props.rejectionDetails
  const checklist = props.adminQAList;
  const { company_id, need_to_model } = props.entityDetails;
  const entityId = props.entity_id;

  // Collect items for "Additional" dynamically
  const additionalItems = [];
  const additionalDict = {};
  const allChecklist = checklist ? Object.keys(checklist)
    .filter(mainKey => mainKey !== 'Additional')
    .reduce((acc, mainKey) => {
      const subKeys = Object.keys(checklist[mainKey] || {});
      return acc.concat(subKeys);
    }, []) : [];
  if (checklist?.Additional?.company_id?.[company_id]) {
    additionalItems.push(...Object.keys(checklist.Additional.company_id[company_id]));
    Object.entries(checklist.Additional.company_id[company_id]).forEach(([key, value]) => {
        additionalDict[key]= value;
    });
  }
  
  if (checklist?.Additional?.model_type?.[need_to_model]) {
    additionalItems.push(...Object.keys(checklist.Additional.model_type[need_to_model]));
    Object.entries(checklist.Additional.model_type[need_to_model]).forEach(([key, value]) => {
        additionalDict[key]= value;
    });
  }
  
  if(checklist?.Additional?.rejection && rejectionDetails && rejectionDetails.length > 0){
    additionalItems.push(...Object.keys(checklist.Additional.rejection));
    Object.entries(checklist.Additional.rejection).forEach(([key, value]) => {
        additionalDict[key]= value;
    });
  }

  const [checkedItems, setCheckedItems] = useState(() => {
    const savedState = localStorage.getItem("QAAdminChecklistState");
    const parsedState = savedState ? JSON.parse(savedState) : {};
    return parsedState[entityId] || {};
  });

  useEffect(() => {
    const parsedState = {};
    parsedState[entityId] = checkedItems;
    localStorage.setItem("QAAdminChecklistState", JSON.stringify(parsedState));
  }, [checkedItems, entityId]);

  const handleCheckboxChange = (item) => {
    setError("");
    setCheckedItems((prev) => ({
      ...prev,
      [item]: !prev[item],
    }));
  };

  const handleNext = () => {
    const allItems = [
      ...allChecklist,
      ...additionalItems,
    ];
    const uncheckedItems = allItems.some((item) => !checkedItems[item]);
    if (uncheckedItems) {
      setError("You must check all items before proceeding.");
      return;
    }
    setError("");
    props.setQAChecklistModal(false);
    props.onApprove();
  };

  return (
    <Modal
      visible={props.QAChecklistModal}
      maskClosable={false}
      closable={false}
      onCancel={() => {
        props.setQAChecklistModal(false);
        setError("");
      }}
      footer={[
        <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: '28px', alignItems: "center" }}>
          <div>
            <span style={{marginLeft: '5px'}}>* Hover over items to see details</span>
          </div>
          <div style={{ display: "flex"}}>
            <Button
              className="modal-okay-gray square font-14"
              onClick={() => {
                props.setQAChecklistModal(false);
                setError("");
              }}
            >
              Go Back to QA
            </Button>
            <Button
              style={{ textAlign: "center" }}
              className="modal-okay square font-14"
              onClick={handleNext}
              disabled={checklist && !Object.keys(checklist).length}
            >
              Next
            </Button>
          </div>
        </div>,
      ]}
      width={"69vw"}
      centered={true}
    >
      <div className="approve-product-modal">
        <h2 style={{ textAlign: "center", color: "#555555" }}>
          {props.title}
        </h2>
        {checklist && Object.keys(checklist).length ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              padding: "5px 20px 20px 20px",
            }}
          >

            {
                (Object.keys(checklist) || []).map((category, index) =>
                    (
                        category != "Additional" &&
                        <div className="qaChecklistBox">
                            <h3 style={{textAlign: 'center', marginBottom: '15px'}}>{category.toString()}</h3>
                            {(Object.keys(checklist[category]) || []).map((item, index) => (
                                <div style={{ display: 'flex', marginLeft: '8px', marginBottom: '8px', fontWeight: '700' }}>
                                    <Checkbox
                                    key={index}
                                    checked={checkedItems[item] || false}
                                    onChange={() => handleCheckboxChange(item)}
                                    className="custom-checkbox"
                                    style={{ marginRight: '8px' }}
                                    />
                                    {
                                      checklist[category][item] != "" ? 
                                      <Tooltip title={<span className="manrope f-12 white">{checklist[category][item]}</span>}>
                                        <span
                                          onClick={() => handleCheckboxChange(item)}
                                          style={{ cursor: 'pointer' }}
                                          dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                      </Tooltip>
                                      :
                                      <span
                                        onClick={() => handleCheckboxChange(item)}
                                        style={{ cursor: 'pointer' }}
                                        dangerouslySetInnerHTML={{ __html: item }}
                                      />
                                    }
                                </div>
                            ))}
                            </div>
                    )
                )
            }

            {additionalItems.length > 0 && 
            <div className="qaChecklistBox">
              <h3 style={{textAlign: 'center', marginBottom: '15px'}}>Additional</h3>
              {additionalItems.map((item, index) => (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px', marginBottom: '4px', fontWeight: '700' }}>
                    <Checkbox
                    key={index}
                    checked={checkedItems[item] || false}
                    onChange={() => handleCheckboxChange(item)}
                    className="custom-checkbox"
                    style={{ marginRight: '8px' }}
                    />
                    <Tooltip title={<span className="manrope f-12 white">{additionalDict[item]}</span>}>
                    <span onClick={() => handleCheckboxChange(item)} style={{ cursor: 'pointer' }}>
                        {item}
                    </span>
                    </Tooltip>
                </div>
              ))}
            </div>}
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <p>No checklist items available.</p>
          </div>
        )}

        {error && (
          <div style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
            {error}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QAChecklistModal;
