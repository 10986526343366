import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CodeOutlined } from '@ant-design/icons';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Form, message } from "antd";
import {Link} from "react-router-dom";

class MyConfirmForm extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      error: '',
      verificationCode: '',
    };
  }

  onSubmit = (event) => {
    console.log(this.props.verifyUser)
    if (this.props.verifyUser != undefined) {
      let error = this.props.verifyUser(this.state.verificationCode);
      if (error != null) {
        this.setState({ error });
      }
    }
    else {
      this.props.onSubmit(this.state.verificationCode)
        .then((user) => {
          if (this.props.loginUser != undefined) {
            this.props.loginUser();
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
    
  }

  performResendCode = () => {
    this.props.onResend()
      .then((user) => {
        message.success('Verification code sent successfully');
        this.setState({ error: '' });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  onResend = (event) => {
    event.preventDefault();
    if (this.formRef && this.formRef.current) {
      this.formRef.current.resetFields();
    }
    this.state = {
      error: '',
      verificationCode: '',
    };
    if (this.props.verifyUser == undefined)
    {
      this.performResendCode();
    } else {
      this.props.sendVerificationCode();
    }
    

  }

  changeVerificationCode = (event) => {
    this.setState({ verificationCode: event.target.value });
  }

  performCancel = () => {
    if (this.props.onCancel != undefined) {
      this.props.onCancel();
    } else {
      window.location.href = "/";
    }
  }

  render = () => {
    // const { getFieldDecorator } = this.props.form;
    const formDiv = {
      'display': 'flex',
      'justify-content': 'space-around',
      'margin-top': '40px',
    }

    const formStyle = {
      'background-color': 'white',
      'border-radius': '5px'

    }

    const logoStyle = {
      'display': 'flex',
      'justify-content': 'space-around',
      'margin-bottom': '30px'
    }
    return (
      <Row className='justify-on-mobile'>
        <Col span={12} className='hide-on-mobile'>
          <img className="branding-image" src={'/img/introImage.png'} />
        </Col>
        <Col  xs={22} sm={22} md={24} lg={12} className='web-desktop-width'>
          <div className="login-frm"  style={formDiv}>
          <div style={formStyle}>
                  <div style={formDiv}>
                    <div style={formStyle}>
                    <h3 className="manrope f-24 w-700 black-00">
                      Verify email  
                    </h3>
                  <p className='manrope f-14 w-500 black-00'>Please check your email for verification code</p>
                  <Form ref={this.formRef} onFinish={this.onSubmit} onFinishFailed={(e) => console.log(e) } className="login-form">
                    <Form.Item
                      name="Verification Code"
                      rules= {[{ required: true, message: <span className='manrope f-14 red'>Enter verification code</span> },
                            {pattern: '^[0-9]{1,6}$', message: <span className='manrope f-14 red'>Verification code must consist of 6 digits</span>},
                            {pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: <span className='manrope f-14 red'>Spaces are not allowed, whitespace found at the end</span>},
                      ]}
                    >
                      <Input
                       className='manrope f-14 w-400 placeholder black '
                              onChange={this.changeVerificationCode}
                              prefix={<CodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                              placeholder="Verification code"
                          />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" className="modal-okay square font-16 center-btn mt-16">
                      Submit
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button onClick={(e) => this.onResend(e)} htmlType="submit" className="modal-okay square font-16 center-btn">
                        Resend
                      </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.performCancel()} htmlType="submit" className='modal-okay-blue square font-16 center-btn border-none' >
                          Cancel
                        </Button>
                    </Form.Item>
                  </Form>
            <div className="manrope f-14 red text-center mt-10 mb-20"> {(this.props.codeError != undefined) ? this.props.codeError:this.state.error}</div>
            </div>
            </div>
            </div>
          </div>
        </Col>
        <Col md={24} lg={12} className='show-on-mobile justify-in-center'>
            <img className="branding-image login-page-mobile-img" src={'/img/introImageMobile.png'} />
          </Col>
      </Row>
    );
  }
}
MyConfirmForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onResend: PropTypes.func,
  error: PropTypes.string,
};

const mapStateToProps = state => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});


const ConfirmForm = connect(mapStateToProps, null)(MyConfirmForm);

// export default  Form.create({ name: 'normal_login' })(ConfirmForm);
export default ConfirmForm;
