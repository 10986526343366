import React, { useEffect, useState, useRef } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  FilterOutlined,
  PlusCircleOutlined,
  ShareAltOutlined,
  LoadingOutlined,
  PlusCircleFilled
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Card,
  Select,
  Radio,
  Form,
  Button,
  Modal,
  Checkbox,
  Tooltip,
  Breadcrumb,
  message,
  Tabs,
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileConstants from "../../../../FileConstants";
import ProjectsList from "../DashboardProjects/ProjectsList";
import Filter from "../CustomerProject/Filter";
import _ from "lodash";

const { TabPane } = Tabs;
const { Option } = Select;
const formRef = { useRef };

let SEQUENCE_ID_OTHER = -1;
let SEQUENCE_ID_OWNED = -1;

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_EMAIL = FileConstants.MANAGED_CUSTOMER_EMAIL;
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;

const ProjectLibrary = (props) => {
  const [tabActiveKey, setTabActiveKey] = useState('approved');
  const [reviewData, setReviewData] = useState([]);
  const [inProgressData, setInProgressData] = useState([]);
  const [filteredInProgressData, setFilteredInProgressData] = useState([]);
  const [filteredReviewData, setFilteredReviewData] = useState([]);
  const [mspData, setMspData] = useState([]);
  const [filteredMspData, setFilteredMspData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedModelType, setSelectedModelType] = useState([]);
  const [selectedReviewStatus, setSelectedReviewStatus] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [currentPage, setCurrentPage] = useState(props.match.path)
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(false);
  const [tempProjectCount, setTempProjectCount] = useState([]);
  const [currentProjectAccessLevel, setCurrentProjectAccessLevel] = useState("");

  const tabs = [
    {
      key: 'msp',
      title: `${MANAGED_CUSTOMER_USERNAME}'s Approved Projects`,
      data: filteredMspData
    },
    {
        key: 'approved',
        title: 'Approved Projects',
        data: filteredData
    }
  ];

  const handleTabChange = (key) => {
    setTabActiveKey(key);
    window.history.pushState(null, null, `?tab=${key}`);
  };

  const getProjectPayload = () => {
    let status = "";
    status = "status__exact='approved'"
    let payload = {
      order_by: "last_modified_stamp",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "thumbnail",
        "company_id",
        "meta_data",
        "status",
        "last_modified",
        "access_level",
        "last_modified_stamp"
      ],
      filter_string: `(${status})&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','viewer','co_owner','quality_control', 'editor'])&&(is_hidden__not=true)`,
    };

    return payload;
  };

  const getMspProjectPayload = () => {
    let status = "";
    status = "status__exact='approved'"
    let payload = {
      order_by: "last_modified_stamp",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "thumbnail",
        "company_id",
        "meta_data",
        "status",
        "last_modified",
        "access_level",
        "last_modified_stamp"
      ],
      filter_string: `(${status}&&username__exact='${MANAGED_CUSTOMER_USERNAME}')&&(access_level__in=['owner','viewer','co_owner','quality_control', 'editor'])&&(is_hidden__not=true)`,
    };

    return payload;
  };

  const fetchProjectData = async () => {
    let payload = getProjectPayload();

    await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
      setProjectData(res.data);
      setFilteredData(res.data);
    });
  };

  const fetchMspProjectData = async (type) => {
    let payload = getMspProjectPayload();

    await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
          setMspData(res.data);
          setFilteredMspData(res.data);
    });
  };

  useEffect(() => {
    const fetchAllProjectData = async (type) => {
      getOwnedProjectCount();
      setIsDataLoading(true);
      if (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME) { 
        await fetchMspProjectData();
        setTabActiveKey('msp')
      }
      await fetchProjectData();
      setIsDataLoading(false);
    };

    fetchAllProjectData();
  }, []);

  const getOwnedProjectCount = () => {
    const counts = localStorage.getItem("entity_counts");
    if (counts) {
        let entityCounts = JSON.parse(counts);
        setTempProjectCount(entityCounts);
    }
    else {
        let payload = {
            username: CUSTOMER_USERNAME,
            project_homepage: true
        };
        if (COMPANY_ID != undefined) {
            payload.company_id = COMPANY_ID;
        };
        
        if(IS_MS_PROVIDER){
            if(MANAGED_CUSTOMER_USERNAME){
                payload['username'] = MANAGED_CUSTOMER_USERNAME
            }
            if(MANAGED_COMPANY_ID){
                payload['company_id'] = MANAGED_COMPANY_ID
            }
        }

        axios.post(ENVIRONMENT.HOME_SCREEN_STATS, payload).then((res) => {
            let approved_project = {
                "type": "approved",
                "count": (res.data.library_data.find(ele => ele.type === "project"))?.count
            }
            res.data.library_data = res.data.library_data.filter(ele => ele.type !== "project")
            let active_project = {
                "type": "active",
                "count": (res.data.review_data.find(ele => ele.type === "project"))?.count
            }
            let requested_project = {
                "type": "requested",
                "count": (res.data.in_progress_data.find(ele => ele.type === "project"))?.count
            }
            //Setting Entity Counts in localStorage
            let entity_counts = _.cloneDeep(res.data.library_data);
            
            entity_counts.push(approved_project)
            entity_counts.push(active_project)
            entity_counts.push(requested_project)
            
            localStorage.setItem('entity_counts', JSON.stringify(entity_counts));
            setTempProjectCount(entity_counts);
        })
    }
  }

  const searchFilter = (searchValue, data) => {
    return data.filter((item) => {
      return item.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  useEffect(() => {
    let filteredData = projectData;
    if (selectedModelType.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedModelType.includes(item.entity_type);
      });
    }
    if (selectedReviewStatus.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedReviewStatus.includes(item.status);
      });
    }
    if (searchValue.length > 0) {
      filteredData = filteredData.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setFilteredData(filteredData);

    const filteredInProgressData = searchFilter(searchValue, inProgressData);
    const filteredReviewData = searchFilter(searchValue, reviewData);
    const filteredMspData = searchFilter(searchValue, mspData);
    setFilteredInProgressData(filteredInProgressData);
    setFilteredReviewData(filteredReviewData);
    setFilteredMspData(filteredMspData);
  }, [searchValue, selectedModelType, selectedReviewStatus]);

  const handleNewProject = () => {
    props.history.push(`/new_project?origin=${currentPage}`);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabActiveKey(tab);
    }
  }, []);

  const onDelete = () => {
    setDeleteLoader(true);

    let payload = {
        "username": CUSTOMER_USERNAME,
        "project_id": currentProjectId
    }

    if(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == 'co_owner'){
        payload["action"] = "delete_project"
    }
    else{
        payload["action"] = "hide_project"
    }

    console.log(payload)

    axios.post(ENVIRONMENT.UPDATE_PROJECT, payload)
        .then((response) => {
            // Retrieve the counts array from localStorage
            const counts = JSON.parse(localStorage.getItem("entity_counts"));

            if (counts) {
            const projectEntry = counts.find(entity => entity.type === tabActiveKey);

            if (projectEntry && projectEntry.count > 0) {
                projectEntry.count -= 1;
            }

            // Store the updated counts array back in localStorage
            localStorage.setItem("entity_counts", JSON.stringify(counts));
            } else {
            console.error("Counts not found in localStorage.");
            }
            
            message.info('Project Deleted.');
            setDeleteLoader(false);
            setDeleteModal(false);
            window.location.reload()
        })
        .catch((error) => {
            console.log(error);
            message.error('Error in deleting project');
            setDeleteLoader(false);
            setDeleteModal(false);
        });
  }

  return (
    <div className="my-products">
      <CustomerMainLayout selected="14" >
        <Row
          className="justify-space-between"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <>
            <Col lg={10} xl={10}>
              <div
                className="manrope f-24 w-700"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                Projects
              </div>
            </Col>
            <Col lg={12} xl={12} className="justify-in-end">
              <Col lg={15} xl={15}>
                <Form>
                  <Input.Group compact size="large" className="d-inline">
                    <Input
                      placeholder="Search by product, space, etc..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      className="manrope f-14 mat-search-bar"
                      style={{ marginLeft: IS_MS_PROVIDER ? 5 : "" }}
                    ></Input>
                  </Input.Group>
                </Form>
              </Col>
              <Col>
                <Tooltip
                  title={
                    <span className="manrope f-12 white">New Project</span>
                  }
                >
                  <Button
                    onClick = {handleNewProject}
                    className="new-product-btn black f-14 ml-8">
                    <PlusCircleFilled />&nbsp;
                    New Project
                  </Button>
                </Tooltip>
              </Col>
            </Col>
          </>
        </Row>
        <div>
          <span>
            <Col span={24}>
            <Tabs
                onChange={(key) => handleTabChange(key)}
                className="product-tab library"
                tabBarGutter={16}
                size="large"
                activeKey={tabActiveKey}
                tabBarStyle={{
                  display: IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME ? 'flex' : 'none',
                }}
            >
                {
                (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME ? tabs : tabs.slice(1))?.map((tab) => {
                    return (
                    <TabPane tab={tab.title + (isDataLoading ? tempProjectCount.find(entity => entity.type === tab.key)?.count ? ` (${tempProjectCount.find(entity => entity.type === tab.key)?.count})` : '' : ` (${tab.data.length})` ) } key={tab.key} style={{ padding: "0px 10px" }}>
                        <div className="product-tab library">
                        {
                            isDataLoading ? <DottedLoader /> : 
                            <ProjectsList 
                            data={tab.data} 
                            setDeleteModal={setDeleteModal}
                            setCurrentProjectId={setCurrentProjectId}
                            setCurrentProjectAccessLevel={setCurrentProjectAccessLevel}
                            tabActiveKey={tabActiveKey}
                            />
                        }
                        </div>
                    </TabPane> )
                })
                }
                </Tabs>
            </Col>
          </span>
        </div>
      </CustomerMainLayout>

      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        visible={deleteModal}
        closable={false}
        footer={[
            <span className="justify-in-center">
                <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setDeleteModal(false)}>
                    Cancel
                </Button>
                <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => onDelete()}>
                    {deleteLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span>Yes, Delete Project </span>}
                </Button>
            </span>
        ]}>
        <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h2 className="manrope f-16 black-00 w-600">{(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == "co_owner") ? 
                "You are about to delete this project" : "You are about to Delete this project for your account."}</h2>
            <p className="manrope f-14 black-55">
                {(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == "co_owner") ? 
                "Since you are the owner of this project, it will be deleted for all users." : "Since you were not the owner of this project, it will still be visible to other users"}
            </p>
        </div>
    </Modal>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(ProjectLibrary));
