import React, {useState, useEffect} from 'react';
import ENVIRONMENT from '../../../../environments';
import { DownloadOutlined, DownOutlined, LoadingOutlined, CheckCircleFilled, ArrowRightOutlined, ArrowLeftOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    Row,
    Col,
    Button,
    Tabs,
    message,
    Menu,
    Dropdown,
    Space,
    Card,
    Tooltip,
    Image,
    Modal,
    Upload,
    Checkbox,
    List,
    Typography,
    Collapse,
    Carousel
} from 'antd';
import { Form } from '@ant-design/compatible';
import './RoomDetailsView.css';
import AdminMainLayout from '../AdminMainLayout';
import Three360Viewer from "../../CustomerComponents/Three360Viewer"
import { Link } from "react-router-dom";
import { saveAs } from 'file-saver';
import ReviewProduct from "../../CustomerComponents/ReviewForm/ReviewProductForm";
import * as Constants from "../../CustomerComponents/Constants";
import { ReactComponent as EmptyBox } from '../../../../icons/empty-box.svg'
import { ReactComponent as SmallEmptyBox } from '../../../../icons/small-empty-box.svg'
import PanoramaViewer from "../../CustomerComponents/PanoramaViewer"
import RoomPhotosViewer from '../../../App/CustomerComponents/RoomPhotosViewer'
import ThreesixtyTestRenderViewer from '../../../App/CustomerComponents/ThreesixtyTestRenderViewer'
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import axios from 'axios';
import AWS from "aws-sdk";
import QAChecklistModal from '../AdminQAChecklist/QAChecklistModal';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

const outsideViewBundleURL = {
    Countryside: ENVIRONMENT.COUNTRY_SIDE_URL,
    Garden: ENVIRONMENT.GARDEN_URL
}


const RoomDetailsView = (props) =>  {
    let companyId = localStorage.getItem('company_id');
    const { getFieldDecorator } = props.form;
    const [imageUrl, setImageUrl] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [countRoomDimensions, setCountRoomDimensions] = useState(-1);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, setLoading] = useState(false);
    const [approveAdminRenderModal,setApproveAdminRenderModal] = useState(false);
    const [showDetailedRejection, setShowDetailedRejection] = useState(false);
    const [testRenders, setTestRenders] = useState([]);
    const validStatusMoveAdminQA = [5, 4, -5, -4, 8, 11, 12];
    const [isFinalRender, setIsFinalRender] = useState(false);

    useEffect(()=>{
        console.log("here we are", props.roomReducer.room)
        setTestRenders(props?.roomDetails?.test_renders)

        for (const render of props?.roomDetails?.test_renders || []) {
            if (render.generated_by === 'Admin') {
                setIsFinalRender(true);
                break;
            }
        }


        if( props.validation_report
            && props.validation_report.High
            && props.validation_report.High.room_dimensions
            && Object.keys(props.validation_report.High.room_dimensions).length > 0
        ){
           setCountRoomDimensions(Object.keys(props.validation_report.High.room_dimensions).length)
        }
    },[props])

    const handleImage = (event) => {
        event.preventDefault();
        setImageUrl(event.currentTarget.getAttribute('href'));
        setShowImage(true);
    }

    const handleImageSelect = (index) => {
        
        const updatedTestRenders = [...testRenders];
        updatedTestRenders[index] = {
            ...updatedTestRenders[index],
            is_hidden: !updatedTestRenders[index].is_hidden,
        };

        setTestRenders(updatedTestRenders);
      };

    const showApproveAdminRenderModal = () => {
        setApproveAdminRenderModal(true);
    }

    const cancelApproveAdminRenderModal = () => {
        setApproveAdminRenderModal(false);
    }

    const handleApproveAdminRender = () => {
        props.onApprove();
        if (props?.roomDetails && props.roomDetails.testRenders != testRenders) {
            axios.post(ENVIRONMENT.UPDATE_ROOM, {room_id : (props.roomDetails.room_id).toString(), test_renders: testRenders});
        }
        cancelApproveAdminRenderModal();
    }

    const showRejectionModal = () => {
        setShowDetailedRejection(true);
    };

    const handleCancel = () => {
        setShowDetailedRejection(false);
    };

    const downloadRoomFile = (url,filename) => {
        let loader = message.loading('Preparing to download..',0)

        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
        if (this.status == 200) {
            setTimeout(loader);

            var myBlob = this.response;
            saveAs(myBlob, filename);

        }
        };
        xhr.send();
    }

    const getTestProductIds = () => {
        return new Promise((resolve, reject) => {
            var jwt_token = localStorage.getItem('all3d_jwt_token');
            var cognito_credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
                Logins: {
                    'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
                }
            });
    
            cognito_credentials.refresh(() => {
                AWS.config.update({
                    region: 'us-west-2',
                    credentials: cognito_credentials
                });
                
                let S3 = new AWS.S3();
                S3.getObject({
                    Bucket: ENVIRONMENT.BUCKET_NAME,
                    Key: ENVIRONMENT.CONFIG_SPACE_FILE_KEY
                }, function(err, data) {
                    if (err) {
                        console.log(err, err.stack);
                        reject(err);
                    } else {
                        let configJSON = data.Body.toString().trim();
                        configJSON = JSON.parse(configJSON);
                        resolve(configJSON['Default']);
                    }
                });
            });
        });
    }

    const handleTestInSceneCreator = () => {
        setLoading(true);
        if(props.roomDetails.dummy_scene_id == ""){
            createCollection("Dummy-collection-" + props.roomDetails.room_id, props.roomDetails.room_id)
        }else{
            window.location.href = '/scene_builder/' + props.roomDetails.dummy_scene_id;
        }
    };

    const createCollection = (collectionName, roomId) => {
        const payload = {
          collection_name: collectionName,
          username: localStorage.getItem('username'),

        };
    
        if (companyId != undefined) {
          payload.company_id = companyId;
        }
    
        axios
            .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
            .then((res) => {
              addRoomToCollection(res.data.collection_id, roomId);
            })
            .catch(() => {
                setLoading(false);
            });
      };

      const addRoomToCollection = (collectionId, roomId) => {
        const payload = {
            collection_id: collectionId,
            room_id: roomId,
            username: localStorage.getItem('username'),
        }
        axios
            .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
            .then((res) => {
                const areas = res.data.space_areas;
                addProductsToCollection(collectionId, roomId, areas);
            })
            .catch(() => {
                console.log("Room could not be added to collection ", collectionId)
                setLoading(false);
            });
      };

      const addProductsToCollection = async (collectionId, roomId, areas) => {
        let product_ids = []
        try {
            let product_list = []
            product_ids = await getTestProductIds();
            for(let i=0; i < product_ids.length; i++){
                let product = { "product_id": product_ids[i]}
                if(areas.length > 0)
                    product.area_name = areas[0]
                product_list.push(product);
            }
            let payload = {"collection_id": collectionId, 
                        "products_list": product_list, 
                        username: localStorage.getItem('username'),}
            if(areas.length > 0)
                payload.space_allocation = true;
            axios
            .post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
            .then((response) => {
                createScene(collectionId, roomId);
            })
            .catch(() => {
                setLoading(false);
            });
        } catch (error) {
            console.error('Error getting test product IDs:', error);
        }
      }

      const createScene = (collectionId, roomId) => {
        {
          const payload = {
            scene_name: "Dummy-scene-" + roomId,
            collection_id: collectionId,
            room_id: roomId,
            is_hidden: true,
            username: localStorage.getItem('username'),
          };
    
          if (companyId != undefined) {
            payload.company_id = companyId;
          }
    
          axios
              .post(ENVIRONMENT.UPDATE_SCENE, payload)
              .then((res) => {
                const sceneId = res.data['scene_id'];

                let update_payload = {"room_id": roomId, "dummy_scene_id": sceneId}
                axios.post(ENVIRONMENT.UPDATE_ROOM, update_payload).then((response) => {
                    console.log(response);
                    setLoading(false);
                    window.location.href = '/scene_builder/' + sceneId;
                });
              })
              .catch(() => {
                setLoading(false);
              });
        }
      };

    const menu = (
        <Menu style={{width: "200px"}}>
            {(props.roomReducer.room.download_links || []).map((ele, index) => {
               return  <Menu.Item key={index}>
                    <a target="_blank" href={ele.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']))}>{ele.display_name} download link <DownloadOutlined style={{float: "right"}} type="download" /></a>
                </Menu.Item>
            })}
        </Menu>
    );

    let admin_menu = null;
    if(props.roomReducer.room.model_status == "-5" || props.roomReducer.room.model_status == "3" || props.roomReducer.room.model_status == "-4"){
        admin_menu = (
            <Menu style={{ width: "175px",fontFamily:"Avenir" }}>
                <Menu.Item key={1}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.setRenderOverrideModal(true)}> Custom 360s</span>
                </Menu.Item>
                <Menu.Item key={2}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.setProductModelOverrideMadal(true)}> Model Override</span>
                </Menu.Item>
                <Menu.Item key={3}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.setProductRegenerateConfirm(true)}> Regenerate</span>
                </Menu.Item>
                <Menu.Item key={4}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.setConfirm3604K(true)}> Regenerate 360 in 4K</span>
                </Menu.Item>
                {(validStatusMoveAdminQA.includes(parseInt(props.roomReducer.room.model_status))) &&
                <Menu.Item key={5}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.sendSpaceToAdminQA()}>Move to Admin QA</span>
                </Menu.Item>
                }
            </Menu>
        );
    }
    else if(validStatusMoveAdminQA.includes(parseInt(props.roomReducer.room.model_status))){
        admin_menu = (
            <Menu style={{ width: "175px",fontFamily:"Avenir" }}>
                <Menu.Item key={1}>
                    <span style={{cursor: "pointer"}} onClick = {() => props.sendSpaceToAdminQA()}>Move to Admin QA</span>
                </Menu.Item>
            </Menu>
        );
    }

    let total_file_size = 0;
    let high_poly_model_size = 0, glb_size = 0, archive_size = 0;
    if(props.roomDetails.model_info != undefined) {
        high_poly_model_size = props.roomDetails.model_info.size_high_poly_model || 0;
        glb_size = props.roomDetails.model_info.glb_size || 0;
        total_file_size = high_poly_model_size + glb_size;
    }

    if(high_poly_model_size == 0){
        // high poly and Vray size not found in DB so doing the head request and calculating the archive size
        props.getArchiveSize();
        archive_size = props.archiveSize/(1024*1024); //converting in MBs
        total_file_size = total_file_size + archive_size;
    }

    if(glb_size == 0){
        // glb size was not found in DB so doing the head request and calculating the glb size
        props.getGlbSize();
        glb_size = props.glbSize/(1024*1024); // converting the size in MBs
        total_file_size = total_file_size + glb_size;
    }

    let room_references = ''
    if (props.roomDetails.reference_urls != undefined && props.roomDetails.reference_urls.length > 0) {
        room_references = props.roomDetails.reference_urls.map((ele, index) => {
            return (
                    <div className="note-bg-artist gray urls" style={{marginBottom: 5, marginRight: 10}}>
                        <span className="note-text gray urls"><a href={ele} target='_blank' style={{color: "#276DD7"}}>{ele}</a></span>
                    </div>
        )
        });
    }

    let room_tags = ''
    if (props.roomDetails.tags != undefined && props.roomDetails.tags.length > 0) {
        room_tags = props.roomDetails.tags.map((ele, index) => {
            return (
                <div className ="note-bg-artist gray" style={{marginBottom: 10, marginRight: 7, border: "1px solid #E4E4E4"}}>
                    <span className="note-text gray" style={{fontSize: 14}}>{ele}</span>
                </div>
            )
        });
    }

    let raw_files = ''
    if(props.roomDetails && props.roomDetails.raw_files){
        raw_files = props.roomDetails.raw_files.map((ele, index) => {
        let file_url = ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
        return <div className="note-bg-artist gray urls" style={{marginBottom: 5}}>
        <span className="note-text gray urls"><a href={file_url} target='_blank' style={{color: "#276DD7"}}>{file_url}</a></span>
        </div>
    });
    }

    let floorplanPhotos = (props.roomDetails.floorplan || []).map((ele, index) => {
        let fileURL = ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + ele.originFileObj.uid + '/' + ele.name;
        if (ele.name.includes('.jpg') || ele.name.includes('.png') || ele.name.includes('.jpeg')) {
            return(
                <div style={{textAlign:"left", marginBottom: 30}}>

                    <div className="manrope f-20 grey-99" style={{textAlign:"left", marginBottom: 10}}>
                        Floor Plan
                    </div>
                    <Col>
                        <Card className="product-artist-card floorplan">

                            <Image
                            className="product-photo-artist floorplan" src={fileURL}
                            />

                        </Card>
                    </Col>
                </div>

               )

        }
        else {
            return (<span>
                <p className="apply-Avenir"><a href={fileURL} target='_blank'>{fileURL}</a></p>
            </span>)
        }

    });

    let labelledFloorPlan = null;
    labelledFloorPlan =
            <div style={{textAlign:"left", marginBottom: 30}}>

                <div className="manrope f-20 grey-99" style={{textAlign:"left", marginBottom: 10}}>
                    Labelled Floor Plan
                </div>
                <Col>
                    <Card className="product-artist-card floorplan">

                        <Image
                        className="product-photo-artist floorplan" src={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.FLOOR_PLAN_URI + props.params.id + '.jpg'}
                        />

                    </Card>
                </Col>
            </div>

    let room_text_guidelines = '';
    if (props.roomDetails.guidelines != '' && props.roomDetails.guidelines != undefined) {
    room_text_guidelines = props.roomDetails.guidelines.split('\n').map((item, i) => {
        if(item != '')
            return <li><div className="category-artist medium align-left" key={i}>{item}</div></li>;
        });
    }


    const getActorUsername = (item) => {
        let result = "";
        if(item.username != ""){
            result = item.username;
        }
        else if(item.username == ""){
            // No username readily available, figure out who performed the action.

            // CASE: Artist assigned a product to himself
            if(item.attribute_name == "assigned_artist" && item.after_value != ""){
                result = item.after_value;
            }
        }
        return result;
    }

    const getItemHistoryAction = (item) => {

        let result = ""
        if(item.additional_message == "auto_unassign"){
            result = "Model was auto-unassigned by System"
        }
        // model was deleted by artist
        else if(item.additional_message == "model_delete_request"){
            result = "Model was deleted by Artist"
        }
        // admin model status change
        else if(item.additional_message == "admin_status_change" && item.after_value == "3"){
            result = "Model was moved to Admin QA"
        }
        // Handle Rejection cases
        else if(item.additional_message == "rejected_by_customer"){
            result = "Model was rejected by Customer"
        }
        else if(item.additional_message == "rejected_by_admin"){
            result = "Model was rejected by Admin"
        }
        else if(item.additional_message == "transfer_msp_room"){
            result = `Model was moved from MSP(${item.before_value}) to customer`;
        }
        else if(item.additional_message=="automated_variation_submission_request"){
            result = "Room variation request was processed by the System."
        }
        // Handle cases for model approval and rejection
        else if(item.attribute_name && item.attribute_name=="model_status" && item.username != "System"){
            if(item.before_value == "3" && item.after_value == "4"){
                result = "Model was Approved by Admin"
            }
            else if(item.before_value == "4" && item.after_value == "5"){
                result = "Model was Approved by Customer"
            }
            else if(item.before_value == "-5" || item.before_value == "-4" && item.after_value == "4"){
                result = "Customer model rejection was denied by Admin"
            }
            else if(item.after_value == "6" && item.additional_message=="quick_render_request"){
                result = "Artist uploaded the model to generate model validations"
            }
            else if(item.after_value == "6" && item.additional_message=="model_submission_request"){
                result = "Artist submitted the model to generate space 360s"
            }
            else if(item.after_value == "11"){
                result = "Model's automation job failed."
            }
            else if(item.after_value == "12"){
                result = "Model's automation job aborted."
            }
        }
        // Return appropriate admin override action
        else if(item.attribute_name == "" && item.admin_override_action != ""){
            if(item.admin_override_action == "override_threesixties"){
                result = "Admin Override was used to Replace the 360s for this space."
            }
            else if(item.admin_override_action == "space360_at_4K"){
                result = "Admin Override was used to regenerate space 360 at 4K."
            }
            else if(item.admin_override_action == "regenerate_qa_assets" || item.admin_override_action == "model_override"){
                result = "Admin Override was used to Regenerate space 360."
            }
        }
        // Return Assigned to artist or unasssigned from artist
        else if(item.attribute_name && item.attribute_name == "assigned_artist"){
            if(item.before_value == "" && item.after_value != ""){
                if(item.username != ""){
                    result = "Model was assigned to Artist by Admin"
                }
                else{
                    result = "Model was self assigned by Artist"
                }
            }
            else if(item.before_value != "" && item.after_value == ""){
                if(item.username != ""){
                    result = "Model was unassigned by Admin"
                }
            }
            else if(item.before_value != "" && item.after_value != ""){
                if(item.username != ""){
                    result = "Admin assigned the model to Another artist"
                }
            }
        }
        // Track the system actions
        else if(item.attribute_name && item.attribute_name == "model_status" && item.username == "System"){
            if(item.before_value == "6" && item.after_value == "8"){
                if(item.additional_message == "space_valid")
                    result = "Validations were generated for the space model"
                if(item.additional_message == "space_invalid")
                    result = "Validations were generated for the space model"
            }
            else if(item.before_value == "6" && item.after_value == "3"){
                result = "System generated the space 360 and moved the space to QA Pending"
            }
            else if(item.before_value == "6" && item.after_value == "4"){
                result = "System generated the space 360 and moved the space to Customer QA"
            }
        }

        else if(item.attribute_name == "model_retries" && item.after_value == "0"){
            result = "Model retries were reset by Admin"
        }
        return result
    };

    // in this function, additional_attachment data will be passed as ele
    const getRejectionDetailsForActivityModel = (ele) => {
        return (
                <div style={{marginTop: 20}}>
            {ele != "" && ele.rejection_details != "" ?
                <span style={{paddingRight: 5}}>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7, paddingLeft: 5}}>
                    Other Information
                </span>
                <span>
                <span className="category-artist" style={{marginBottom: 30, fontSize: 17, paddingLeft: 5, color: '#555555'}}>
                    {ele.rejection_details}
                </span>
                </span>
            </span> : ""}

      {( ele != "" && ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

          <span>
          <div className="category-artist" style={{textAlign: 'left', marginBottom: 7, marginTop: 20, paddingLeft: 5}}>
          Uploads / Files
          </div>
          <div style={{display: 'flex', flexWrap: 'wrap', paddingLeft: 5}}>
            {ele.reference_files && ele.reference_files.map((file,index) => (
                <a className="upload-a-settings" style={{display: 'flex'}} target="_blank" href={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}/> :
                (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                <div className="category-artist" style={{fontSize: 14, textAlign: 'left', marginRight: 10, color: '#555555'}}>{file.name}</div>
                </a>
            ))}
          </div>

      </span>: ''}
    </div>
        )
    }

    const formateDate = (dateTime) => {
        let date = dateTime.split(' ')[0];
        date = new Date(date);
        date = date.toLocaleDateString('US', {day: 'numeric', month: 'long', year: 'numeric'})
        return date
    }

    const activity_sort_menu = (
        <Menu style={{ width: "205px",fontFamily:"Avenir" }}>
            <Menu.Item key={1}>
                <span style={{cursor: "pointer"}} onClick = {() => props.sortActivity(true)}>Oldest Activity First</span>
            </Menu.Item>
            <Menu.Item key={2}>
                <span style={{cursor: "pointer"}} onClick = {() => props.sortActivity(false)}>Most Recent Activity First</span>
            </Menu.Item>
        </Menu>
    );

    // sum up rejection count
    let admin_rejection_count = 0;
    let customer_rejection_count = 0;
    let last_rejection_by = null;
    let last_rejection_date = null;
    if(props.rejectionDetails){
        props.rejectionDetails.map((ele) => {
            if(ele.qa_role == "admin")
            {
                admin_rejection_count += 1;
                if(!last_rejection_by){
                    last_rejection_by = 'admin';
                    last_rejection_date = ele.qa_time;
                }
            }
            if(ele.qa_role == "" && !ele.customer_rejection){
                customer_rejection_count += 1;
                if(!last_rejection_by){
                    last_rejection_by = 'customer';
                    last_rejection_date = ele.qa_time;
                }
            }
        })
    }

    // structuring data for item history
    let item_history_data = '';
    if (props.itemHistory && props.itemHistory.length > 0){
        item_history_data = props.itemHistory.map((ele, index) => (
            <div>
            <React.Fragment>
                <div style={{marginTop: 25}}>
                    <div className='note-bg-artist'>Date <span style={{color:"#276DD7", paddingLeft: 5}}>{Utilities.convertUtcToLocalTimezone(ele.action_time) || '-'}</span>
                    <span style={{paddingLeft: 25}}>Activity</span><span style={{color:"#276DD7", paddingLeft: 5}}>{getItemHistoryAction(ele)}</span>
                    </div>
                </div>

                <div style={{display: "flex"}}>
                    <div style={{paddingTop: 10, paddingLeft: 4}}>Activity By: <span style={{color:"#333333", paddingLeft: 5}}> {getActorUsername(ele)}</span></div>

                    {

                    ele.additional_attachment && ele.additional_attachment.rejection_details?
                    <div style={{paddingTop: 10, paddingLeft: 40}}>
                        {/* {console.log(props.itemHistory,"item history")} */}
                        <span>Rejection Comments: <span style={{color:"#333333", paddingLeft: 5}}> {ele.additional_attachment.rejection_details} </span></span>
                        {(ele.additional_attachment.reference_files != [] && ele.additional_attachment.reference_files != undefined) && (typeof (ele.additional_attachment.reference_files) == "object" && Object.keys(ele.additional_attachment.reference_files).length != 0)?
                        ele.additional_attachment.reference_files.map((file,index) => (
                            <span style={{display:'block'}}>
                            <a style={{color:"#276DD7", lineHeight: '120%'}} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                            {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                            <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                            (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                            <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                            </a>
                            </span>
                        ))
                    :''}
                    </div>
                    :''
                    }

                    {/* If model assigned to artist by admin, show assigned artist name as well */}
                    {ele.attribute_name == "assigned_artist" && ele.after_value != "" && ele.username != "" ?
                        <div style={{paddingTop: 10, paddingLeft: 30}}>Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.after_value}</span></div> : ""
                    }

                    {/* If admin unassigned a model from artist, show before artist name as well */}
                    {ele.attribute_name == "assigned_artist" && ele.after_value == "" && ele.username != "" ?
                        <div style={{paddingTop: 10, paddingLeft: 30}}>Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.before_value}</span></div> : ""
                    }

                    {/* Admin changed the artist, show the previous artist */}
                    {ele.attribute_name == "assigned_artist" && ele.after_value != "" && ele.before_value != "" && ele.username != "" ?
                        <div style={{paddingTop: 10, paddingLeft: 30}}> Previous Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.before_value}</span></div> : ""
                    }
                </div>

                {/* If the item was rejected show rejection details */}
                {ele.attribute_name == "model_status" && (ele.after_value == "-4" || ele.after_value == "-5") ? getRejectionDetailsForActivityModel(ele.additional_attachment) : ""}


            </React.Fragment>
            </div>
        ));
    }

    let roomElements = ""
    if(props.roomDetails.elements  && props.roomDetails.elements.length > 0){
        roomElements =  <div className="j-start-a-start">
        <Row>
        {props.roomDetails.elements.map(element => (
            element ?
            <Col span={6}>
            {console.log('ele',element)}
                <div className="bg-tag" style={{width:'fit-content'}}>
                    <span className="manrope f-16 black-33 capitalize">
                        {element.elementName}
                    </span>
                </div>
                {((element.elementImages && element.elementImages.length > 0) || ((element.elementText && !Array.isArray(element.elementText)) || (element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0)  )) &&
                <Card className="product-artist-card w-auto" style={{padding: 16, marginTop: 8, maxWidth: 250}}>
                    {element.elementImages && element.elementImages.length > 0 && <div>
                        <div className="manrope f-18 grey-77" style={{marginBottom: 8}}>Reference Images</div>
                        <div className="justify-in-start">
                        {element.elementImages.map(photo => (
                            <Card className="product-artist-card">
                                <Image
                                className="product-photo-artist" src={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name)}
                                />
                            </Card>
                        ))}
                        </div>

                    </div>}
                    {element.elementText && !Array.isArray(element.elementText) ?
                    <div>
                        <div className="manrope f-18 grey-77" style={{marginBottom: 8}}>Reference URL(s)</div>
                        <div className="manrope f-14 blue hover-change clamp-w-width" style={{overflow: 'hidden'}}>{element.elementText}</div>
                    </div>:
                    element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0 ?
                    <div>
                        <div className="manrope f-18 grey-77" style={{marginBottom: 8}}>Reference URL(s)</div>
                        {element.elementText.map((element_text) => (
                            <div style={{display:'block',marginBottom: 5}} className="manrope f-14 blue hover-change clamp-w-width"><a target="_blank" href={element_text}>{element_text}</a></div>
                        ))}
                    </div> :''}
                </Card>}
                </Col> : ''

            ))}
        </Row>
        </div>
    }

    let roomPhotos = (props.roomDetails.room_photos || []).map((ele, index) => {
        let fileURL = ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + ele.originFileObj.uid + '/' + ele.name;
        return(
            <Col>
                <Card className="product-artist-card">
                    <Image className="product-photo-artist" src={fileURL} />
                </Card>
            </Col>

        )
    });

    let outside_photos = null;
    if(props.roomDetails.outside_view && props.roomDetails.outside_view.image && !Array.isArray(props.roomDetails.outside_view)){
        outside_photos = (
            <div>
            {(outsideViewBundleURL[props.roomDetails.outside_view.name] != undefined)?
                <Row>
                    <p>You can use outside view from this </p>
                    <a style={{marginLeft: 6}} href={outsideViewBundleURL[props.roomDetails.outside_view.name].replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(props.roomDetails.platform))}>bundle.</a>
                    </Row>:""
            }
            <Card className="product-artist-card">
                <Image className="product-photo-artist" src={props.roomDetails.outside_view.image.replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(props.roomDetails.platform))} />
            </Card>
            </div>
        )
    }

    let outside_photos_custom = null;

    if(Array.isArray(props.roomDetails.outside_view_custom)) {
        outside_photos_custom = (props.roomDetails.outside_view_custom || []).map((ele, index) => {
            let fileURL = ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.originFileObj.uid + '/' + ele.name);
            return <Col>
            <Card className="product-artist-card">
                <Image
                className="product-photo-artist" src={fileURL}
                />
            </Card>
        </Col>
        });
    }

    const information_not_available = (
        <Col style={{display: 'flex', padding: '30px 0px 10px 10px', alignItems: 'center'}}>
            <SmallEmptyBox />
            <div>
                <span style={{fontSize: 16, color: '#555555', paddingLeft: 20}}>No Information Found</span>
            </div>
        </Col>
    )

    let room_hours_time1 = null, room_hours_time3 = null;
    if(props.roomReducer.room.time1 && props.roomReducer.room.time1 != ""){
        room_hours_time1 = props.roomReducer.room.time1;
    }
    if(props.roomReducer.room.time3 && props.roomReducer.room.time3 != ""){
        room_hours_time3 = props.roomReducer.room.time3;
    }


    let rejections_data = '';
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
        let ele = props.rejectionDetails[0];
      rejections_data = (
        <div style={{marginTop: 20}}>
          <div className='artist-upload-txt small align-text'>Date <span style={{color:"#333333"}}>{Utilities.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span></div>
          <div style={{display: "flex"}}>
              <Col span={(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ? 16 : 24}>
              <span style={{paddingRight: 5, overflow: 'hidden'}}>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7}}>
                    Comment
                </span>
                <span style={{display: 'flex'}}>
                <span className="category-artist" style={{marginBottom: 30, fontSize: 16, width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left'}}>
                    {ele.rejection_details}
                </span>
                </span>
              </span>
              </Col>


            {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

                    <Col span={8}>
                        <div className="category-artist" style={{textAlign: 'left', marginBottom: 7}}>
                            Uploads
                        </div>
                            {ele.reference_files && ele.reference_files.length > 0 ?
                                <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name']}>
                                {(ele.reference_files[0].name.includes('.jpg') || ele.reference_files[0].name.includes(".png") || ele.reference_files[0].name.includes(".PNG") || ele.reference_files[0].name.includes(".svg")) ?
                                <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name']}/> :
                                (ele.reference_files[0].name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                                <img className="upload-image-settings" src={'/img/ele.reference_files[0]-icon.png'}/> }
                                <span className="category-artist" style={{fontSize: 14, textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', width: '90%', whiteSpace: 'nowrap'}}>{ele.reference_files[0].name}</span>
                                </a>
                            : ''}
                    </Col>
            : ''}
          </div>

        </div>
      );
    }

    let detailed_rejection_data = '';
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
     detailed_rejection_data = props.rejectionDetails.map((ele, index) => (
        <div style={{marginTop:2, maxWidth:"941px"}}>
        <span style={{marginTop:15, display:"block" }} className='note-bg-artist'>Date <span style={{color:"#276DD7", paddingLeft: 3, marginRight:25}}>{Utilities.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span>  Activity By: <span style={{marginLeft:5,color:"#276dd7"}}>{ele.qa_person}</span></span>
        {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?
        <div style={{display: "block", margin: 2, padding:2, width:'100%'}} >
              <div>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7, width:"50%", float:'left'}}>
                    Comments:
                </span>
                <span className="category-artist" style={{ marginBottom: 7, width:"50%", float:'left'}}>
                    Uploads:
                </span>
              </div>
              <div style={{marginBottom:10, width:'100%'}}>
                <span className="" style={{textAlign:"left", fontSize: 14, color:"#333333", width:"51%" , float:'left'}}>
                    {ele.rejection_details}
                </span>
              <div style={{float:'left', width:'47%'}}>
                <Carousel style={{width:"100%"}} arrows={true} nextArrow={<RightOutlined />} prevArrow={<LeftOutlined/>}>

                    {ele.reference_files && ele.reference_files.map((file,index) => (
                        <a onClick={handleImage} style={{color:"#276DD7", lineHeight: '120%'}} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                        <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                        <img className="upload-image-settings-reject" src={ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                        (file.name.includes(".tiff") ) ?  <img className="upload-image-settings-reject" src={'/img/tiff_icon.png'}/> :
                        <img className="upload-image-settings-reject" src={'/img/file-icon.png'}/> }
                        </div>
                        <p className="category-artist" style={{fontSize: 14, textAlign: 'center', color: '#555555'}}>{file.name}</p>
                        </a>

                    ))}

                </Carousel>
              </div>

          </div>
        </div>
        :
        <div>
            <span className="category-artist" style={{textAlign: 'left', width:"10%", float:'left', marginTop:'4px'}}>
                Comments:
            </span>
            <span className="" style={{textAlign:"left", fontSize: 14, color:"#333333", width:"90%" , float:'left'}}>
                {ele.rejection_details}
            </span>
        </div>
        }
      </div>
     ));
    }

    let max_retries = 5;

    return (
        <AdminMainLayout selected='3'>
        {props.roomReducer.isLoading ? <DottedLoader/> :
            (<Row type="flex" justify="space-around" className="space-side-margins" style={{marginTop:"25px"}}>
            <Col span={24}>
                { (props.roomReducer.room.model_status == '4') ?
                    <h4>Space QA</h4>
                    :
                    ""
                }
                { (props.roomReducer.room.model_status == '5') || (props.roomReducer.room.model_status == '3') || (props.roomReducer.room.model_status == '4') || (props.roomReducer.room.model_status == '-4') || (props.roomReducer.room.model_status == '-5') ?
                    <Row type="flex" style={{justifyContent: "space-between",marginBottom:"30px"}}>
                        <p style={{fontFamily:"Avenir", fontSize:"16px",color: "#999999",marginBottom: 0,alignSelf:"center"}}>
                            <Link className="store-bd" to={`/admin_rooms`} style={{color:"#999999"}}>Room Library</Link> / <span className="active-route-link-color">{props.roomReducer.room.room_name}</span>
                        </p>
                        {/* <Tooltip title="Share Room" placement="left">
                            <Button  onClick={() => setSharingModal(true)} style={{width:"30px",height:"30px"}}>
                                <img src="/img/share-2.jpg" style={{position:"absolute", top:"4px", left:"5px",width:"20px",height:"20px"}}/>
                            </Button>
                        </Tooltip> */}
                        <Tooltip title="Embed" placement="bottom">
                            <Button onClick={() => props.setEmbedLink(true)} style={{marginRight: 2,width:"30px",height:"30px"}}>
                                <span style={{margin:"0",position:"absolute", top:"13px", left:"15.5px",transform: "translate(-50%,-50%)",fontSize:"17px"}}>{"</>"}</span>
                            </Button>
                        </Tooltip>
                    </Row>
                    :
                    ""
                }
                {props.roomReducer.room.model_status != 2 ?
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <Col span={24} style={{paddingBottom: 7}}>
                    {(!props.isFullScreen ?
                        <div style={{ zIndex: 1, top:(props.roomReducer.room.model_status == 4) || (props.roomReducer.room.model_status == 3) ? "74px" : "20px", right:"20px", float: 'left' } } id="components-dropdown-demo-dropdown-button">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button style={{fontFamily:"Avenir",width:"188px",height:"42px"}}>Download Links <DownOutlined /></Button>
                            </Dropdown>
                        </div> : "")}
                    <div style={{display: 'flex', float: 'right'}}>
                        <Tooltip title="You can test your space in scene creator and generate renders." placement="bottom">
                            <Button className="modal-okay" style={{marginRight: 2}} onClick={() => handleTestInSceneCreator()}>
                                <span className="modal-okay-text">Test in Scene Creator {loading ? <LoadingOutlined style={{ marginLeft:10 }}/> : ''}</span>
                            </Button>
                        </Tooltip>
                        {props.roomReducer.room.model_status == "3" || (validStatusMoveAdminQA.includes(parseInt(props.roomReducer.room.model_status))) ?
                        <Dropdown overlay={admin_menu} trigger={['click']}>
                            <Button className="modal-okay-orange" style={{minHeight: '42px', maxHeight: '42px'}}>
                            <span className="modal-okay-text">Admin Overrides <DownOutlined/></span>
                            </Button>
                        </Dropdown> : ""}
                    </div>
                </Col>
                </div>: ""}
                <div style={{ zIndex: 1, top:"20px", left:"20px" } }>
                </div>
                <div style={{display: 'flex'}}>
                {props.roomReducer.room.three_sixties == undefined || ( props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length == 0) && props.roomReducer.room.model_status != 2 ?

                    <Col span={props.compareView ? 12 : 24}>
                    <Card className={props.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: props.isFullScreen ?0:8}}>
                        {(props.roomReducer.room.model_status == 3 && !props.isFullScreen && !props.compareView &&  props.roomDetails.room_photos && props.roomDetails.room_photos.length > 0) ?
                        <Button style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"75px"}} className='modal-okay square comparison-btn admin-compare'  onClick={() => (props.setCompareView(!props.compareView), forceUpdate())}>
                            <span className="modal-okay-text">{props.compareView == false ? <span>Comparison View <ArrowRightOutlined/></span> : ""}</span>
                        </Button> : ""}
                            {props.roomReducer.isLoading ? <LegacyIcon className="model-pending-msg" type="sync" spin />: <div>
                                {props.roomReducer.room.model_status == '6' ? <h3 className="model-pending-msg">Model is being Processed.</h3>
                                : ''}
                            </div>}
                            {props.roomReducer.room.model_status != 2 ?
                                <ThreesixtyTestRenderViewer {...props} compareView={props.compareView} adminScrollCheck={false} adminCheck={true} embed={false} url={[ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + props.params.id + '/360Camera.jpg']}/>
                                // <Three360Viewer customClass={props.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} fullScreen={props.isFullScreen} url={[baseURL + props.params.id + '/360Camera.jpg']}/>
                            : ""}
                            {!props.compareView ?
                            <Tooltip title={(props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                <img
                                src={props.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}
                                alt="Fullscreen Icon"
                                style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}}
                                className="product-full-screen-icon"
                                onClick={() => {
                                    props.setFullScreen(!props.isFullScreen); forceUpdate();}} type={(props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                            </Tooltip> : ""}
                            {/* <PanoramaViewer customClass={props.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl={baseURL + props.params.id + '/360Camera.jpg'} /> */}
                    </Card>
                    </Col>
                    :
                    (props.roomReducer.room.three_sixties) ?
                    <Col span={props.compareView ? 12 : 24}>

                        <Tabs
                                tabBarStyle={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            centered
                            defaultActiveKey='1'
                        >
                            {props.roomReducer.room.three_sixties.map((name, index) => (
                                <TabPane tab={name.split(".")[0]} key={index + 1} className="tabAttr" >
                                    <Card className={props.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: props.isFullScreen ?0:8}}>
                                    {(props.roomReducer.room.model_status == 3 && !props.isFullScreen && !props.compareView &&  props.roomDetails.room_photos && props.roomDetails.room_photos.length > 0) ?
                                        <Button style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"75px"}} className='modal-okay square comparison-btn admin-compare'  onClick={() => (props.setCompareView(!props.compareView), forceUpdate())}>
                                            <span className="modal-okay-text">{props.compareView == false ? <span>Comparison View <ArrowRightOutlined/></span> : ""}</span>
                                        </Button> : ""}
                                        {props.roomReducer.isLoading ? <LegacyIcon className="model-pending-msg" type="sync" spin />: <div>
                                            {props.roomReducer.room.model_status == '2' ? <h3 className="model-pending-msg">Model will be ready by 24 hours.</h3>
                                            : ''}
                                        </div>}
                                        <ThreesixtyTestRenderViewer {...props} compareView={props.compareView} adminScrollCheck={false} adminCheck={true} embed={false} url={[ENVIRONMENT.getBaseURL(props.roomDetails.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + props.params.id + '/' + name]}/>
                                        {!props.compareView ?
                                            <Tooltip title={(props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                                <img
                                                src={props.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}
                                                alt="Fullscreen Icon"
                                                style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}}
                                                className="product-full-screen-icon"
                                                onClick={() => props.setFullScreen(!props.isFullScreen)} type={(props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                            </Tooltip>:""}
                                        {/* <Three360Viewer customClass={props.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} fullScreen={props.isFullScreen} url={[baseURL + props.params.id + '/' + name]}/> */}
                                        {/* <PanoramaViewer customClass={props.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl={baseURL + props.params.id + '/' + name} /> */}
                                    </Card>
                                </TabPane>
                            ))}

                        </Tabs>
                        </Col>

                    :
                    ""
                }
                {/* Displaying the test renders */}
                {props.roomReducer.room.model_status == 3 && props.compareView == true ?
                    <Col span={props.compareView ? 12 : 24} style={props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length > 0 ? {marginTop:50} : {}}>
                    <Card className={props.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: props.isFullScreen ?0:8, marginTop: (!props.isFullScreen &&  props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length > 0) ? 16: ""}}>
                        {(props.roomReducer.room.model_status == 3 && !props.isFullScreen && props.compareView &&  props.roomDetails.room_photos && props.roomDetails.room_photos.length > 0) ?
                        <Button style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"20px"}} className='modal-okay square comparison-btn admin-compare'  onClick={() => (props.setCompareView(!props.compareView), forceUpdate())}>
                            <span className="modal-okay-text">{props.compareView == false ? <span>Comparison View <ArrowRightOutlined/></span> : <span><ArrowLeftOutlined/> Back to Original View</span>}</span>
                        </Button> : ""}
                            {props.roomReducer.isLoading ? <LegacyIcon className="model-pending-msg" type="sync" spin />: <div>
                                {props.roomReducer.room.model_status == '6' ? <h3 className="model-pending-msg">Model is being Processed.</h3>
                                : ''}
                            </div>}
                            {props.roomReducer.room.model_status != 2 ?
                                <RoomPhotosViewer  {...props} compareView={props.compareView} adminScrollCheck={false} adminCheck={true} embed={false} />
                            : ""}
                    </Card>
                    </Col>
                    :
                    ""
                }
                </div>

                { (props.roomReducer.room.model_status != '50') ?
                   <React.Fragment>
                        <Row style={{ marginTop: "95px", marginBottom: 20}}>
                            <Col span={24} style={{display: 'flex'}}>
                                <div>
                                    <p className="artist-product-heading">{props.roomReducer.room.room_name}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>

                        {props.roomReducer.room.assigned_artist && props.roomReducer.room.assigned_artist != "" ?
                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Artist:</span>
                            <div className ="note-bg-artist gray">
                            <span className="note-text gray">{props.roomReducer.room.assigned_artist}</span>
                            </div>
                        </Col>
                        : ""}
                        {(props.roomDetails.customer_username && props.roomDetails.customer_username != "") ?
                            <Col style={{display:"flex",alignItems:"center",marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>Customer</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.roomDetails.customer_username}</span>
                                </div>
                            </Col>
                        : ""}
                        {(props.roomDetails.requested_for && props.roomDetails.requested_for != "") ?
                            <Col style={{display:"flex",alignItems:"center",marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>Requested For</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.roomDetails.requested_for}</span>
                                </div>
                            </Col>
                        : ""}
                        {props.roomDetails.category && props.roomDetails.category != "" ?
                        <Col style={{display:"flex",alignItems:"center",marginRight:'40px', marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Category</span>
                            <div className ="note-bg-artist gray">
                            <span className="note-text gray">{props.roomDetails.category}</span>
                            </div>
                        </Col> : ""}
                        <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>ID</span>
                            <div className ="note-bg-artist gray">
                            <span className="note-text gray">{props.params.id}</span>
                            </div>
                        </Col>
                        {props.roomDetails.brand_id ?
                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>Item ID</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.roomDetails.brand_id}</span>
                                </div>
                            </Col>
                        : "" }
                         {props.roomDetails.licensing_options && props.roomDetails.licensing_options.license ?
                            <Col style={{display:"flex",alignItems:"center", marginRight:'40px', marginBottom: 20}}>
                                <span className="category-artist" style={{marginRight:16}}>License</span>
                                <div className ="note-bg-artist gray">
                                    <span className="note-text gray">{props.roomDetails.licensing_options.license}
                                    {props.roomDetails.licensing_options.license == 'public' ? props.roomDetails.licensing_options.public_option == 'forCreativeCommon' ?
                                    <Tooltip title={<span className='manrope f-12 white'>{props.roomDetails.licensing_options.creative_common}</span>}>
                                        <img style={{marginLeft: 5}} src={FileConstants.getLicensingIcon(props.roomDetails.licensing_options.creative_common)}/>
                                    </Tooltip>:
                                    <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                                        <img style={{marginLeft: 5}} src={FileConstants.getLicensingIcon(props.roomDetails.licensing_options.public_option)}/>
                                    </Tooltip>: '' }
                                    </span>
                                </div>
                            </Col>
                        : "" }

                        {props.roomDetails.variant_of ?
                            <Col style={{display:"flex",alignItems:"center", marginBottom: 20}}>
                            <span className="category-artist" style={{marginRight:16}}>Model Type</span>
                            <Row>
                                <Col>
                                    <div className="note-bg-artist gray" style={{marginRight: 16}}>
                                        <span className="note-text gray">Variation</span>
                                    </div>
                                </Col>

                            </Row>
                            </Col> : ""}
                    </Row>

                    {/* Room status */}
                    <Row style={{marginTop: 15}}>
                        <Col style={{display:"flex",alignItems:"center"}}>
                            <span className="category-artist" style={{marginRight:16}}>Status:</span>
                            <div className ="note-bg-artist red" style={{display: "block"}}>
                            {
                                (() => {
                                    if (props.roomDetails.model_status == "1")
                                        return <span className="note-text red room-status">Model was sent back to customer on {props.roomDetails.last_modified} as further details are required.</span>
                                    if (props.roomDetails.model_status == "2" && !props.roomReducer.room.assigned_artist)
                                        return <span className="note-text red room-status">Model is Yet to be Assigned to an Artist.</span>
                                    if (props.roomDetails.model_status == "2" && props.roomReducer.room.assigned_artist)
                                        return <span className="note-text red room-status">Model has been Assigned to an Artist.</span>
                                    if (props.roomDetails.model_status == "3")
                                        return <span className="note-text red room-status">Model is Pending for Admin QA since {props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "4")
                                        return <span className="note-text red room-status">Model is Pending for Customer QA since {props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "-4" && (last_rejection_by && last_rejection_by == "admin"))
                                        return <span className="note-text red product-status">Model was Rejected by Admin on {last_rejection_date ? formateDate(last_rejection_date) : props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "-4" && (last_rejection_by && last_rejection_by == "customer"))
                                        return <span className="note-text red product-status">Model was Rejected by Customer on {last_rejection_date ? formateDate(last_rejection_date) : props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "5")
                                        return <span className="note-text red room-status">Model has been Approved by Customer since {props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "-5")
                                        return <span className="note-text red room-status">Model has been Rejected by Customer since {props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "6")
                                        return <span className="note-text red room-status">Model is being Processed since {props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "8") // TODO: change the status for 8 when the quick renders feature is added for spaces.
                                        return <span className="note-text red room-status">Model was validated on {props.roomDetails.last_modified} and now Artist needs to Finalize the Model.</span>
                                    if (props.roomDetails.model_status == "11")
                                        return <span className="note-text red room-status">Model's automation job failed on {props.roomDetails.last_modified}.</span>
                                    if (props.roomDetails.model_status == "12")
                                        return <span className="note-text red room-status">Model's automation job aborted on {props.roomDetails.last_modified}.</span>
                                })()
                            }
                            </div>
                            <Button type="link" disabled={props.itemHistory.length == 0} onClick={() => {props.setItemHistoryModal(true)}} className='note-text blue item-history' style={{display: 'inline', marginLeft: 20}}>
                                View Activity Timeline{props.itemHistory.length == 0 ? '(No Records Found)' : ''}
                            </Button>
                        </Col>
                    </Row>


                    {/* Reset Model Retries Option for Admin */}
                    {props.roomDetails.model_status == 8 && props.retriesReset == false && props.roomDetails.model_retries == max_retries ?
                        <Row style={{marginTop: 15}}>
                            <Col className='retries-div'>
                                <Row>
                                    <div className="note-bg-artist" style={{marginRight: 8, display: "flex"}}>
                                        <div style={{marginRight: 10}}>
                                            <span className="note-text" style={{color: '#555555', fontSize: 16}}>The artist has used the maximum number({max_retries}) of attempts to get this model right. Reset the number of retries if further fixes are required.</span>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Button disabled={(props.resetRequestInProgress===true)} className="modal-okay square" style={{marginRight: 3}} onClick={() => props.reset_model_retries()}>
                                                <span className="modal-okay-text">Reset Retries {props.resetRequestInProgress == true?<LoadingOutlined spin/>:""}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                    </Row> : ""}

                    {/* Model Rejection Details */}
                    <Row style={{marginBottom: 16, marginTop: 15}}>
                        {rejections_data ?
                            <Col sm={24} lg={24} xl={11} style={{marginRight: 30}} className="note-bg-artist red">

                                <div>
                                    <div className='note-text red' style={{display: 'inline'}}>
                                        Rejection Comments
                                    </div>
                                    <Button type="link" onClick={() => {showRejectionModal()}} className='note-text blue rejection-history-view' style={{display: 'inline', position: 'absolute', right: 20}}>
                                        View Details
                                    </Button>
                                </div>
                                {/* Admin Rejection count and customer rejection count */}
                                {admin_rejection_count + customer_rejection_count > 0 ?
                                <Row style={{alignItems: 'center', marginTop: 4}}>
                                    <span className="category-artist" style={{marginRight:16}}>Count:</span>
                                    {admin_rejection_count ?
                                        <Col style={{display: 'flex'}}>
                                                <span style={{color:"#276DD7", marginRight: 16}}>Admin: {admin_rejection_count}</span>
                                        </Col>
                                    : ""}

                                    {customer_rejection_count ?
                                        <Col style={{display:"flex"}}>
                                            <span style={{color:"#276DD7"}}>Customer: {customer_rejection_count}</span>
                                        </Col>
                                    : ""}
                                </Row> : ''}
                                {rejections_data}
                            </Col>
                        : ""}

                        {room_text_guidelines != "" ?
                            <Col sm={24} lg={24} xl={12} className="note-bg-artist gray">
                                <div>
                                    <div className='note-text gray' style={{display: 'inline'}}>
                                        Initial Comments and Guidelines
                                    </div>
                                </div>
                                <div style={{marginTop: 5}}>
                                    <ul style={{paddingLeft: 20}}>
                                        {room_text_guidelines}
                                    </ul>
                                </div>
                            </Col>: ""}
                    </Row>

                    {/* Tabs */}
                    <Row style={{paddingTop: 30}}>
                        <Col span={24}>
                        <Tabs defaultActiveKey="stats" className="product-tab" size="large">
                            <TabPane tab="Modeling Stats and Model Information" key="stats">
                                <Row>
                                    <Col sm={24} xl={12} lg={12} style={{paddingRight: 30}}>
                                        {/* Modeling Time */}
                                        <Col span={24} style={{paddingTop: 30}}>
                                            {props.totalModelingTime() != "0 Hours" ?
                                                <React.Fragment>
                                                <div>
                                                    <span className="product-txt-heading">Initial Modeling Time</span>
                                                    <span className="note-text blue" style={{paddingLeft: 10}}>{props.totalModelingTime()}</span>
                                                </div>

                                                <table className="stats-table" style={{marginTop: 13}}>
                                                    <tr>
                                                        {room_hours_time1 ?
                                                        <td colSpan={2}>
                                                                <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>High Poly Geometry</span>
                                                                <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(room_hours_time1)}</span>
                                                        </td>
                                                        : ""}

                                                        {room_hours_time3 ?
                                                        <td colSpan={2}>
                                                                <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>VRay Textures</span>
                                                                <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(room_hours_time3)}</span>
                                                        </td>
                                                        : ""}
                                                    </tr>
                                                </table>
                                                </React.Fragment>
                                                :
                                                <div>
                                                        <span className="product-txt-heading">Initial Modeling Time</span>
                                                        {information_not_available}
                                                </div>
                                            }
                                        </Col>

                                        {/* Fixes Time */}
                                        {props.fixesDetails != undefined && props.fixesDetails.length > 0 ?
                                            <Col span={24} style={{paddingTop: 30, paddingBottom: 10}}>
                                                <React.Fragment>
                                                    <div>
                                                        <span className="product-txt-heading">Total Fixing Time</span>
                                                        <span className="note-text blue" style={{paddingLeft: 10}}>{props.totalFixesTime()}</span>
                                                    </div>
                                                    <table className="stats-table" style={{marginTop: 13, display: 'block'}}>
                                                        <tr>
                                                        {
                                                            (() => {
                                                                var fixes_array = []
                                                                for(var i=0; i<props.fixesDetails.length; i++)
                                                                {
                                                            fixes_array.push(
                                                                        <td style={{display: 'inline-block'}}>
                                                                            <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Fix {props.fixesDetails.length - i}</span>
                                                                            <span style={{fontSize: 16, paddingTop: 7}}>{props.convertTimeToHoursString(props.fixesDetails[i].fix_time)}</span>
                                                                        </td>
                                                                )
                                                                }
                                                                return fixes_array
                                                            })()
                                                        }
                                                        </tr>
                                                    </table>
                                                </React.Fragment>

                                            </Col>:""}


                                        <Col style={{alignItems:"center", marginRight:'40px', paddingTop: 30}}>
                                                <span className="product-txt-heading">Tags</span>
                                                {props.roomDetails.tags != undefined && props.roomDetails.tags.length > 0  ?
                                                <div style={{paddingTop: 10, display: 'flex', flexWrap: 'wrap'}}>
                                                {room_tags}
                                                </div>

                                            :
                                            <div>
                                                {information_not_available}
                                            </div>
                                            }
                                        </Col>


                                        <Col style={{alignItems:"center", marginRight:'40px', paddingTop: 30}}>
                                            <span className="product-txt-heading">Reference URL(s)</span>
                                            {props.roomDetails.reference_urls != undefined && props.roomDetails.reference_urls.length > 0  ?
                                                <div style={{display:"flex", flexDirection: 'column', paddingTop: 10}}>
                                                    {room_references}
                                                </div>
                                                :
                                                information_not_available
                                            }
                                        </Col>

                                        <Col style={{alignItems:"center", marginRight:'40px', paddingTop: 30}}>
                                            <span className="product-txt-heading">Raw File(s)</span>
                                            {raw_files && raw_files.length > 0  ?
                                                <div style={{display:"flex", flexDirection: 'column', paddingTop: 10}}>
                                                    {raw_files}
                                                </div>
                                                :
                                                information_not_available
                                            }
                                        </Col>

                                    </Col>


                                    <Col sm={24} xl={12} lg={12} style={{paddingRight: 30}}>
                                         {/* File Size */}
                                         <Col span={24} style={{paddingTop: 30}}>
                                            {total_file_size > 0 ?
                                                <React.Fragment>
                                                <div>
                                                    <span className="product-txt-heading">Total Bundle Size</span>
                                                    <span className="note-text blue" style={{paddingLeft: 10}}>{props.convertByteSize(total_file_size)}</span>
                                                </div>
                                                <table className="stats-table" style={{marginTop: 13}}>
                                                <tr>
                                                    {high_poly_model_size > 0 ?
                                                    <td>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>High Poly (Mesh + Textures)</span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(high_poly_model_size)}</span>
                                                    </td>
                                                    : archive_size > 0 ?
                                                    <td>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Archive Size</span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(archive_size)}</span>
                                                    </td> : ""
                                                }
                                                    {glb_size > 0 ?
                                                    <td>
                                                        <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>GLB Size</span>
                                                        <span style={{fontSize: 16, paddingTop: 7}}>{props.convertByteSize(glb_size)}</span>
                                                    </td>
                                                    : ""}
                                                </tr>
                                                </table>
                                            </React.Fragment>
                                                :
                                                <div style={{paddingBottom: 13}}>
                                                    <span className="product-txt-heading">Total Bundle Size</span>
                                                    {information_not_available}
                                                </div>
                                                }
                                        </Col>

                                        {/* Vertex Count */}
                                        <Col span={24} style={{paddingTop: 30}}>
                                            {props.roomDetails.model_info && props.roomDetails.model_info.vertex_count && props.roomDetails.model_info.vertex_count != 0 ?
                                                <div>
                                                    <span className="product-txt-heading">Vertex Count</span>
                                                    <span className="note-text blue" style={{paddingLeft: 10}}>{parseInt(props.roomDetails.model_info.vertex_count).toLocaleString()}</span>
                                                </div> :
                                                <div>
                                                    <span className="product-txt-heading">Vertex Count</span>
                                                    {information_not_available}
                                                </div>
                                                }
                                        </Col>

                                        {
                                            countRoomDimensions > 0
                                            ?
                                            <Row style={{marginBottom:10}}>
                                            <Col span={countRoomDimensions == 1 ? 18 : 24}>
                                            <Card
                                            title={"Dimensions (inches)"}
                                            >
                                            <Row>
                                                {
                                                countRoomDimensions > 1?
                                                Object.keys(props.validation_report.High.room_dimensions).map((ele, index) => {
                                                    return(
                                                        <Col className='dimensions-col' span={12}>
                                                            <span  className='manrope f-14 grey-99 dimensions-span'>
                                                                {ele}
                                                            </span>
                                                            <span  className='manrope f-12 black-33 w-600 dimensions-span'  >
                                                                D {parseInt(props.validation_report.High.room_dimensions[ele].length)} " W {parseInt(props.validation_report.High.room_dimensions[ele].width)} " H {parseInt(props.validation_report.High.room_dimensions[ele].height)} "
                                                            </span>
                                                        </Col>
                                                    )
                                                })
                                                :
                                                ''
                                                }
                                                <Col className='dimensions-col' span={countRoomDimensions == 1 ? 18 : 12}>
                                                    <span className='manrope f-14 grey-99 dimensions-span'>
                                                        Total
                                                    </span>
                                                    <span className='manrope f-12 black-33 w-600 dimensions-span'>
                                                        D {parseInt(props.validation_report.High.dimensions.depth)} " W {parseInt(props.validation_report.High.dimensions.width)} " H {parseInt(props.validation_report.High.dimensions.height)} "
                                                    </span>
                                                </Col>
                                            </Row>
                                            </Card>
                                            </Col>
                                            </Row>
                                            :
                                            ''
                                        }

                                        {props.validation_generated  ?
                                        <Col span={24} style={{paddingTop: 30}}>
                                            <div className="product-txt-heading" style={{marginBottom: 14}}>Space Model Validation Summary</div>
                                            {props.success_val_length == 0 ? '' :
                                            <Collapse
                                            bordered={false}
                                            defaultActiveKey={props.invalid_val_length == 0  && props.auto_val_length == 0 ? ['1']: ['0']}
                                            expandIconPosition={'right'}
                                            expandIcon={({ isActive }) => <div className="draw-full-circle" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{props.success_val_length}</span></div>}
                                            className="collapse-validation">
                                            <Panel header={<span className="modal-text green">Successfully Validated</span>} key="1" className="site-collapse-custom-panel">
                                                <div className="collapse-validation-collapse">
                                                {props.successfully_validated_high.length == 0 ? '' :
                                                <span>
                                                    <div className='val-sub-heading width-height-label'>High Poly</div>
                                                    <List
                                                    bordered={false}
                                                    dataSource={props.successfully_validated_high}
                                                    className="list-validation"
                                                    renderItem={item => (
                                                    <List.Item className="list-item-validation">
                                                        <Typography.Text mark style={{marginRight: 10}}><Checkbox className="green-checkbox" checked={true}/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                    </List.Item>
                                                    )}/>
                                                </span>}

                                                </div>
                                            </Panel>
                                            </Collapse>}
                                            {props.auto_val_length == 0 ? '' :
                                            <Collapse
                                            bordered={false}
                                            defaultActiveKey={props.invalid_val_length == 0 ? ['1']: ['0']}
                                            expandIconPosition={'right'}
                                            expandIcon={({ isActive }) => <div className="draw-full-circle yellow" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{props.auto_val_length}</span></div>}
                                            className="collapse-validation">
                                                <Panel header={<span className="modal-text yellow">Automatically Fixed</span>} key="1" className="site-collapse-custom-panel">
                                                    <div className="collapse-validation-collapse">
                                                    {props.automatically_fixed_high.length == 0 ? '' :
                                                    <span>
                                                        <div className='val-sub-heading width-height-label'>High Poly</div>                                <List
                                                        bordered={false}
                                                        dataSource={props.automatically_fixed_high}
                                                        className="list-validation"
                                                        renderItem={item => (
                                                        <List.Item className="list-item-validation">
                                                            <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-circle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                        </List.Item>
                                                        )}/>
                                                    </span>}

                                                    </div>
                                                </Panel>
                                            </Collapse>}
                                            {props.invalid_val_length == 0 ? '' :
                                            <Collapse
                                            bordered={false}
                                            defaultActiveKey={['1']}
                                            expandIconPosition={'right'}
                                            expandIcon={({ isActive }) => <div className="draw-full-circle red" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{props.invalid_val_length}</span></div>}
                                            className="collapse-validation">
                                            <Panel header={<span className="modal-text red">What Went Wrong?</span>} key="1" className="site-collapse-custom-panel">
                                                <div className="collapse-validation-collapse">
                                                {props.invalid_data_high.length == 0 ? '' :
                                                    <span>
                                                        <div className='val-sub-heading width-height-label'>High Poly</div>                                <List
                                                        bordered={false}
                                                        dataSource={props.invalid_data_high}
                                                        className="list-validation"
                                                        renderItem={item => (
                                                        <List.Item className="list-item-validation">
                                                            <Typography.Text className="mark-style" mark style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-triangle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                                        </List.Item>
                                                        )}
                                                    />
                                                    </span>}
                                                </div>
                                            </Panel>
                                            </Collapse>}
                                        </Col> : ''}

                                    </Col>
                                    <Col sm={24}  xl={10} lg={10}>
                                    <div style={{paddingTop: 30}}>
                                    </div>

                                    </Col>

                                </Row>
                            </TabPane>

                            <TabPane tab="Floor Plan and Space Elements" key="floor_plan">
                                <Col span={24}>
                                    <div style={{paddingTop: 30}}>
                                        {floorplanPhotos.length > 0 ? floorplanPhotos :
                                            <div>
                                                {information_not_available}
                                            </div>
                                        }
                                    </div>
                                    <div style={{paddingTop: 30}}>
                                        {labelledFloorPlan && props.labelledFloorPlanExist ? labelledFloorPlan :
                                            <div>
                                                {information_not_available}
                                            </div>
                                        }
                                    </div>

                                    <div style={{paddingTop: 30}}>
                                    <div className="manrope f-20 grey-99" style={{textAlign:"left", marginBottom: 10}}>
                                        Space Elements
                                     </div>
                                        <Row>
                                            {console.log(props.roomDetails.elements)}
                                            {(props.roomDetails.elements && props.roomDetails.elements.length > 0) ? roomElements :
                                                <div>
                                                    {information_not_available}
                                                </div>
                                            }
                                        </Row>
                                    </div>

                                </Col>
                            </TabPane>

                            <TabPane tab="Reference Images and Videos/Raw Files" key="ref_imgs">
                                <Col span={24} style={{paddingTop: 30}}>
                                    <div>
                                        <span className="product-txt-heading">Reference Images</span>
                                        <div style={{marginTop: 7, display: 'flex'}}>
                                            {roomPhotos.length > 0 ?
                                            <Row style={{display:"flex"}}>
                                                {roomPhotos}
                                            </Row>
                                            :
                                                <div>
                                                    {information_not_available}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24} style={{paddingTop: 30}}>
                                    <div>
                                        <span className="product-txt-heading">Images for Outside View</span>
                                        <div style={{marginTop: 7, display: 'flex'}}>
                                            {outside_photos ? outside_photos :
                                                outside_photos_custom ? outside_photos_custom :
                                                <div>
                                                    {information_not_available}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>

                            </TabPane>

                        </Tabs>

                        </Col>

                    </Row>

                   <Row style={{marginTop: 100}}>

                   </Row>
                   </React.Fragment>
                   :""
                }
            </Col>

            </Row>)}
            {(props.roomReducer.room.model_status == "3") ? <ReviewProduct adminForm={true} adminRoom={true} roomDetailModal={false} roomDetail={false} approveTxt='Approve' rejectTxt="Reject" isAdminQA={true} onApprove={isFinalRender ? showApproveAdminRenderModal : props.onApprove} onReject={props.onReject} referenceFiles={props.roomReducer.room.reference_files}  assigned_artist={(props.roomReducer.room.assigned_artist !== undefined) ? props.roomReducer.room.assigned_artist : null} rejectionDetails={props.roomReducer.room.rejection_details} platform={props.roomReducer.room.platform} rejectionLoader={props.rejectionLoader} setRejectionLoader={props.setRejectionLoader} setApproveToCustomerLibrary={props.setApproveToCustomerLibrary} openAdminQAChecklist={props.openAdminQAChecklist}></ReviewProduct> : ''}
            {(props.roomReducer.room.model_status == "-5") ? <ReviewProduct adminForm={true} adminRoom={true} roomDetailModal={false} roomDetail={false} approveTxt='Deny Rejection' rejectTxt="Assign to artist" onApprove={props.onApprove} onReject={props.onReject} referenceFiles={props.roomReducer.room.reference_files} assigned_artist={(props.roomReducer.room.assigned_artist !== undefined) ? props.roomReducer.room.assigned_artist : null} rejectionDetails={props.roomReducer.room.rejection_details} platform={props.roomReducer.room.platform} rejectionLoader={props.rejectionLoader} setRejectionLoader={props.setRejectionLoader} setApproveToCustomerLibrary={props.setApproveToCustomerLibrary}></ReviewProduct> : ''}
            
            <Modal bodyStyle={{ paddingTop: "2px" }}
                title="Admin Scene Renders"
                visible={approveAdminRenderModal}
                width={500}
                centered={true}
                onCancel={() => cancelApproveAdminRenderModal()}
                footer={[
                    <Button key="back" className="modal-okay-green" onClick={handleApproveAdminRender}>
                        <div className="modal-okay-text">{false? <LoadingOutlined spin />:""}  Approve</div>
                    </Button>
                ]}>
                <Space direction="vertical" size={5}>
                <Text>Select Space Images which you want to share with customer:</Text>
                <Row gutter={[16, 16]}>
                    {testRenders?.map((item, index) => (
                        <>
                        {item.status == 'rendered' && item.generated_by == 'Admin' &&  (
                            <Col key={index} span={8}>
                            
                                <div
                                    className={`image-container ${!item.is_hidden ? 'selected' : ''}`}
                                    onClick={() => handleImageSelect(index)}
                                    >
                                        {!item.is_hidden && (
                                            <CheckCircleFilled className="check-icon" />
                                        )}
                                        
                                            <img
                                                src={ENVIRONMENT.getBaseURL(item.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props?.roomDetails?.dummy_scene_id + '/' + item.filename}
                                                alt={`Image ${index}`}
                                                style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                                            />
                                    </div>
                           
                            </Col> 
                        )}
                        </>
                    ))}
                </Row>
                </Space>
            </Modal>
            
            
            <Modal bodyStyle={{ paddingTop: "2px" }}
                title="Upload your Space 360s"
                visible={props.renderOverrideModal}
                width={500}
                centered={true}
                onCancel={() => props.cancelRenderOverrideModal()}
                footer={[
                    <Button key="back" className="modal-okay" onClick={props.handleSubmitRenderReplaceForm}>
                        <div className="modal-okay-text">{false? <LoadingOutlined spin />:""}  Replace</div>
                    </Button>
                ]}
            >
                <div>
                <Form className="input-form">
                <Row type="flex">
                    <Col span={24} style={{padding: 12}}>
                        <p className="text-danger">All of the current 360s will be replaced with the 360 renders you upload.</p>
                        <div className="d-flex" style={{justifyContent:"center"}}>
                            <div style={{textAlign:"center",minWidth:"100%"}}>
                                <Form.Item name={'perspective_images'} label="Space 360s" colon={false}>
                                {getFieldDecorator('perspective_images', {
                                    valuePropName: 'perspective_images',
                                    getValueFromEvent: e => e && e.fileList,
                                    rules: [{ required: true, message: 'Please upload perspective renders to replace' }]
                                })(
                                    <Upload
                                        accept=".jpg,.jpeg"
                                        {...Constants.getUploadProps(props.roomDetails.platform)}
                                        multiple="true"
                                        listType="text"
                                        fileList = {props.perspectiveRenders}
                                        onChange = {props.handlePerspectiveRenderChange}
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                    >
                                    <div className="d-flex">
                                        <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                        <div className="ant-upload-text">
                                        <div className="up-info">Drop your Files</div>
                                        <div>Support: JPG</div>
                                        </div>
                                    </div>
                                    </Upload>
                                )}
                                </Form.Item>
                            </div>
                        </div>
                        <p>If uploading more than one 360, kindly upload with proper names like 'Patio', 'Living' and drop them in the order you want on Space page.</p>
                    </Col>
                    </Row>
                    {(props.uploadError != '')?
                        <div className="ant-legacy-form-explain" style={{color: "#ff4d4f", textAlign: "center"}}>{props.uploadError}</div>
                    :""}
                </Form>
                </div>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Space Model Override"
                    visible={props.productModelOverrideMadal}
                    width={500}
                    centered={true}
                    onCancel={() => props.cancelProductModelOverrideMadal()}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={props.handleSubmitModelOverrideForm}>
                            <div className="modal-okay-text">{false? <LoadingOutlined spin />:""}  Regenerate Assets</div>
                        </Button>
                    ]}
                >
                    <div>
                    <Form className="input-form">
                    <Row type="flex">
                        <Col span={24} style={{padding: 12}}>
                            <p className="text-danger">All Space Assets (360 renders and glb ) will be regenerated with the space model you upload. You will be notified via email to review the assets again once processed.</p>
                            <div className="d-flex" style={{justifyContent:"center"}}>
                                <div style={{textAlign:"center",minWidth:"100%"}}>
                                    <Form.Item name={'override_model'} label="Space Model" colon={false}>
                                    {getFieldDecorator('override_model', {
                                        valuePropName: 'override_model',
                                        getValueFromEvent: e => e && e.fileList,
                                        rules: [{ required: true, message: 'Please upload perspective renders to replace' }]
                                    })(
                                        <Upload
                                            accept=".zip"
                                            {...Constants.getUploadProps(props.roomDetails.platform)}
                                            multiple="true"
                                            listType="text"
                                            fileList = {props.overideProductModel}
                                            beforeUpload={file => {
                                                if(file.name != props.params.id + '.zip'){
                                                    props.setUploadError("Invalid archive name, archive must be named " + props.params.id + '.zip')
                                                    props.setModelArchiveValid(false)
                                                    return false;
                                                }
                                                else {
                                                    props.setUploadError("")
                                                    props.setModelArchiveValid(true)
                                                }

                                            }}
                                            onChange = {props.handleOverrideModelChange}
                                            className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                        >
                                        <div className="d-flex">
                                            <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                            <div className="ant-upload-text">
                                            <div clasfsName="up-info">Drop your Files</div>
                                            <div>Support: ZIP</div>
                                            </div>
                                        </div>
                                        </Upload>
                                    )}
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>

                        </Row>
                    </Form>
                    </div>
                    {(props.uploadError != '')?
                        <div className="ant-legacy-form-explain" style={{color: "#ff4d4f", textAlign: "center"}}>{props.uploadError}</div>
                    :""}
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Upload your Custom Renders"
                    visible={props.renderOverrideModalConfirm}
                    width={500}
                    centered={true}
                    onCancel={() => props.cancelRenderOverrideModalConfirm()}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={(e) => props.handleSubmitRenderReplaceForm(e, true)}>
                            <div className="modal-okay-text">{(props.renderOverrideLoader == true)? <span><LoadingOutlined spin /> Replacing</span>:<span>Confirm</span>}</div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to replace orignal QA renders with your custom renders?</h4>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Model Override"
                    visible={props.productModelOverrideMadalConfirm}
                    width={500}
                    centered={true}
                    onCancel={() => props.cancelProductModelOverrideMadalConfirm()}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={(e) => props.handleSubmitModelOverrideForm(e, true)}>
                            <div className="modal-okay-text">{(props.modelOverrideLoader == true)? <span><LoadingOutlined spin /> Submittings Request</span>:<span>Confirm</span>}</div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to regenerate QA Assets with updated model?</h4>
                </Modal>
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Product Regenerate Assets Request"
                    visible={props.productRegenerateConfirm}
                    width={500}
                    centered={true}
                    onCancel={() => props.setProductRegenerateConfirm(false)}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={() => props.regenerateModelConfirm()}>
                            <div className="modal-okay-text">{(props.modelRegernateLoader == true)? <span><LoadingOutlined spin /> Submittings Request</span>:<span>Confirm</span>}
                            </div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to regenerate Space Assets?</h4>
                </Modal>

                {/* Modal for space360 4K confirmation */}
                <Modal bodyStyle={{ paddingTop: "2px" }}
                    title="Regenerate Space 360 at 4K"
                    visible={props.confirm3604K}
                    width={500}
                    centered={true}
                    onCancel={() => props.setConfirm3604K(false)}
                    footer={[
                        <Button key="back" className="modal-okay" onClick={() => props.render360_4K()}>
                            <div className="modal-okay-text">{(props.space360RegenerateLoader == true)? <span><LoadingOutlined spin /> Submitting Request</span>:<span>Confirm</span>}
                            </div>
                        </Button>
                    ]}
                >
                    <h4>Are you sure you want to regenerate Space 360 at 4K?</h4>
                </Modal>


                {/* Modal showing item's complete history */}
                <Modal visible={props.itemHistoryModal}
                maskClosable={true}
                closeIcon={
                    <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
                    style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}
                    onClick={() => props.setItemHistoryModal(false)} />
                }
                onCancel={() => props.setItemHistoryModal(false)}
                footer={false}
                width={1000}>
                    <Row>
                        <Col span={18} style={{fontSize: 24, color: '#141414'}}>
                                Activity Timeline
                        </Col>
                        <Col span={5}>
                        <Dropdown overlay={activity_sort_menu} trigger={['click']} style={{backgroundColor: '#FAFAFC', borderRadius: 2}} >
                            <Button id="item-modal-dropdown" style={{float: 'right', marginRight: 10}}>
                                <span >{props.activitySortAscending == true ? "Oldest Activity First" : "Most Recent Activity First"}<DownOutlined/></span>
                            </Button>
                        </Dropdown>
                        </Col>
                    </Row>

                    <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}}>
                        <div>
                            {item_history_data}
                        </div>

                    </div>

                </Modal>

                {/* Modal will apear on successful request of space360 4k */}
                <Modal
                    className="admin-override-space360-4k"
                    closable={false}
                    visible={props.render360_4k_success}
                    width={620}
                    centered={true}
                    footer={[
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={() => {window.history.back()}}>
                            <div className="modal-okay-text">Okay</div>
                        </Button>
                    ]}
                    >
                    <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                        <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                        <div>
                            <h2 className="success-modal-text">Space 360 Requested Successfully!</h2>
                            <p className="modal-text">You have successfully requested a 360 for this space at 4K resolution.</p>
                        </div>
                    </div>
                </Modal>

                {/* Modal for image showing  */}

                <Modal
                className="rejection-image"
                style={{width:'100vw'}}
                closeIcon={
                    <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
                    style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}

                    />
                }
                footer={false} visible={showImage} onCancel={() => {setImageUrl(''); setShowImage(false); }}>
                    <a href={imageUrl} target="_blank" >
                        <img src={imageUrl} style={{width:'100%', height:"100%"}}/>
                    </a>
                </Modal>


                <Modal
                closeIcon={
                    <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
                    style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}
                    />
                } visible={showDetailedRejection} onCancel={handleCancel} footer={false} width={1000}>
                    <div style={{fontSize: 24, color: '#141414'}}>
                        Rejection Comments
                    </div>
                    <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}}>
                        <div style={{display:'grid'}}>
                            {detailed_rejection_data}
                        </div>

                    </div>

                </Modal>
                <Modal
                    visible={props.embedLink}
                    className="modal-embed"
                    onCancel={props.handleEmbedCancel}>
                    <div style={{padding:"16px 6px 0 14px"}}>
                      <h2 className="share-modal-heading">Space Public URL(s)</h2>
                    <span>
                        <h4 className="modal-text">Click the copyable icon to copy the URL for a space.</h4>
                        {props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length == 0 ?
                        <Paragraph className="modal-radio-anchor bg" copyable={{ text: `https://app.all3d.ai/360_public_viewer/space/${props.params.id}/360Camera.jpg` }}>
                            <Link to={'/360_public_viewer/space/' + props.params.id + '/360Camera.jpg'} target="_blank">
                                Default Space
                            </Link>
                        </Paragraph>: props.roomReducer.room.three_sixties ?
                        props.roomReducer.room.three_sixties.map((url) => (
                            <Paragraph className="modal-radio-anchor bg"  copyable={{ text: `https://app.all3d.ai/360_public_viewer/space/${props.params.id}/${url}` }}>
                                <Link to={'/360_public_viewer/space/' + props.params.id + '/' + url} target="_blank">
                                {url}
                                </Link>
                            </Paragraph>
                        )) : ''}
                    </span>
                    </div>
                </Modal>

                {/* Modal for successful reset of retries */}
                <Modal
                    className="modal-share-success"
                    width={620}
                    visible={props.showRetriesModal}
                    closable={false}
                    footer={[
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={() => props.setShowRetriesModal(false)}>
                            <div className="modal-okay-text">Okay</div>
                        </Button>
                    ]}
                    centered={true}
                    >
                    <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                        <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                        <div>
                            <h2 className="success-modal-text">Retries reset successfully!</h2>
                            <p className="modal-text">The artist has been successfully given {max_retries} more tries to get this model right.</p>
                        </div>
                    </div>
                </Modal>

                {/* Modal for successful room status change to 3 */}
                <Modal
                    className="modal-share-success"
                    width={620}
                    visible={props.adminModelStatusChange}
                    closable={false}
                    footer={[
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={() => window.history.back()}>
                            <div className="modal-okay-text">Okay</div>
                        </Button>
                    ]}
                    centered={true}
                    >
                    <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                        <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                        <div>
                            <h2 className="success-modal-text">Space Moved to Admin QA</h2>
                            <p className="modal-text">The space has been successfully moved to Admin QA.</p>
                        </div>
                    </div>
                </Modal>

                <QAChecklistModal 
                    entityDetails={props.roomDetails}
                    setQAChecklistModal={props.setQAChecklistModal}
                    QAChecklistModal={props.QAChecklistModal}
                    onApprove={isFinalRender ? showApproveAdminRenderModal : props.onApprove}
                    entity_id = {props.params.id}
                    adminQAList = {props.adminQAList}
                    rejectionDetails = {props.rejectionDetails}
                    title={"Space Admin QA"}
               />
        </AdminMainLayout>
        );
}

export default Form.create({ name: 'render-override-form' })(RoomDetailsView);