import React from 'react';
import { Table, Divider, message, Input, Space, Button, Modal } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import ENVIRONMENT from '../../../environments'
import DottedLoader from '../DottedLoader/DottedLoader'
import TemplateRequestModal from '../CustomerComponents/TemplateRequestModal';

const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class ScenesTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    tableLayout: 'fixed',
    hasData: true,
    dataSource: null,
    customerColumn: 'show',
    companyColumn: 'hide',
    subscriptionColumn:'hide',
    artistAction: 'hide',
    jobDeskAction: 'hide',
    adminQAAction: 'hide',
    customerColumn: 'hide',
    adminViewAction: 'hide',
    removeFromStoreAction: 'hide',
    addBackToStoreAction: 'hide',
    addToStoreAction: 'hide',
    filter_categories: [],
    filter_customers: [],
    flter_room_names: [],
    filter_styles: [],
    loader: true,
    status: 2,
    add_store_item: false,
    add_store_item_loader: false,
    addItemToStoreAction: 'hide',
    selected_item: '',
    price: 0,
    getting_details_loader: false,
    template_details: null,
    button_loader: false,
    template_modal: false,
    space_categories: [],
    preselected_renders: [],
    reject_button_loader: false,
    confirm_removal_button: false,
    confirm_modal: false,
    platform: 'aws'
  };

  getColumns = () => [
    {
      title: 'Scene Name',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'ALL3D ID',
      dataIndex: 'id',
      key: 'id',
      ...this.getColumnSearchProps('id'),
      render: text => <span><a href={'/admin_templates/' + text} target="_blank">{text}</a></span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Style Category',
      dataIndex: 'style',
      key: 'style',
      filters: this.state.filter_style_value,
      ...this.getColumnSearchProps('style'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Room',
      dataIndex: 'room_name',
      key: 'room_name',
      filters: this.state.filter_room_names,
      ...this.getColumnSearchProps('room_name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'MS Provider Name',
      dataIndex: 'requested_by',
      key: 'requested_by',
      filters: this.state.filter_customers,
      onFilter: (value, record) => record.requested_by === value,
      defaultFilteredValue: this.getAppliedFilterValue('requested_by'),
      render: text => <span>{text}</span>,

    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      sorter: (a, b) => a.key - b.key,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      className: this.state.addToStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.getTemplateDetails(record.id)}>{record.removed_on ? 'Add Back To Store': 'Review Request'}</a>
        </span>
      ),
    },
    {
      title: 'Remove From Store',
      key: 'action',
      dataIndex: 'action',
      className: this.state.removeFromStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.getTemplateDetails(record.id, 'remove')}>Remove From Store</a>
        </span>
      ),
    }
  ];

  componentDidMount = () => {
    this.handleDataSource();
    this.setSpaceCategoriesData();
  }

  cancelTemplateModal = () => {
    this.setState({
      template_modal: false
    })
  }

  setSpaceCategoriesData = () => {
    let payload = {
      'request_type': 'room_model'
    }
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
    .then(res => {
      let categories = []
      if (res.data) {
        res.data.map((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
        })
       this.setState({
        space_categories: categories
       })
      }
    })
  }

  approveTemplateScene = (values) => {
    this.setState({
      button_loader: true
    });
    let payload = {
      name: values.name,
      price: values.price,
      room_name: values.room_name,
      style: values.style,
      action: 'add_to_store',
      status: '3',
      requested_by: this.state.template_details.requested_by,
      id: this.state.template_details.id
    }
    if (this.state.template_details.admin_removed_on) {
      payload.action = 'update'
    }

    let lifestyle_dict_arr = []
    this.state.template_details.final_renders.map((render) => {
      if (values.lifestyle_renders.includes(render['filename'])) {
        lifestyle_dict_arr.push(render);
      }
    })
  
    payload.lifestyle_renders = lifestyle_dict_arr;
    console.log(payload)
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
      .then(res => {
        console.log(res)
        this.setState({
          button_loader: false,
          template_modal: false
        });
        message.success('Scene approved and added to store!');
        window.location.reload();
      });
  }

  rejectTemplate = () => {
    this.setState({
      reject_button_loader: true
    });
    let payload = {
      action: 'delete',
      id: this.state.template_details.id
    }

    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
    .then(res => {
      console.log(res)
      this.setState({
        reject_button_loader: false,
        template_modal: false
      });
      message.success('Scene rejected!');
      window.location.reload();
    });
   
  
  }

  removeSceneFromStore = () => {
   
    this.setState({
      confirm_removal_button: true
    });
    let payload = {
      id: this.state.template_details.id,
      status: '2',
      action: 'remove'
    }
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
      .then(res => {
        console.log(res)
        this.setState({
          confirm_removal_button: false,
          confirm_modal: false
        });
        message.success('Scene removed from store successfully!');
        window.location.reload();
      });
  }

  cancelConfirmModal = () => {
    this.setState({
      confirm_modal: false,
      confirm_removal_button: false
    })
  }

  getTemplateDetails = (template_id, action = 'approve') => {
    let payload = {
      id: template_id,
      action: 'get'
    }
    if (action == 'remove') {
      this.setState({
        confirm_modal: true
      })
    } else if (action == 'approve') {
      this.setState({
        getting_details_loader: true,
        template_modal: true
      });
    }
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
      .then(res => {
        console.log(res)
        if (res.data && res.data[0]) {
          let selected_cameras = []
          res.data[0].lifestyle_renders.map((render) => {
            selected_cameras.push(render.filename);
          })

          this.setState({
            preselected_renders: selected_cameras,
            template_details: res.data[0],
            platform: res.data[0]?.platform,
            getting_details_loader: false,
          });
        }
      })
  }

  setFilterQueryParams = (filters) => {
    const url = new URL(window.location);

    for (const [key, value] of Object.entries(filters)) {
      if(value){
        url.searchParams.set(key, value)
      }
      else if(url.searchParams.has(key)){
          url.searchParams.delete(key);
        }
    }
    window.history.replaceState({}, '', url)
  }

  getAppliedFilterValue = (filter) => {
    // return already applied filter value or null
    const url = new URL(window.location);
    let value = null;
    value = url.searchParams.get(filter)
    if(value)
      value = value.split(',')
    
    return value
  }


  // Search in table functionality
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          defaultValue={this.getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    defaultFilteredValue: this.getAppliedFilterValue(dataIndex),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
    let filteredValue = this.getAppliedFilterValue(dataIndex);
    if(!selectedKeys)
      setSelectedKeys(filteredValue)
    return filteredValue
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleDataSource = () => {
    this.setState({
      loader: true
    })

    if (this.props.state == 'store') {
      this.handleTemplateSceneBatchData('3');
    }

    if (this.props.state == 'eligible_for_store') {
      this.handleTemplateSceneBatchData('2');
    }
  }


  handleFilteringOptions = (filter_categories_values, filter_customer_values, filter_room_name_values, filter_style_values) => {
    
    let filter_categories_objects = [];
    let filter_customer_objects = [];
    let filter_room_name_objects = [];
    let filter_style_objects = [];


    filter_categories_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_customer_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_room_name_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_style_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
  


    filter_categories_values.forEach( filter_categories_value => {
      filter_categories_objects.push({
        text: filter_categories_value,
        value: filter_categories_value,
      })
    });

    filter_customer_values.forEach( filter_customer_value => {
      filter_customer_objects.push({
        text: filter_customer_value,
        value: filter_customer_value,
      })
    });

    filter_room_name_values.forEach( filter_room_name_value => {
      filter_room_name_objects.push({
        text: filter_room_name_value,
        value: filter_room_name_value,
      })
    });
    filter_style_values.forEach( filter_style_value => {
      filter_style_objects.push({
        text: filter_style_value,
        value: filter_style_value,
      })
    });

    this.setState({
      filter_categories: filter_categories_objects,
      filter_customers: filter_customer_objects,
      filter_room_names: filter_room_name_objects,
      filter_styles: filter_style_objects,
    });

  }


  getTableDataFromReponse = (response_data) => {
    let data = [];
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_room_name_value = [];
    let filter_style_value = [];
    for (let i = 1; i <= (response_data).length; i++) {
      if (this.props.customer_username_filter == undefined || this.props.customer_username_filter == "" || this.props.customer_username_filter == response_data[i - 1]['requested_by'])
      {
        data.push({
          key: i,
          scene_id: response_data[i - 1]['scene_id'],
          name: response_data[i - 1]['name'],
          id: response_data[i - 1]['id'],
          style: response_data[i - 1]['style'],
          room_name: response_data[i - 1]['room_name'],
          requested_by: response_data[i - 1]['requested_by'],
          created_on: response_data[i - 1]['created_on'],
          removed_on: response_data[i - 1]['admin_removed_on']
        });
      }

      let category_value = response_data[i - 1]['style'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let customer_value = response_data[i - 1]['requested_by'];
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let room_name_value = response_data[i - 1]['room_name'];
      if(room_name_value != "" && !filter_room_name_value.includes(room_name_value)){
        filter_room_name_value.push(room_name_value);
      }

      let style_value = response_data[i - 1]['style'];
      if(style_value != "" && !filter_style_value.includes(style_value)){
        filter_style_value.push(style_value);
      }


     
    }
    

    if (this.props.setCount != undefined){
      this.props.setCount(data.length);
    }


    this.setState({
      dataSource: data,
      loader: false
    })

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_room_name_value, filter_style_value);
  }


  handleTemplateSceneBatchData = (status) => {
    let payload = {
      action: 'get_batch',
      status: status
    }
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
      .then(res => {
        console.log(res, 'templates');
        if (this.props.state == 'store' || this.props.state == 'eligible_for_store') {
          this.getTableDataFromReponse(res.data); 
        }

        if (status == '3') {
          this.setState({
              removeFromStoreAction: "",
              loader: false
          });
        } else if (status == '2') {
          this.setState({
            addToStoreAction: "",
            loader: false
          });
        }
      })
  }


  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };


  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {this.state.loader ? <DottedLoader/> :
          dataSource &&
          <Table {...this.state} onChange={
            (pagination, filters, sorter, extra) => {
              this.setFilterQueryParams(filters)
            } 
       } columns={this.getColumns()} dataSource={dataSource} />
        }
        {this.state.template_details != null &&
        <TemplateRequestModal
          platform={this.state.platform}
          template_details={this.state.template_details}
          preselected_renders={this.state.preselected_renders}
          getting_details_loader={this.state.getting_details_loader}
          submit_button_loader={this.state.button_loader}
          reject_button_loader={this.state.reject_button_loader}
          scene_id={this.state.template_details.scene_id}
          renders={this.state.template_details.final_renders}
          space_categories={this.state.space_categories}
          visible={this.state.template_modal}
          onCancel={this.cancelTemplateModal}
          onOk={this.approveTemplateScene}
          admin_page={true}
          rejectTemplate={this.rejectTemplate}
       />}
        <Modal
          className='template-modal-setting small'
          visible={this.state.confirm_modal}
          closable={true}
          onCancel={this.cancelConfirmModal}
          title={<span className='manrope f-14 black-33 w-600'>Remove Scene From Store</span>}
          footer={[
              <span className="justify-in-end">
                  <Button disabled={this.state.confirm_removal_button} key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={this.cancelConfirmModal}>
                      No
                  </Button>
                  <Button disabled={this.state.confirm_removal_button} style={{textAlign:"center"}} className="modal-okay square font-14" onClick={this.removeSceneFromStore}>
                      {this.state.confirm_removal_button ? <span> Removing <LoadingOutlined spin /></span>:<span> Yes </span>}
                  </Button>
              </span>
          ]}> 
               <div>
                  <h2 className='manrope f-14 black-55'>Are you sure you want to remove this scene from store?</h2>
                </div>       
            </Modal>
      </div>
    );
  }
}

export default ScenesTable;
