import React, { useState, useEffect, createRef } from 'react';
import { Modal, Card, Button, Image, Menu, Row, Col, Empty, message, Tooltip, Switch, Dropdown } from 'antd';
import { LoadingOutlined, DownOutlined, DeleteOutlined, FlagOutlined, HeartOutlined, HeartFilled, EditOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants'
import * as Utilities from '../../Utilities';
import axios from 'axios';
import { Gallery } from 'react-grid-gallery';
import _ from "lodash"
import ProjectActionContext from '../../ContextFiles/ProjectActionContext';
import FlagSiloModal from '../ProductComponents/FlagSiloModal';
import ImageViewerContext from '../../ContextFiles/ImageViewerContext';
import ImageViewerTabs from '../ImageViewerCarousel/ImageViewerTabs';
import ImageViewerCarousel from '../ImageViewerCarousel/ImageViewerCarousel';

export default function SiloList({ projectId, silos = [], populateSilosData, 
    loadingSilos, getResolutionType, downloadSiloImagesArchive, projectMetadata, setProjectMetadata,
    selectedResolutionFilter, setSelectedResolutionFilter, searchValue, handleFavorite
 }) {
    const [visibleImage, setVisibleImage] = useState([])
    const [expandImage, setExpandImage] = useState(false)
    const [deleteSiloModal, setDeleteSiloModal] = useState(false)
    const [deleteSiloLoader, setDeleteSiloLoader] = useState(false)
    const [deleteSiloProductId, setDeleteSiloProductId] = useState(null)
    const [deleteSiloCompanyId, setDeleteSiloCompanyId] = useState(null)
    const [deleteSiloCustomerUsername, setDeleteSiloCustomerUsername] = useState(null)
    const [deleteSiloCameraName, setDeleteSiloCameraName] = useState(null)
    const [editableRenderNameId, setEditableRenderNameId] = useState(null)
    const [renderNameBeingEdited, setRenderNameBeingEdited] = useState(null)
    const [renderNameButtonLoader, setRenderNameButtonLoader] = useState(false)
    const [siloImageModal, setSiloImageModal] = useState(false);
    const [imgAccessedFromCarousel, setImgAccessedFromCarousel] = useState(false);
    const [selectedImgIndex, setSelectedImgIndex] = useState('');
    const [singleImage, setSingleImage] = useState(true);
    const [imageFlagModal, setImageFlagModal] = useState(false);
    const [selectedSiloImage, setSelectedSiloImage] = useState(null);
    const [deleteSiloData, setDeleteSiloData] = useState(null);
    const [filteredSilos, setFilteredSilos] = useState([]);
    const [isImageVisible, setIsImageVisible] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false)
    
    const tabs = [
        {
            type: 'silo_images',
            title: `Silos`,
            component: <ImageViewerCarousel />
        }
    ];

    useEffect(() => {
        if (selectedResolutionFilter == 'all') {
            setFilteredSilos(silos)
        }
        else {
            setFilteredSilos(silos.filter(item => item.resolution == selectedResolutionFilter))
        }
    }, [selectedResolutionFilter, silos]);

    const onImageExpand = (name) => {
        let temp_arr = visibleImage;
        silos.map(x => {
            if (x.unique_filename == name) {
                temp_arr[x.unique_filename] = true;
            } else {
                temp_arr[x.unique_filename] = false;
            }
        });

        setVisibleImage(temp_arr)
        setExpandImage(true)
        setIsImageVisible(true)
    }
    const changeVisibility = (visible, prevVisible) => {
        let temp_arr = [];
        if (expandImage) {
            silos.map(x => {
                if (visibleImage[x.unique_filename] && !visible) {
                    temp_arr[x.unique_filename] = false;
                }
                else if (visibleImage[x.unique_filename] && visible) {
                    temp_arr[x.unique_filename] = true;
                } else {
                    temp_arr[x.unique_filename] = false;
                }
            });

            setVisibleImage(temp_arr)
            setExpandImage(false)
            setIsImageVisible(false)

        } else {
            silos.map(x => {
                temp_arr[x.unique_filename] = false;
            });
            setVisibleImage(temp_arr)
            setExpandImage(false)
            setIsImageVisible(false)
        }
    }


    const openDeleteSiloModal = (filename, product_id, company_id, username, silo_data) => {
        setDeleteSiloProductId(product_id)
        setDeleteSiloCompanyId(company_id)
        setDeleteSiloCameraName(filename)
        setDeleteSiloCustomerUsername(username)
        setDeleteSiloModal(true)
        setDeleteSiloData(silo_data)
    }

    const deleteSilo = () => {
        setDeleteSiloLoader(true)
        let payload = {
            action: "delete",
            product_id: deleteSiloProductId,
            camera_name: deleteSiloCameraName,
            customer_username: deleteSiloCustomerUsername,
        }
        if (deleteSiloCompanyId) {
            payload.company_id = deleteSiloCompanyId
        }

        axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
            .then((response) => {
                let metadata_payload = { "action": 'update_metadata', "project_id": projectId }
                axios.post(ENVIRONMENT.UPDATE_PROJECT, metadata_payload)
            })
            .catch((error) => {
                message.error('Error in deleting Silo Image');
            });

        let siloData = silos;

        //Delete silo locally
        for (let silo of siloData) {
            if (silo.camera_name === deleteSiloCameraName && silo.username === deleteSiloCustomerUsername
                && silo.company_id === deleteSiloCompanyId && silo.product_id === deleteSiloProductId) {
                silo.is_hidden = true;
            }
        }

        //updating metdata
        let resolution = getResolutionType(deleteSiloData.image_data);
        let metadata = projectMetadata;
        metadata['silo_count'][resolution] = metadata['silo_count'][resolution] - 1;
        setProjectMetadata(metadata);
        message.success('Silo Image Deleted.');
        populateSilosData(siloData);
        setDeleteSiloLoader(false);
        setDeleteSiloModal(false);
        setSiloImageModal(false);
    }

    const openSingleFlagImage = (image) => {
        setSingleImage(true);
        setSelectedSiloImage(image);
        setImageFlagModal(true);
    }

    const customImageThumbnail = ({ item, imageProps }) => {
        // You can access the original props passed to the image component via imageProps
        return (
            <div style={{ position: 'relative' }}>
                <img
                    {...imageProps} // Spread the original image props to preserve default behavior
                    src={item.thumbnail ? item.thumbnail : item.src}
                    style={{ ...imageProps.style, filter: item.image_status == 'pending' ? 'blur(3px)' : 'none' }} // Add custom styles
                />
            </div>
        );
    };

    const getImgDisplayName = (img) => {
        let display_name = img.camera_display_name ? img.camera_display_name : img.camera_name.split(".")[0];
        return display_name;
    }

    const downloadSingleImage = (obj) => {
        let fname = getImgDisplayName(obj) + '.' + obj.image_data.img_format;
        let download_link = obj.request_link;
        if (obj.image_data.img_format == 'tiff') {
            download_link.replace('.jpg', '.tiff')
        }
        Utilities.downloadFile(download_link, fname);
    }

    const filterImages = () => {
        let array = _.cloneDeep(filteredSilos);
        array.forEach(obj => {
            obj.width = obj.image_data.width;
            obj.height = obj.image_data.height;
            obj.display_name = getImgDisplayName(obj);
            obj.customOverlay = (<div className='custom-overlay'>
                {obj.image_status == 'pending' ?
                    <Tooltip title={<span className='manrope f-12 white'>Render in Progress</span>}>
                        <div className={`pointer-event-auto collaborate-icon white`}>
                            <div className='collaborate-img thumbnail-lifestyle display-flex'>
                                <img src={'/img/time-clock-img.svg'} />
                            </div>
                        </div>
                    </Tooltip> : ''}

                {obj.image_status == 'completed' &&
                    <div className="fav-and-more-icons">
                    <Dropdown
                        overlay={(
                            <Menu>
                                <Menu.Item key="3"
                                    onClick={() => {
                                        downloadSingleImage(obj);
                                    }}
                                >
                                    <span className="menu-item-text">Download</span>
                                </Menu.Item>
                                <Menu.Item key="4" onClick={() => openSingleFlagImage(obj)}>
                                    <Tooltip title={<span className='manrope f-12 white'>Something does not look good? Report it to the admin.</span>}>
                                        <span className="menu-item-text">Report</span>
                                    </Tooltip>
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => openDeleteSiloModal(obj.camera_name, obj.product_id, obj.company_id, obj.username, obj)}
                                    key="5" >
                                    <span className="menu-item-text">Delete</span>
                                </Menu.Item>
                            </Menu>
                        )}
                        trigger={['hover']} className='pointer-event-auto'>
                        <div className="img-more-info" onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                            <img src={'/img/more.svg'} />
                        </div>
                    </Dropdown>
                    <div className={`pointer-event-auto img-fav-icon white`}>
                        {obj.isFavourite ? (
                            <Tooltip
                            title={<span className="manrope f-12 white">Unfavorite</span>}
                            >
                            <HeartFilled
                                style={{ fontSize: "19px" }}
                                onClick={(e) => {
                                console.log("here :");
                                e.stopPropagation();
                                handleFavorite(obj, 'silo');
                                }}
                            />
                            </Tooltip>
                        ) : (
                            <Tooltip
                            title={<span className="manrope f-12 white">Favorite</span>}
                            >
                            <HeartOutlined
                                style={{ fontSize: "19px" }}
                                onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite(obj, 'silo');
                                }}
                            />
                            </Tooltip>
                        )}
                    </div>
                </div>    
                }

                <div className='hover-info'>
                    <div className="image-banner">
                        <Tooltip title={<span className='manrope f-12 white'>{getImgDisplayName(obj)}</span>}>
                            <p className="manrope f-12 w-700 lh-140 clamp-text w-100 image-banner-text justify-in-start">
                                {getImgDisplayName(obj)}
                            </p>
                        </Tooltip>
                        <p className="manrope f-12 image-banner-text">{obj.image_data.width}x{obj.image_data.height} <span className='manrope upper-case'>{obj.image_data.img_format}</span></p>
                    </div>
                </div>
            </div>);
            obj.src = obj.request_link;
        })
        let searchTerm = searchValue.toLowerCase();
        return array.filter((image) => {
            // Filter based on search term
            const nameMatches = image.display_name.toLowerCase().includes(searchTerm);

            return (nameMatches);
        });
    }

    const renderReportButton = (obj) => {
        return (
            <Tooltip title={<span className='manrope f-12 white'>Something does not look good? Report it to the admin.</span>}>
                <span>
                    <Button
                        className="red-icon-button-container mr-8"
                        onClick={() => openSingleFlagImage(obj)}>
                        <FlagOutlined />
                    </Button>
                </span>
            </Tooltip>
        );
    }

    const handleDelete = (obj) => {

        return (
            <Button
                onClick={() => openDeleteSiloModal(obj.camera_name, obj.product_id, obj.company_id, obj.username, obj)}
                className="red-icon-button-container mr-8">
                <DeleteOutlined />
            </Button>
        );
    };

    const handleDownload = (obj) => {
        return (
            <span>
                <Dropdown overlay={(<Menu style={{ width: "165px" }}>
                    <Menu.Item key={1}>
                        <a className='manrope f-12' target="_blank" onClick={() => downloadSiloImagesArchive()}> All Silo Images </a>
                    </Menu.Item>
                    <Menu.Item key={4} onClick={() => {
                        downloadSingleImage(obj);
                    }}>
                        <span className='manrope f-12'> This Image Only</span>
                    </Menu.Item>
                </Menu>)} trigger={['click']}>
                    <Button
                        className="modal-okay square font-16 h-40 "
                        data-tut="reactour__stil_images_button">
                        Download
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </span>)
    }

    const openSiloImageModal = (index) => {
        setSiloImageModal(true);
        setSelectedSiloImage(filterImages()[index]);
        setSelectedImgIndex(index);
        setImgAccessedFromCarousel(false);
    }

    const handleCancel = () => {
        setSiloImageModal(false);
        resetRenderNameField()
    }

    const setSelectedImgFromCarousel = (selected_image) => {
        setSelectedSiloImage(selected_image);
    }

    const getImageFromSrc = (render, thumbnailLink = false) => {
        let src = render.request_link; 
        if(thumbnailLink && render.thumbnail)
            src = render.thumbnail;

        return src
    }

    const renderInProgressTooltip = (render) => {
        return (render.image_status == "pending" ? <Tooltip
            title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: render.image_status == "pending" ? 'Render in Progress' : '' }}></span>}>
            <div
                className={`lifestyle-image-collaborate-icon white border justify-in-start`} style={{ width: 'fit-content' }}>
                <img
                    className='collaborate-img'
                    src={'/img/time-clock-img.svg'}
                    alt="In Progress Icon"
                />
                <span className={`manrope f-14 w-500`}>
                    {render.image_status == "pending" ? "Render In Progress" : ""}
                </span>
            </div>
        </Tooltip>: "");
    }

    const handleEnterForSiloRenderRename = (keyCode, id, render) => {
        if(keyCode == 13){
            // Enter is Pressed
            renameSiloRender(id, render)
        }
        else if(keyCode == 27){
            // ESC is pressed
            let silo_render_field = document.getElementById('render-name');
            silo_render_field.contentEditable = false;
            silo_render_field.innerText = renderNameBeingEdited;
            resetRenderNameField()
        }
    }

    const resetRenderNameField = () => {
        let silo_render_field = document.getElementById('render-name');
        silo_render_field.contentEditable = false;
        setEditableRenderNameId(null)
        setRenderNameButtonLoader(false)
        setRenderNameBeingEdited(null)
    }

    const renameSiloRender = (elem_id, render = null) =>{
        setRenderNameButtonLoader(true)
        let silo_render_field = document.getElementById('render-name');
        if(silo_render_field && silo_render_field.innerText.trim().length)
        {
            // if the render name string does not consist only on white spaces
            silo_render_field.contentEditable = false;
            let silo_render_name = silo_render_field.innerText;

            const renderIndex = silos.findIndex(item=>item.unique_filename==elem_id)
            const siloRendersCopy = [...silos];

            siloRendersCopy[renderIndex]["camera_display_name"] = silo_render_name
            if (selectedSiloImage) {
                setSelectedSiloImage(siloRendersCopy[renderIndex])
            }
            populateSilosData(siloRendersCopy);

            if(silo_render_name != renderNameBeingEdited && silo_render_name != ''){
                let payload = {
                    'product_id': `${selectedSiloImage.product_id}`, // elem_id is the unique_filename, which is product_id before the filename, separated by _
                    'camera_name': selectedSiloImage.camera_name,
                    'updated_render_name': silo_render_name,
                    'action': 'rename_silo'
                }
                if(selectedSiloImage.company_id){
                    payload['company_id'] = selectedSiloImage.company_id
                }
                else{
                    payload['customer_username'] = selectedSiloImage.captured_by
                }

                axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
                .then( (response) => {
                    if(response.status == 200){
                        message.success('Silo image renamed')
                        resetRenderNameField()
                    }
                });
            }

            if(silo_render_name == '' || silo_render_name == renderNameBeingEdited){
                // User tried to update empty name, reset the field. Same Behaviour as pressing ESC
                let silo_render_field = document.getElementById('render-name');
                silo_render_field.innerText = renderNameBeingEdited
                silo_render_field.contentEditable = false;
                resetRenderNameField()
            }
        }
        else if(silo_render_field && !silo_render_field.innerText.trim().length){
            // render name only consists on white spaces
            silo_render_field.contentEditable = false;
            silo_render_field.innerText = renderNameBeingEdited
            resetRenderNameField()
        } else {
            setRenderNameButtonLoader(false)
        }
    }

    const makeSiloRenderNameEditable = (id) => {
        let silo_render_field = document.getElementById('render-name');
        silo_render_field.contentEditable = true;
        setEditableRenderNameId(id)
        setRenderNameBeingEdited(silo_render_field.innerText)
    }
    const handleMouseEnter = () => {
        if (!editableRenderNameId) {
            setTooltipVisible(true)
        }
    };
    
    const handleMouseLeave = () => {
        setTooltipVisible(false)
    };

    const handleImageNameUpdate = (x) => {
        const isSmallScreen = window.innerWidth <= 1430;
        const styles = {
            textContainer: {
                marginBottom: 11,
                textTransform: 'uppercase',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                whiteSpace: isSmallScreen ? 'nowrap' : 'normal',
            },
            dateContainer: {
                marginBottom: 11,
                display: 'flex',
                justifyContent: 'flex-end',
                whiteSpace: isSmallScreen ? 'nowrap' : 'normal',
                alignItems: 'end',
                gap: '3px'
            },
        };

        let last_modified = new Date(Number(x.time_updated)).toDateString();

        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "90%"
            }}>
                <Tooltip visible={tooltipVisible} placement='topLeft' title={<span className='manrope f-12 white'>{getImgDisplayName(x)}</span>}>
                    <h4
                        className={`manrope f-20 scene-render-name${editableRenderNameId == x.unique_filename ? `-editable` : ``} lifestyle-name-settings`}
                        id='render-name'
                        onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                        onKeyDown={(e) => {e.stopPropagation(); handleEnterForSiloRenderRename(e.keyCode,x.unique_filename, x) }}>
                        {getImgDisplayName(x)}
                        {/* style={{ marginTop: 11, width: 'fit-content', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: 8 }}> */}
                    </h4>
                </Tooltip>
                {editableRenderNameId == x.unique_filename ?
                <Button disabled={renderNameButtonLoader} loading={renderNameButtonLoader} className="save-button font-12" 
                onClick={() => { renameSiloRender(x.unique_filename, x) }}
                style={{ marginLeft: 15, display: 'flex', justifyContent: 'flex-end' }}>
                    Save
                </Button>
                :
                <div onClick={() => { makeSiloRenderNameEditable(x.unique_filename) }} className="edit-icon-data-small edit-icon-blue" >
                    <EditOutlined />
                </div>}

                <Tooltip title={<span className="manrope f-12 white">{x.image_data.width}x{x.image_data.height}</span>}>
                    <div className="manrope f-14 black-33 ml-16" style={styles.textContainer}>
                        {` ${x.image_data.width} x ${x.image_data.height} `}
                        {x.image_data.img_format}
                    </div>
                </Tooltip>
                {last_modified !== 'Invalid Date' && last_modified !== '' && x.captured_by ?
                <Tooltip title={<span className="manrope f-12 white">{new Date(x.time_updated).toDateString()}</span>}>
                    <div className="manrope f-14 grey-8c ml-16"
                        style={styles.dateContainer}>
                        {`Created on ${last_modified} by `}
                        <span style={{ fontWeight: 'bold' }}>{x.original_captured_by ? x.original_captured_by : x.captured_by}</span>
                    </div>
                </Tooltip> : 
                last_modified !== 'Invalid Date' && last_modified !== '' ?
                <Tooltip title={<span className="manrope f-12 white">{new Date(x.time_updated).toDateString()}</span>}>
                    <div className="manrope f-14 grey-8c ml-16"
                        style={styles.dateContainer}>
                        Created on {last_modified}
                    </div>
                </Tooltip>
                : x.captured_by ? (
                    <h5 className="manrope w-500 f-12 grey-8c mb-0" style={{ marginLeft: '4px' }}>
                        by <span style={{ fontWeight: 'bold' }}>{`${x.original_captured_by ? x.original_captured_by : x.captured_by}`}</span>
                    </h5>
                ) : null}
                
            </div>);
    }

    return (<>
        {loadingSilos ?
            <div>
                <LoadingOutlined style={{ fontSize: 50 }} className="loading-center" />
            </div> : (filteredSilos?.length == 0 || filterImages()?.length == 0) ? 
                <Empty
                style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }}
                description={`No silos to show`}
                className="manrope f-14" /> :
                <div className='mb-60'>
                    <Gallery
                        thumbnailImageComponent={customImageThumbnail}
                        onClick={(index) => openSiloImageModal(index)}
                        rowHeight={300}
                        margin={8}
                        images={filterImages()}
                        enableImageSelection={false}
                    />
                </div>}
        <Modal
            destroyOnClose={true}
            className="modal-share-success"
            width={600}
            visible={deleteSiloModal}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-14" onClick={() => {
                        setDeleteSiloModal(false)
                        setDeleteSiloCameraName(null)
                        setDeleteSiloProductId(null)
                        setDeleteSiloCompanyId(null)
                    }}>
                        Cancel
                    </Button>
                    <Button disabled={deleteSiloLoader} style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => deleteSilo()}>
                        {deleteSiloLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span> Okay </span>}
                    </Button>
                </span>
            ]}
            centered={true}
        >
            <div style={{ margin: "0px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <h2 className="manrope f-16 black-00 w-600">You're about to delete a Silo Image</h2>
                    <p className="manrope f-14 black-55">This will remove your Silo image from your product. Are you sure you want to delete?</p>
                </div>
            </div>
        </Modal>
        {selectedSiloImage &&
            <ProjectActionContext.Provider
                value={{
                    context_type: "project",
                    product_id: selectedSiloImage.product_id,
                    companyId: selectedSiloImage.company_id,
                    imageFlagModal: imageFlagModal,
                    selectedImageForFlag: selectedSiloImage.request_link,
                    setImageFlagModal: setImageFlagModal,
                    cognito_username: selectedSiloImage.captured_by,
                    setSingleImage: setSingleImage,
                    singleImage: singleImage,
                    silos: silos,
                    selectedSiloImage: selectedSiloImage
                }}>
                <FlagSiloModal context_type={"project"} />
            </ProjectActionContext.Provider>}
        <ImageViewerContext.Provider
            value={{
                type: "silos",
                isVisible: siloImageModal,
                handleOk: handleCancel,
                handleCancel: handleCancel,
                defaultActiveTabKey: "silo_images",
                tabs: tabs,
                selectedImg: selectedSiloImage,
                setSelectedImg: setSelectedSiloImage,
                imgAccessedFromCarousel: imgAccessedFromCarousel,
                setImgAccessedFromCarousel: setImgAccessedFromCarousel,
                selectedImgIndex: selectedImgIndex,
                setSelectedImgIndex: setSelectedImgIndex,
                image_list: silos,
                renderInProgressTooltip: renderInProgressTooltip,
                getImageFromSrc: getImageFromSrc,
                handleImageNameUpdate: handleImageNameUpdate,
                resetRenderNameField: resetRenderNameField,
                expandImage: onImageExpand,
                changeVisibility: changeVisibility,
                image_visible: visibleImage,
                white_balance_renders_switch: {},
                renderReportButton: renderReportButton,
                handleDelete: handleDelete,
                handleDownload: handleDownload,
                setSelectedImgFromCarousel: setSelectedImgFromCarousel,
                render_generation: true,
                isImageVisible: isImageVisible,
                handleFavorite: handleFavorite
            }}>
            <ImageViewerTabs />
        </ImageViewerContext.Provider>
    </>)
}