import { config } from 'rxjs';
import * as THREE from 'three'
import Constants from "../Constants.js"
import {changeColorToSpaceComponent, changeMaterialToSpaceComponent, degrees_to_radians} from "../HelperFunctions.js"
export default class SpaceConfigurator {

    spaceManager = null;
    raycastManager = null;
    selectionName = '';
    selectionColorConfigurable = false;
    selectionType = '';
    selection = null;
    preserveTexture = false;
    focusedAsset = null;
    focusedAssetName = null;
    focusedAssetType = '';
    active = false;
    mouse = null;
    sceneCreator = null;
    selectedAssetsMaterials = {};
    focusedAssetsMaterials = {};
    enabled = false;
    spaceConfiguration = {};
    uniqueParentNodes = {};
    uniqueParentMaterials = {};
    configAppliedToAll = false;
    backupOriginalMaterials = {};
    targetRequest = null;

    /**
     * Struct that contains references to all the event callbacks for this module.
     */
    events = {
        scope : null,

        onMouseMove () {
            this.scope.#onMouseMove();
        },
        onMouseUp () {
            this.scope.#onMouseUp();
        }
    }

    /**
     * Space Configurator Component that applies selected custom configurations ( colors/ materials ) to space components
     * @param {SceneCreator} sceneCreator - The main scene creator instance.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {THREE.Vector2} mouse - Reference to vector2 containing mouse position in NDC (normalized device coordinates). 
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(sceneCreator, scene, mouse, managersDict, setSpaceConfiguratorState = null, 
            setSpaceConfigurationOfSelection = null, setSpaceTypeOfSelection = null, setSpaceSelectionColorConfigurable = null) {
        this.sceneCreator = sceneCreator;
        this.mouse = mouse;
        this.scene = scene;
        this.events.scope = this;
        this.setSpaceConfiguratorState = setSpaceConfiguratorState;
        this.setSpaceConfigurationOfSelection = setSpaceConfigurationOfSelection;
        this.setSpaceTypeOfSelection = setSpaceTypeOfSelection;
        this.setSpaceSelectionColorConfigurable = setSpaceSelectionColorConfigurable;
        this.setupRequiredManagers(managersDict);
        this.setupSpaceIntersectableAssetsList();
    }

    /**
     * Setup all helper managers required in this module
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    setupRequiredManagers(managersDict) {
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
    }

    /**
     * Setup the list of configurable space assets (walls,floor,ceilings)
     */
    setupSpaceIntersectableAssetsList() {
        this.spaceIntersectableTargets = [];
        // add floors
        this.spaceManager.floors.forEach((floor) => {
            floor.traverse( ( child ) => {
                if ( child.isMesh ) {
                    this.spaceIntersectableTargets.push(child);
                }
            } );
           
        })
        // add ceilings
        this.spaceManager.ceilings.forEach((ceiling) => {
            this.spaceIntersectableTargets.push(ceiling);
        })
        // add walls
        this.spaceManager.walls.forEach((wall)=> {
            this.spaceIntersectableTargets.push(wall);
        })
        // add misc
        let configurableNodes = []
        this.spaceManager.miscNodes.forEach((misc)=> {
            if (misc.userData.isConfigurable) {
                configurableNodes.push(misc);
            }
        })

        this.spaceIntersectableTargets = this.spaceIntersectableTargets.concat(configurableNodes);

        this.getParentNodes(this.spaceManager.floors, Constants.SpaceStructureType.FLOOR)
        this.getParentNodes(this.spaceManager.ceilings, Constants.SpaceStructureType.CEILING)
        this.getParentNodes(this.spaceManager.walls, Constants.SpaceStructureType.WALL)
        this.getParentNodes(configurableNodes, Constants.SpaceStructureType.DEFAULT)
    }

    getParentNodes(structureAssetsList, structureType) {
        this.uniqueParentNodes[structureType] = [];
        for(let structureAsset of structureAssetsList) {
            if (!this.isGroupHeadNode(structureAsset.parent.name) && !this.isMiscGroupHeadNode(structureAsset)) {
                this.uniqueParentNodes[structureType].push(structureAsset);
            }
            else if (!this.uniqueParentNodes[structureType].includes(structureAsset.parent)) {
                this.uniqueParentNodes[structureType].push(structureAsset.parent);
            }
        }
        console.log("unique nodes", this.uniqueParentNodes);
    }


    /**
     * Set Structure type of selection
     * @param {String} selectionName 
     */
    setSelectionType(selectionName) {
        if (selectionName.toLowerCase().includes("wall")) {
            return Constants.SpaceStructureType.WALL;
        }
        else if (selectionName.toLowerCase().includes("floor")) {
            return Constants.SpaceStructureType.FLOOR;
        }
        else if (selectionName.toLowerCase().includes("ceiling")) {
            return Constants.SpaceStructureType.CEILING;
        }
        return Constants.SpaceStructureType.DEFAULT; 
    }

    /**
     * Update the current selection
     * @param {Object} newSelection - The list of selections
     * @param {String} newSelectionName - The selection group head name
     * @param {Object} newSelectionMaterials - The list of selections' materials
     */
    updateSelection(newSelection, newSelectionName, newSelectionMaterials) {
        this.dehighlightSelection()
        this.selection = newSelection;
        this.selectionName = newSelectionName;
        this.selectionColorConfigurable = false;
        this.selectionType = this.setSelectionType(newSelectionName);
        this.selectedAssetsMaterials = newSelectionMaterials;
        for (let selectionNode of newSelection) {
            const baseMat = this.backupOriginalMaterials[selectionNode.name]; 
            if (baseMat && baseMat.name.includes("_CB")) {
                this.selectionColorConfigurable = true;
                break;
            }
        }
        this.highlightSelection();   
        this.setAllSelectionMaterials();
    }

    /**
     * Highlight the selected asset
     */
    highlightSelection() {
        if (this.selection) {
            for (let selection of this.selection) {
                changeColorToSpaceComponent(selection, Constants.defaultHighLightColor);
                selection.material.transparent = true;
                selection.material.opacity = 0.1;
                selection.material.depthWrite = false;
            }
        }
    }

    /**
     * Highlight the focused asset
     */
    highlightFocusedAsset() {
        if (this.focusedAsset) {
            this.focusedAssetType = this.setSelectionType(this.focusedAssetName);
            for (let focusedAsset of this.focusedAsset) {
                if (this.backupOriginalMaterials[focusedAsset.name] == null || this.backupOriginalMaterials[focusedAsset.name] == undefined) {
                    this.backupOriginalMaterials[focusedAsset.name] = focusedAsset.material.clone();
                }
                this.focusedAssetsMaterials[focusedAsset.name] = focusedAsset.material.clone();
                changeColorToSpaceComponent(focusedAsset, Constants.focusedHighlightColor);
                focusedAsset.material.transparent = true;
                focusedAsset.material.opacity = 0.1;
                focusedAsset.material.depthWrite = false;
            }
        }
    }

    resetSpaceConfiguratorFocus() {
        this.dehighlightFocusedAsset();
    }

    /**
     * Deactivate configurator
     */
    deactivateConfigurator() {
        this.dehighlightSelection();
        this.dehighlightFocusedAsset();
    }

    /**
     * Reset configuration for selected space component
     */
    dehighlightSelection(){
        if (this.selection) {
            for (let selection of this.selection) {
                this.restoreMaterialToSpaceComponent(selection, this.selectedAssetsMaterials[selection.name]);
            }
            if (this.configAppliedToAll) {
                this.restoreMaterialToAllSelection();
            }
        }
        this.selection = null;
        this.selectionName = '';
        this.selectionType = '';
        this.selectedAssetsMaterials = {}
    }

    setTargetRequest(target) {
        this.targetRequest = target;
    }

    getTargetRequest() {
        return this.targetRequest;
    }

    setPreserveTextureOption (preserveTexture) {
        this.preserveTexture = preserveTexture;
    }

    restoreMaterialToAllSelection () {
        let selectionNodes = this.uniqueParentNodes[this.selectionType];
        if (selectionNodes) {
            for(let selection of selectionNodes) {
                if (selection.name != this.selectionName) {
                    if (selection.isMesh) {
                        this.restoreMaterialToSpaceComponent(selection, this.uniqueParentMaterials[selection.name]);
                    }
                    else if (selection.children) {
                        for (let selectionChild of selection.children) {
                            if (selectionChild.isMesh) {
                                this.restoreMaterialToSpaceComponent(selectionChild, this.uniqueParentMaterials[selectionChild.name]);
                            }
                        }
                    }
                }
            }
            this.configAppliedToAll = false;
        }
    }

    /**
     * Reset all assets of selected type to original space configuration
     */
    resetMaterialToAllSelection () {
        let selectionNodes = this.uniqueParentNodes[this.selectionType];
        if (selectionNodes) {
            for(let selection of selectionNodes) {
                if (selection.name != this.selectionName) {
                    if (selection.isMesh) {
                        this.restoreMaterialToSpaceComponent(selection, this.backupOriginalMaterials[selection.name]);
                        delete this.spaceConfiguration[selection.name]
                    }
                    else if (selection.children) {
                        for (let selectionChild of selection.children) {
                            if (selectionChild.isMesh) {
                                this.restoreMaterialToSpaceComponent(selectionChild, this.backupOriginalMaterials[selectionChild.name]);
                            }
                        }
                    }
                }
            }
            this.configAppliedToAll = false;
        }
    }

    /**
     * Dehighlight the focused asset
     */
    dehighlightFocusedAsset() {
        if (this.focusedAsset) {
            for (let focusedAsset of this.focusedAsset) {
                this.restoreMaterialToSpaceComponent(focusedAsset, this.focusedAssetsMaterials[focusedAsset.name])
            }
        }
        this.focusedAsset = null;
        this.focusedAssetName = null;
        this.focusedAssetType = '';
    }

    /**
     * Restore material to space component
     * @param {THREE.Mesh} spaceObj - The space component to restore the material for
     * @param {THREE.MeshStandardMaterial} material - The material to set
     */
    restoreMaterialToSpaceComponent(spaceObj, material) {
        if (material) {
            spaceObj.material = material.clone();   
        }
    }

    /**
     * Apply selected color to the selected space component
     * @param {HEXCode} colorHexCode 
     */
    applyColorToSelection(colorHexCode) {
        if (this.selection) {
            for (let selection of this.selection) {
                if (this.isValidMaterial(selection, this.preserveTexture)) {
                    changeColorToSpaceComponent(selection, colorHexCode);
                }
                else {
                    this.restoreMaterialToSpaceComponent(selection, this.backupOriginalMaterials[selection.name]);
                }
            }   
        }
    }

    setAllSelectionMaterials () {
        this.uniqueParentMaterials = {}
        let selectionNodes = this.uniqueParentNodes[this.selectionType];
        if (selectionNodes) {
            for(let selection of selectionNodes) {
                if (selection.name != this.selectionName) {
                    if (selection.isMesh) {
                        if (this.backupOriginalMaterials[selection.name] == null || this.backupOriginalMaterials[selection.name] == undefined) {
                            this.backupOriginalMaterials[selection.name] = selection.material.clone();
                        }
                        this.uniqueParentMaterials[selection.name] = selection.material.clone();
                    }
                    else if (selection.children) {
                        for (let selectionChild of selection.children) {
                            if (selectionChild.isMesh) {
                                if (this.backupOriginalMaterials[selectionChild.name] == null || this.backupOriginalMaterials[selectionChild.name] == undefined) {
                                    this.backupOriginalMaterials[selectionChild.name] = selectionChild.material.clone();
                                }
                                this.uniqueParentMaterials[selectionChild.name] = selectionChild.material.clone();
                            }
                        }
                    }
                }
            }
        }
    }

    isValidMaterial(component, preserveTexture) {
        const material = this.backupOriginalMaterials[component.name];
        if (material == null) {
            material = component.material;
        }
        if (!preserveTexture || material.name.includes("_CB")) {
            return true;
        }
        return false;
    }

    applyColorToAllSelection(colorHexCode) {
        if (this.focusedAsset && this.focusedAssetType == this.selectionType) {
            this.dehighlightFocusedAsset();
        }
        this.configAppliedToAll = true;
        let selectionNodes = this.uniqueParentNodes[this.selectionType];
        if (selectionNodes) {
            for(let selection of selectionNodes) {
                if (selection.name != this.selectionName) {
                    if (selection.isMesh) {
                        changeColorToSpaceComponent(selection, colorHexCode);
                    }
                    else if (selection.children) {
                        for (let selectionChild of selection.children) {
                            if (selectionChild.isMesh) {
                                changeColorToSpaceComponent(selectionChild, colorHexCode);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Apply selected material to the selected space component
     * @param {Texture} texture - The texture to be applied to the current selection
     * @param {Number} horizontalRepeat - The horizontal repeat value to be applied
     * @param {Number} verticalRepeat - The vertical repeat value to be applied
     * @param {Number} roughness - The roughness value to be applied
     */
    applyMaterialToSelection(texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, onMaterialApplied = null) {
        if (this.selection) {
            for (let selection of this.selection) {
                console.log("Apply rotation", rotation, degrees_to_radians(rotation))
                changeMaterialToSpaceComponent(selection, texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, degrees_to_radians(rotation), onMaterialApplied);
            }
        }
    }

    applyMaterialToAllSelection(texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, onMaterialApplied = null) {
        if (this.focusedAsset && this.focusedAssetType == this.selectionType) {
            this.dehighlightFocusedAsset();
        }
        this.configAppliedToAll = true;
        let selectionNodes = this.uniqueParentNodes[this.selectionType];
        if (selectionNodes) {
            for(let selection of selectionNodes) {
                if (selection.name != this.selectionName) {
                    if (selection.isMesh) {
                        changeMaterialToSpaceComponent(selection, texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, 
                            verticalOffset, degrees_to_radians(rotation), onMaterialApplied);
                    }
                    else if (selection.children) {
                        for (let selectionChild of selection.children) {
                            if (selectionChild.isMesh) {
                                changeMaterialToSpaceComponent(selectionChild, texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, 
                                    verticalOffset, degrees_to_radians(rotation), onMaterialApplied);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Reset configuration to the original state of the space
     */
    resetConfigurationToSelection() {
        if (this.selection) {
            for (let selection of this.selection) { 
                this.restoreMaterialToSpaceComponent(selection, this.backupOriginalMaterials[selection.name]);
            }
            if (this.configAppliedToAll) {
                this.resetMaterialToAllSelection();
            }
            delete this.spaceConfiguration[this.selectionName]
            this.selection = null;
            this.selectionName = '';
            this.selectionType = '';
            this.selectedAssetsMaterials = {}
            this.setSpaceConfiguratorState(false);
        }
    }

    /**
     * Set the updated configuration for current selection
     * @param {Object} configInfo - The updated configuration information to set for current selection
     */
    updateConfigurationToSelection(configInfo) {
        let newConfigInfo = Object.assign({}, configInfo)
        this.selectedAssetsMaterials = {};
        newConfigInfo["name"] = this.selectionName;
        if (this.preserveTexture) {
            newConfigInfo["base_material_asset"] = this.selectionName;
        }
        for(let selection of this.selection) {
            this.selectedAssetsMaterials[selection.name] = selection.material.clone();
        }
        this.addConfigurationInfo(newConfigInfo)   
        if (this.configAppliedToAll) {
            this.updateConfigurationToAllSelection(configInfo);
        }
    }

    /**
     * Set the updated configuration for current selection
     * @param {Object} configInfo - The updated configuration information to set for current selection
     */
    updateConfigurationToAllSelection(configInfo) {
        let selectionNodes = this.uniqueParentNodes[this.selectionType];
        this.uniqueParentMaterials = {};
        if (selectionNodes) {
            for(let selection of selectionNodes) {
                if (selection.name != this.selectionName) {
                    let newConfigInfo = Object.assign({}, configInfo)
                    newConfigInfo["name"] = selection.name;
                    if (this.preserveTexture) {
                        newConfigInfo["base_material_asset"] = this.selectionName;
                    }
                    this.addConfigurationInfo(newConfigInfo); 
                }
            }
        }
    }

    /**
     * Restore configuration for current selection
     */
    discardConfigurationToSelection() {
        this.dehighlightSelection();
    }

    /**
     * Add a new configuration to space configuration info for saving and exporting
     * @param {Object} configInfo - The configuration details to log in space configuration json
     */
    addConfigurationInfo(configInfo) {
        this.spaceConfiguration[configInfo.name] = configInfo;
    }

    /**
     * Export the space configuration info
     */
    exportSpaceConfiguration() {
        let spaceConfiguration = [];
        for (const [, configValue] of Object.entries(this.spaceConfiguration)) {
            spaceConfiguration.push(configValue);
        }   
        return spaceConfiguration;
    }

    /**
     * Apply color to the space component
     * @param {THREE.Object} spaceObj - The space component to set the color for 
     * @param {HEXCode} colorHexCode - The color hex code 
     */
    applyColorToSpaceComponent(spaceObj, colorHexCode, preserveTexture) {
        if (spaceObj.isMesh) {
            this.backupOriginalMaterials[spaceObj.name] = spaceObj.material.clone();
            if (this.isValidMaterial(spaceObj, preserveTexture)) {
                changeColorToSpaceComponent(spaceObj, colorHexCode);
            }
        }
        else {
            for (let spaceObjChild of spaceObj.children)  {
                this.backupOriginalMaterials[spaceObjChild.name] = spaceObjChild.material.clone()
                if (this.isValidMaterial(spaceObjChild, preserveTexture)) {
                    changeColorToSpaceComponent(spaceObjChild, colorHexCode);   
                }
            }
        }
    }

    /**
     * Apply material to space component
     * @param {THREE.Mesh} spaceObj - The space component (wall/floor/ceiling) to apply material on
     * @param {THREE.Texture} texture - The texture to be applied
     * @param {Number} horizontalRepeat - The u tiling value for the texture
     * @param {Number} verticalRepeat - The v tiling value for the texture
     * @param {Number} roughness - The roughness value for the texture
     * @param {Function} onMaterialApplied - The callback function to call when the material is updated
     */
    applyMaterialToSpaceComponent(spaceObj, texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, onMaterialApplied) {
        if (spaceObj.isMesh) {
            this.backupOriginalMaterials[spaceObj.name] = spaceObj.material.clone()
            changeMaterialToSpaceComponent(spaceObj, texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, degrees_to_radians(rotation), onMaterialApplied);
        }
        else {
            for (let spaceObjChild of spaceObj.children)  { 
                this.backupOriginalMaterials[spaceObjChild.name] = spaceObjChild.material.clone()
                changeMaterialToSpaceComponent(spaceObjChild, texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, degrees_to_radians(rotation), onMaterialApplied);
            }
        }
    }

    /**
     * Retreive configuration for space component
     * @param {String} spaceComponentName - The space component to retreive the configuration for
     */
    retreiveConfigurationForSpaceComponent(spaceComponentName) {
        const configInfo = {};
        const savedConfigInfo = this.spaceConfiguration[spaceComponentName];
        for (const configIndex in savedConfigInfo) {
            configInfo[configIndex] = savedConfigInfo[configIndex]
        }
        return configInfo;
    }

    /**
     * Check if the selected component is a group head node or not
     * @param {String} componentName - The selected component
     */
    isGroupHeadNode(componentName) {
        if (componentName.toLowerCase().includes("wall") || componentName.toLowerCase().includes("floor")
            || componentName.toLowerCase().includes("ceiling")) {
                return true;
        }
        return false;
    }

    /**
     * Check if the selected component is a group head node or not
     * @param {THREE.Mesh} component - The selected component
     */
    isMiscGroupHeadNode(component) {
        if (component.userData && component.userData.isConfigurable
            && !component.parent.name.toLowerCase().includes("cb")) {
                return true;
        }
        return false;
    }
    
    /**
     * Mouse move event handler function to identify if a space component is focused or not
     */
    #onMouseMove = () => {
        let raycastPoint = new THREE.Vector2();
        raycastPoint.copy(this.mouse);
        let raycastIntersections = this.raycastManager.setFromCameraAndIntersect(raycastPoint, this.sceneCreator.activeCamera, 
            this.spaceIntersectableTargets, true);
        if (raycastIntersections && raycastIntersections.length > 0) {
                raycastIntersections = raycastIntersections.filter( ( item ) => {
                    return (!(item.object instanceof THREE.LineSegments)) ;
                } );
                let raycastedObject = raycastIntersections[0].object;
                let isParentRelevant = this.isGroupHeadNode(raycastIntersections[0].object.parent.name) || this.isMiscGroupHeadNode(raycastIntersections[0].object);
                if (isParentRelevant) {
                    raycastedObject = raycastIntersections[0].object.parent;
                }
                if (this.focusedAssetName != raycastedObject.name
                    && raycastedObject.name != this.selectionName) {
                    if (this.focusedAssetName != this.selectionName) {
                        this.dehighlightFocusedAsset();
                    }
                    this.focusedAssetName = raycastedObject.name;
                    if (isParentRelevant) {
                        this.focusedAsset = raycastedObject.children;
                    }
                    else {
                        this.focusedAsset = [raycastedObject];
                    }
                    this.highlightFocusedAsset();
                }
                else if (raycastedObject.name == this.selectionName) {
                    this.dehighlightFocusedAsset();
                }
        }
        else {
            this.dehighlightFocusedAsset();
        }
    }

    /**
     * Mouse up event handler function to select a focused space component
     */
    #onMouseUp = () => {
        if (this.focusedAsset != null && this.focusedAssetName != this.selectionName) {
            this.updateSelection(this.focusedAsset, this.focusedAssetName, this.focusedAssetsMaterials);
            this.setSpaceTypeOfSelection(this.selectionType);
            this.setSpaceSelectionColorConfigurable(this.selectionColorConfigurable);
            this.setSpaceConfigurationOfSelection(this.retreiveConfigurationForSpaceComponent(this.focusedAssetName))
            this.setSpaceConfiguratorState(false)
            this.setSpaceConfiguratorState(true)
            this.focusedAsset = null;
            this.focusedAssetName = '';
            this.focusedAssetType = '';
            this.focusedAssetsMaterials = {};
        }
    }
 }