import React from 'react';
import axios from 'axios';
import { ExclamationCircleOutlined,LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Card,Tooltip,message } from 'antd';
import ENVIRONMENT from '../../../../environments';
import ImageMagnify from '../../CustomerComponents/ImageMagnify/ImageMagnify';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';

const image_style = { width: '100%',height: "calc(100vh -  282px)",objectFit:"contain" };
const fullscreen_image_style = { width: "100vw",height: "100vh",objectFit:"contain" };

class RoomPhotosViewer extends React.Component {
    state = {
        room_id: 0,
        room_photos: [],
        card_image: '',
        box_check: 'card',
        is_fullscreen: false,
        image: [],
    }


    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                is_fullscreen: false
            });
        }
    }
    
    componentDidMount() {
        this.setState({ room_photos: this.props.roomDetails.room_photos }, () => {this.checkScroll()});
        document.addEventListener("keydown", this.escFunction, false);
        window.addEventListener("resize", this.checkScroll);
        document.addEventListener("wheel", () => {
            this.checkScroll();
        }, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps!= this.props) {
          this.checkScroll();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        window.removeEventListener("resize", this.checkScroll);
        document.removeEventListener("wheel", () => {
            this.checkScroll();
        }, false);
    }
    

    leftScroll = () => {
        var elem = document.getElementById('review-scrollable-ref-imgs');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft -= 273;
            var left = document.getElementById('left-scroll-btn-ref');
            var right = document.getElementById('right-scroll-btn-ref');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            
            if (right!=null) {
                right.style.visibility = 'visible';
                elem.style.left = "20px";
                // forceUpdate();
            }
        }
    }

    rightScroll = () => {
        var elem = document.getElementById('review-scrollable-ref-imgs');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft += 273;
            var left = document.getElementById('left-scroll-btn-ref');
            var right = document.getElementById('right-scroll-btn-ref');
            elem.style.left = "94px";
            if (right != null) {
                if (left != null) {
                    left.style.display = "inline-block";
                }
                
                var newScrollLeft = elem.scrollLeft;
                var divWidth = elem.offsetWidth;
                var scrollwidth = elem.scrollWidth;
                if(newScrollLeft === scrollwidth - divWidth){
                    right.style.visibility = 'hidden';
                }
            }
            
        }
    }

    checkScroll = () => {
        var elem = document.getElementById('review-scrollable-ref-imgs');
        var carousel_elem = document.getElementById('pdp-carousel-ref-images'); 
        if (elem != null) {
            elem.style.scrollBehavior = "unset";

            var y1 = elem.scrollTop;  
            elem.scrollTop += 1; 
            var y2 = elem.scrollTop ; 
            elem.scrollTop -= 1; 
            var y3 = elem.scrollTop; 
            elem.scrollTop = y1; 
        
            var x1 = elem.scrollLeft;  
            elem.scrollLeft += 1; 
            var x2 = elem.scrollLeft; 
            elem.scrollLeft -= 1; 
            var x3 = elem.scrollLeft; 
            elem.scrollLeft = x1; 
            var right = document.getElementById('right-scroll-btn-ref');
            var left = document.getElementById('left-scroll-btn-ref');
            let nextValue = elem.scrollLeft - 273;

            if (elem.style.left == "94px") {
                elem.style.left = "94px";
            }
            else {
                if ( elem.scrollLeft == 0) {
                    elem.style.left = "20px";
                }
            }
            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
                if (left != null) {
                    if (elem.style.left == "20px" &&  elem.scrollLeft == 0) {
                        left.style.display = "none"; 
                    }
                }
                if (elem.style.left == "20px"  &&  nextValue <= 0) {// for right scroll
                    if (window.innerWidth <= 1500 && this.props.compareView) {
                        // elem.style.width = "320px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && this.props.compareView) {
                        // elem.style.width = "300px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && this.props.compareView) {
                        // elem.style.width = "20%";
                        elem.style.width = "100%";
                    }
                    if (this.props.compareView) {
                        console.log("set width",carousel_elem);
                        carousel_elem.style.width = "80%";
                    }
                }
                else { // for left scroll
                    if (window.innerWidth <= 1500 && this.props.compareView) {
                        // elem.style.width = "320px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && this.props.compareView) {
                        // elem.style.width = "300px";
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && this.props.compareView) {
                        // elem.style.width = "20%";
                        elem.style.width = "100%";
                    }

                    if (this.props.compareView) {
                        console.log("set width",carousel_elem);
                        carousel_elem.style.width = "80%";
                    }
                }
            }
            if (!this.props.compareView && this.props.adminScrollCheck) {
                elem.style.width = "100%";
                elem.style.left = "20px";
            }

            if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // right.style.visibility = 'hidden';
                left.style.display = "none";
            }
            // ---- NEED FOR LATER USE ----
            // console.log("H",x1 !== x2 || x2 !== x3); 
            // console.log("V",y1 !== y2 || y2 !== y3);
        }
    }      

    render() {
        let room_photos_photos = []
        Object.keys(this.state.room_photos).forEach((key) => {
            let photos_array = this.state.room_photos[key];
            if(!Array.isArray(photos_array))
            {
                if(key in photos_array){
                    photos_array = this.state.room_photos[key][key];
                }
                else {
                    photos_array = []
                }
            }
            let room_photos_subset = (photos_array || []).map((ele, index) => {
                let file_url = ENVIRONMENT.getBaseURL(this.props.roomDetails.platform) + ENVIRONMENT.BASE_URI + ele.uid + '/' + ele.name;
                return <Col span={12}>
                    <Card>
                        <a href={file_url} target='_blank'>
                            <img style={image_style} src={file_url} alt="References" />
                        </a>
                    </Card>
                </Col>
            });
            if (key === 'room_photos_photos_all')
            {
                room_photos_photos.unshift(room_photos_subset);
            }
            else{
                room_photos_photos.push(room_photos_subset);
            }
        });

        const setFullScreen  = () => {
            if(!this.state.is_fullscreen){
                message.info("Press ESC to exit full screen");
            }
            this.setState({
                is_fullscreen: !this.state.is_fullscreen
            });
        }

        let card_img = []
        let room_photos_url = []
        let index = 0

        // Add url of rejection images by customers.
        if(this.props.compareView){
            if(this.props.rejectionDetails && !window.location.href.includes('artist_rooms')){
                this.props.rejectionDetails.map((elem, index) => {
                    if(elem.qa_role == ""){
                        elem.reference_files.map((ele, i) => {
                            let file_url = ENVIRONMENT.getBaseURL(this.props.roomDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name);
                            if(!room_photos_url.includes(file_url) && (file_url.includes('.jpg') || file_url.includes('.jpeg') || file_url.includes('.png')))
                                room_photos_url.push(file_url);
                        })
                    }
                })
            }
        }

        // Append room photos urls
        Object.keys(this.state.room_photos).forEach((key) => {
            let ele = this.state.room_photos[key];
            let file_url = ENVIRONMENT.getBaseURL(this.props.roomDetails.platform) + ENVIRONMENT.BASE_URI + ele.uid + '/' + ele.name;
            this.state.image[index] = file_url;
            index++;
            room_photos_url.push(file_url)
        });

        room_photos_url.map((ele, i) => {
            if (i == 0) {
                this.state.dummy_image = ele
            }
            card_img.push(
                <Col>
                <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{marginRight:"10px",width:"64px",height:"64px",padding:"10px"}}>
                    <img onClick={() => displayImage(ele)} style={{ cursor: "pointer", height: "40px", width:"40px",objectFit: "contain", display: "block"}} src={ele} />
                </Card>
            </Col>   
            )
        })

        const displayImage = (URL) => {
            this.setState({
                card_image: URL,
                dummy_image: '',
                box_check: 'card'
            });
        } 


        return (
            <Card className={this.state.is_fullscreen ? "product-full-screen-card" : "product-viewer-normal"} bodyStyle={{padding: this.state.is_fullscreen ? 0 : 12}}>
                {console.log('*-*-*-',this.props)}
                {console.log(this.state)}
                <div className="bg-model" style={{zIndex:"1",position:"absolute",top:"20px",left:"20px"}}>
                    <div className="bg-model-text">
                        Reference Image(s)
                    </div>
                </div>
                <Tooltip title={(!this.state.is_fullscreen ? "Full Screen" : "Exit Full Screen")} placement="left">
                    <img src={this.state.is_fullscreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}  alt="Fullscreen Icon" 
                    style={{position:"absolute", bottom:"20px",zIndex:"10",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                    className="product-full-screen-icon" onClick={() => setFullScreen() } />
                </Tooltip> 
                {card_img !== [] ? 
                <div>
                    <div style={{ display: 'flex', justifyContent: "space-around" }}>
                        <Col>
                            <Card bordered={false}>
                                {this.state.box_check === 'card' ? (this.props.adminCheck==true ? 
                                <div>                                        
                                    <ImageZoomAdmin custom_class={this.state.is_fullscreen ? fullscreen_image_style : image_style}  compare={true} notShowToolButtons={true} image={this.state.card_image || this.state.dummy_image}/>
                                </div> :
                                <div>                                        
                                    <ImageZoomAdmin custom_class={this.state.is_fullscreen ? fullscreen_image_style : image_style}  compare={true} notShowToolButtons={true} image={this.state.card_image || this.state.dummy_image}/>
                                </div>
                            ) 
                                : ""}
                            </Card>
                        </Col>
                    </div>
                    <div className='pdp-carousel-scroll' id='pdp-carousel-ref-images'>
                        <div className="icon-con-left" id="left-scroll-btn-ref" onClick={this.leftScroll} style={{display: 'block'}}>
                            <RightOutlined  className="left-button-scr" />
                        </div>
                        <div id="review-scrollable-ref-imgs" className="pdp-scrollable">
                            {card_img}
                        </div>
                        <div className="icon-con" id="right-scroll-btn-ref" onClick={this.rightScroll} style={{display: 'block'}}>
                            <RightOutlined  className="right-button-scr" />
                        </div>
                    </div>
                </div>
                : 
                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                    <Col>
                            <Card>
                                <ExclamationCircleOutlined style={image_style}/>
                            </Card>
                        </Col>
                </div>}
            </Card>
            );



    } // render close

}


export default RoomPhotosViewer;