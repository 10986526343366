import React, { useContext } from 'react';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';

const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

const CollaborateQACanvas = () => {
    const { initialLoader, access_level, selected_action, projectID, productData } = useContext(CollaborateQAContext);

    return (
        <div id="collaborate-container" className={`collaborate-container ${(["co-owner", "owner"].includes(access_level) || (projectID && access_level == "edit")) ? `qa` : `qa-editor`} ${initialLoader  || (!(IS_MS_PROVIDER && (productData.customer_username == MANAGED_CUSTOMER_USERNAME || productData.company_id == MANAGED_COMPANY_ID)) && access_level == "restricted") ? `blur-canvas` : ``}`}>
            <div id={`fabric-canvas`} className={`fabric-container ${selected_action}`}>
                <canvas id="canvas-editor-qa"></canvas>
            </div>
        </div>
    );
}


export default CollaborateQACanvas;
