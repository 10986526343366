import React from 'react';
import { Card, Col, Checkbox, Radio } from 'antd';
import FileConstants from '../../../../FileConstants';
import { useState, useEffect } from 'react';
import { mode } from 'crypto-js';

const ProductOutputSelection = (props) => {

    const [modelTypes, setModelTypes] = useState("high_res");

    const changeModelOutput = (value) => {
        // If high_res or high_pbr is now selected, the previous of these is removed, so that only one of these ( the latest ) value is selected
        let tempModelTypes = value;
        if( (value.includes("high_pbr") && value.includes("high_res")) ){
            let index = 0;
            if(modelTypes.includes("high_res")){
                index = tempModelTypes.indexOf("high_res");
            }
            else{
                index = tempModelTypes.indexOf("high_pbr");
            }
            tempModelTypes.splice(index,1)
        }
        if( (value.includes("high_pbr_segmented") && value.includes("high_res_segmented")) ){
            let index = 0;
            if(modelTypes.includes("high_res_segmented")){
                index = tempModelTypes.indexOf("high_res_segmented");
            }
            else{
                index = tempModelTypes.indexOf("high_pbr_segmented");
            }
            tempModelTypes.splice(index,1)
        }
        setModelTypes(tempModelTypes)
        props.changeModelOutput(tempModelTypes)
    }
    return(
        <Col span={24}>
            <h1 style={{marginBottom: 8, marginTop: 10}} className="manrope f-16 black-55"><b>What is the type of {(props.creation_type && props.creation_type == 'material') ? "Material" : "Model"} you need? *</b></h1>

            <Checkbox.Group value={props.modelTypes} className='justify-space-start wrap' onChange={changeModelOutput} style={{marginBottom: 10}}>
            {FileConstants.modelOutputTypes.map((output) => (
                <Card className='model-output-card'>
                   {!output.name.includes('Configurator') && !output.name.includes('Photography')?
                        <Checkbox value={output.type}  className='blue-checkbox'>
                            <span className='manrope f-14 blue w-600'>{output.name}</span>
                        </Checkbox>
                    : <span className='manrope f-14 blue w-600'>{output.name}</span>}
                    <div style={{marginTop: 8}} className='manrope f-14 grey-6b'>{output.use}</div>
                    <div>
                    {
                        output.materials ?
                        // <Radio.Group onChange={changeModelOutputRadio} defaultValue={output.type}>
                            output.materials.map( (mat) => (
                                <>
                                    <Checkbox className='blue-checkbox' value={mat.type}>
                                        <span className='manrope f-14 blue w-600'>{mat.name}</span>
                                    </Checkbox>
                                </>
                            ))
                        // </Radio.Group>
                        :
                        ''
                    }
                    </div>
                    <div className='note-bg-artist pd-16' style={{marginTop: 12}}>
                        <div className='manrope f-14 grey-6b italic'>Supported Use Cases:</div>
                        {output.supported_usecases.map((usecase) => (
                            <div style={{marginTop: 24}}>
                                {
                                usecase.materials ?
                                usecase.materials.map( (mat, index)=> (
                                    <>
                                    {index == 0 ?
                                        <>
                                           <span className='manrope f-14 grey-6b'>
                                                {usecase.name} {usecase.use && <span className="manrope f-12 grey-6b">({usecase.use})</span>}
                                            </span>
                                        </>
                                        :
                                        ''
                                    }
                                    <Checkbox className='blue-checkbox' value={mat.type}>
                                        <span className='manrope f-14 blue w-600'>{mat.name}</span>
                                    </Checkbox>
                                    </>
                                ))
                                :
                                output.name.includes('Configurator') ?
                                <Checkbox value={usecase.type}  className='blue-checkbox' style={{marginBottom: 14}}>
                                    <span className='manrope f-14 grey-6b'>
                                        {usecase.name} {usecase.use && <span className="manrope f-12 grey-6b">({usecase.use})</span>}
                                    </span>
                                </Checkbox>
                                :

                                 <p className='manrope f-14 grey-6b'>
                                    {usecase.name}
                                </p>}
                                <img className='image-radius' src={usecase.icon}/>
                                {usecase.link ?
                                <div className='background link pd-8' style={{marginTop: 8}}>
                                    <a target='_blank' href={usecase.link} className='manrope f-14 blue hover-change'>View Example</a>
                                </div>
                                : ''}
                            </div>
                        ))}
                    </div>
                </Card>
            ))}
            </Checkbox.Group>
        </Col>);
}

export default ProductOutputSelection;
