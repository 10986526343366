import React from 'react';
import { Table, Modal, Select, Button, Form, Input, Collapse, Space } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import * as Utilities from '../../Utilities'
const Option = { Select };

const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class ComplaintsTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandable,
    title: undefined,
    showHeader,
    footer: false,
    rowSelection: {},
    tableLayout: 'fixed',
    scroll: undefined,
    hasData: true,
    dataSource: null,
    reviewAction: 'hide',
    super_artists: [],
    complaint_assignment_modal: false,
    complaint_assignment_loader: false,
    complaint_assignment_success: false,
    selected_complaint_id: null,
    complaint_unassignment_modal: false,
    complaint_unassignment_loader: false,
    complaint_unassigned_need_refresh: false,
    searchText: '',
    searchedColumn: '',
    filter_type:[],
    filter_assignee:[],
  };

  getColumns = () => [
    {
      title: 'Complaint ID',
      dataIndex: 'complaint',
      key: 'complaint',
      ...this.getColumnSearchProps('complaint','Complaint ID'),
      sorter: (a, b) => a.complaint - b.complaint,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Entity ID',
      dataIndex: 'id',
      key: 'id',
      ...this.getColumnSearchProps('id','Entity ID'),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name','Customer Name'),
     // onFilter: (value, record) => record.name === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <span>
          {record.status == "sent_back_to_artist" ? 
            <span >Rejected</span>
            : record.status == "rejected" ? <span >flag rejected</span> : <span >{record.status}</span>  
        }
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: this.state.filter_types,
      onFilter: (value, record) => record.type === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Assignee',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      hidden: this.props.state == 'pending_unassigned' ? true : false,
      filters: this.state.filter_assignee,
      onFilter: (value, record) => record.assigned_to === value,
      //...this.getColumnSearchProps('assigned_to'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.reviewAction,
      render: (text, record) => (
        <span>
          <a href={'/complaints/' + record.complaint}>Review</a>
          {this.props.state == "pending_unassigned" || this.props.state == "pending_assigned" || this.props.state == "rejected" ? 
          <React.Fragment>
            <span style={{marginRight: 10, marginLeft: 10}}>|</span>
            {record.assigned_to == '' ? 
            <a onClick={()=>{this.setState({selected_complaint_id: record.complaint}); this.complaintAssignmentModalVisibility(true)}}>Assign</a>
            : 
            <a onClick={()=>{this.setState({selected_complaint_id: record.complaint, complaint_unassignment_confirmation: true})}}>Unassign</a>
            }
          </React.Fragment> : ""

        }
        </span>
      ),
    },
  ].filter(item => !item.hidden);

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    if (this.props.state === 'pending_unassigned') {
      this.handleAssignedData('pending', false, false);
      this.fetchSuperArtist();
    }
    if (this.props.state === 'pending_assigned') {
      this.handleAssignedData('pending', true, false);
      this.fetchSuperArtist();
    } else if (this.props.state === 'in_progress') {
      this.handleAssignedData('in_progress',false);
    } else if (this.props.state === 'rejected') {
      this.handleAssignedData('sent_back_to_artist', false);
      this.fetchSuperArtist();
    } else if (this.props.state === 'admin_qa') {
      this.handleAssignedData('admin_qa', false);
    } else if (this.props.state === 'resolved') {
      this.fetchResolvedFlaggingRejectComplaints({ status: 'resolved' });
    }
  }
  setFilterQueryParams = (filters) => {
    const url = new URL(window.location);

    for (const [key, value] of Object.entries(filters)) {
      if(value){
        url.searchParams.set(key, value)
      }
      else if(url.searchParams.has(key)){
          url.searchParams.delete(key);
        }
    }
    window.history.replaceState({}, '', url)
  }

  getAppliedFilterValue = (filter) => {
    // return already applied filter value or null
    const url = new URL(window.location);
    let value = null;
    value = url.searchParams.get(filter)
    if(value)
      value = value.split(',')
    return value
  }

  getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
    let filteredValue = this.getAppliedFilterValue(dataIndex);
    if(!selectedKeys)
      setSelectedKeys(filteredValue)
    return filteredValue
  }

  // Search in table functionality
  getColumnSearchProps = (dataIndex,placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          defaultValue={this.getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    defaultFilteredValue: this.getAppliedFilterValue(dataIndex),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  complaintAssignmentModalVisibility = (visible) => {
    if(visible===false && this.state.complaint_unassigned_need_refresh){
      window.location.reload();
    }
    this.setState({
      complaint_assignment_modal: visible
    })
  }

  fetchSuperArtist = () => {
    var data = []
    axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        for(let i = 0; i<res.data.length; i++){
          if(res.data[i].is_superartist == true){
            data.push(res.data[i]);
          }
        }
        this.setState({
          super_artists: data
        })
      });
  }

  handleComplaintAssignment = values => {

    this.setState({
      complaint_assignment_loader: true
    })
    
    let payload = {
      'complaint_id': this.state.selected_complaint_id,
      'assigned_to': values.artist_username, 
      'assigned_by': localStorage.getItem('username')
    }
    axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
      .then(res => {
        this.setState({
          complaint_assignment_loader: false,
          complaint_assignment_modal: false,
          complaint_assignment_success: true
          
        })
      })
  }

  handleComplaintUnassignment = () => {
    this.setState({
      complaint_unassignment_loader: true,
      complaint_unassigned_need_refresh: true
    })
    let payload = {
      'complaint_id': this.state.selected_complaint_id,
      'assigned_to': '', 
      'assigned_by': localStorage.getItem('username')
    }
    axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
      .then(res => {
        this.setState({
          complaint_unassignment_modal: true,
          complaint_unassignment_loader: false,
          complaint_unassignment_confirmation: false
        })
      })
  }

  handleAssignedData = (current_status, assigned=false, all_assigned_unassigned=true) => {
    let filter_type_values = [];
    let filter_assignee_values = [];

    let payload = {

    }
    if(this.props.state){
      if(this.props.state.includes('pending')){
        payload['status'] = 'pending'
      }
      else if(this.props.state.includes('rejected')){
        payload['status'] = 'sent_back_to_artist'
      }
      else{
        payload['status'] =  this.props.state
      }
    }

    if(ENVIRONMENT.isProduction())
      payload['compress_response'] = true;

    axios.post(ENVIRONMENT.GET_COMPLAINT_BATCH, payload)
      .then(res => {

        let jsonData = null;
        if(res.data.isBase64Encoded){
          jsonData = Utilities.decompressData(res);
        } 
        else{
          jsonData = res.data;
        }
        var data = [];
        let type_value=null;
        let assignee_value=null;

        for (let i = 1; i <= (jsonData).length; i++) {
          if ((jsonData[i - 1]['status'] == current_status && !assigned && jsonData[i - 1]['assigned_to'] == '' && !all_assigned_unassigned) 
              || (jsonData[i - 1]['status'] == current_status && assigned && jsonData[i - 1]['assigned_to'] != '' && !all_assigned_unassigned)
              || (jsonData[i - 1]['status'] == current_status && all_assigned_unassigned)) {
            data.push({
              key: i,
              id: jsonData[i - 1]['entity_id'],
              name: jsonData[i - 1]['username'],
              complaint: jsonData[i - 1]['id'],
              type:  jsonData[i - 1]['complaint_against'],
              status: jsonData[i - 1]['status'],
              assigned_to: jsonData[i - 1]['assigned_to']
            });
          }
          type_value=jsonData[i - 1]['complaint_against'];
          assignee_value=jsonData[i - 1]['assigned_to'];
          if (type_value && !filter_type_values.includes(type_value)) {
            filter_type_values.push(type_value);
          }
          if (assignee_value && !filter_assignee_values.includes(assignee_value)) {
            filter_assignee_values.push(assignee_value);
          }
          this.handleFilteringOptions(filter_type_values,filter_assignee_values)
        }

        this.setState({
          reviewAction: "show",
          dataSource: data,
        });

      })
  }
  handleFilteringOptions = (filter_type_values,filter_assignee_values) => {

    let filter_type_objects = [];
    filter_type_values.forEach(filter_type_value => {
      filter_type_objects.push({
        text: filter_type_value,
        value: filter_type_value,
      })
    });
    this.setState({
      filter_types: filter_type_objects,
    });

    let filter_assignee_objects = [];
    filter_assignee_values.forEach(filter_assignee_value => {
      filter_assignee_objects.push({
        text: filter_assignee_value,
        value: filter_assignee_value,
      })
    });
    this.setState({
      filter_assignee: filter_assignee_objects,
    });
  }
  fetchResolvedFlaggingRejectComplaints = (payload = {}) => {
    if(ENVIRONMENT.isProduction())
      payload['compress_response'] = true;
    let filter_type_values = [];
    let filter_assignee_values = [];

    axios.post(ENVIRONMENT.GET_COMPLAINT_BATCH, payload)
      .then(res => {
        let jsonData = null;
        if(res.data.isBase64Encoded){
          jsonData = Utilities.decompressData(res);
        } 
        else{
          jsonData = res.data;
        }
        let data = [];
        let type_value=null;
        let assignee_value=null;
        for (let i = 1; i <= (jsonData).length; i++) {
          if (jsonData[i - 1]['status'] == 'resolved' ||  jsonData[i - 1]['status'] == 'rejected') {
            data.push({
              key: i,
              id: jsonData[i - 1]['entity_id'],
              name: jsonData[i - 1]['username'],
              complaint: jsonData[i - 1]['id'],
              type:  jsonData[i - 1]['complaint_against'],
              status: jsonData[i - 1]['status'],
              assigned_to: jsonData[i - 1]['assigned_to']
            });
            type_value=jsonData[i - 1]['complaint_against'];
            assignee_value=jsonData[i - 1]['assigned_to'];
            if (type_value && !filter_type_values.includes(type_value)) {
              filter_type_values.push(type_value);
            }
            if (assignee_value && !filter_assignee_values.includes(assignee_value)) {
              filter_assignee_values.push(assignee_value);
            }
            this.handleFilteringOptions(filter_type_values,filter_assignee_values)

          }
        }
        this.setState({
          reviewAction: "show",
          dataSource: data,
        });
      })
  }


  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource ?
          <Table {...this.state} 
          columns={this.getColumns()} 
          dataSource={dataSource} 
          onChange={
            (pagination, filters, sorter, extra) => {
              console.log('filters = ', filters)
              this.setFilterQueryParams(filters)
            } 
        }/> :
          <DottedLoader />
        }

        {/* Complaint Assignment Modal */}
        <Modal  bodyStyle={{padding: 0}}
          visible={this.state.complaint_assignment_modal}
          onCancel={()=>{this.complaintAssignmentModalVisibility(false)}}
          className="aspect-ratio-modal small"
          footer={[

            <div className="justify-in-end">
              <Button  className="modal-okay-gray square font-14" onClick={()=>{this.complaintAssignmentModalVisibility(false)}}>
                Cancel
              </Button>
              <Button disabled={this.state.complaint_assignment_loader} className="modal-okay square font-14" onClick={() => document.getElementById("assignment-submit-button").click()}>
                Assign&nbsp;{this.state.complaint_assignment_loader ? <LoadingOutlined/> : ""}
              </Button>
            </div>
            
        ]}
          >
          <div style={{padding:"32px 32px 24px 32px"}}>
            
            <div className="manrope f-24 black-55" >
            Please select assignee
            </div>
            <Form className="input-form" onFinish={this.handleComplaintAssignment} style={{marginTop: 24}}>
            <div className="manrope f-16 black-55" style={{marginBottom: 8}}>Select an assignee</div>

              <Form.Item  className="manrope f-14 black-55" name="artist_username" colon={false} rules={[{ required: true, message: 'Please select an Artist' }]}>

                <Select
                  notFoundContent="No Super Artist Found."
                  showSearch 
                  className="manrope f-14 black-55"
                  placeholder="Artist">
                  {this.state.super_artists.map((ele, index) => {
                    return <Option key={index} className="manrope f-12 select-view" value={ele.artist_profile}>{ele.artist_profile}</Option>
                  })}
                </Select>
              </Form.Item>
              <Button id="assignment-submit-button" type="primary" htmlType="submit" style={{"display": "none"}}>
                Submit
              </Button>
            </Form>
          </div>
        </Modal>


        {/* Success Modal for complaint assignment */}
        <Modal
            className="modal-share-success"
            width={620}
            visible={this.state.complaint_assignment_success}
            closable={false}
            footer={[
              <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={()=>{window.location.reload()}}>
                  <div className="modal-okay square font-14">Okay</div>
              </Button>
            ]}
            centered={true}
            > 
            <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                <div>
                    <h2 className="success-modal-text">Assigned successfully!</h2>
                    <p className="modal-text">The complaint has been successfully assigned to the artist.</p>
                </div>
            </div>           
        </Modal>


        {/* Unassign Confirmation */}
        <Modal
            className="modal-share-success"
            width={620}
            visible={this.state.complaint_unassignment_confirmation}
            closable={false}
            footer={[
              <React.Fragment>
                <Button key="back" style={{textAlign:"center"}} disabled={this.state.complaint_unassignment_loader} className="modal-okay" onClick={()=> this.handleComplaintUnassignment()}>
                    <div className="modal-okay square font-14">Yes&nbsp;{this.state.complaint_unassignment_loader ? <LoadingOutlined/> : ""}</div>
                </Button>
                <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={()=>{this.setState({
                  complaint_unassignment_confirmation: false
                })}}>
                    <div className="modal-okay square font-14">No</div>
                </Button>

              </React.Fragment>
            ]}
            centered={true}
            > 
            <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                <div>
                    <h2 className="success-modal-text">Are you Sure?</h2>
                    <p className="modal-text">Are you sure you want to unassign the complaint? The Artist might have already started working on it</p>
                </div>
            </div>           
        </Modal>

        {/* Success Modal for complaint unassignment */}
        <Modal
            className="modal-share-success"
            width={620}
            visible={this.state.complaint_unassignment_modal}
            closable={false}
            footer={[
              <React.Fragment>
                <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={()=>{window.location.reload()}}>
                    <div className="modal-okay square font-14">Go To Dashboard</div>
                </Button>

                <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={()=>{this.setState({
                  complaint_unassignment_modal: false,
                  complaint_assignment_modal: true
                })}}>
                  <div className="modal-okay square font-14">Reassign</div>
                </Button>
              </React.Fragment>
            ]}
            centered={true}
            > 
            <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
                <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
                <div>
                    <h2 className="success-modal-text">Unassigned successfully!</h2>
                    <p className="modal-text">The complaint has been unassgined successfully.You can either go to the dashboard or reassign to another artist.</p>
                </div>
            </div>           
        </Modal>
      </div>
    );
  }
}

export default ComplaintsTable;
