import React from "react";
import { Layout } from "antd";
import "./LogInHeader.scss";

const { Header, Content } = Layout;

function LogInHeader() {
    return (
        <Header className="sign-up-header justify-in-center">
            <img src={require("../../assets/images/all3d-new-logo-red.svg")}/>
        </Header>
    );
}


export default LogInHeader;
