import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import CustomerMainLayout from '../CustomerMainLayout'
import { Input, Col, Row, Form, Tabs, Radio } from 'antd';
import DottedLoader from "../../DottedLoader";
import '@ant-design/compatible/assets/index.css';
import '../../../../styles/helper.scss'
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import _ from "lodash"; // Import the entire lodash library
import CollaborationCard from './CollaborationCard';
import CollaborationModals from '../CollaborationModals/CollaborationModals';
import FileConstants from '../../../../FileConstants';
const { TabPane } = Tabs;


let CUSTOMER_USERNAME = localStorage.getItem("username");
let COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem("managed_customer_username");
const IS_MS_PROVIDER = FileConstants.isMSProvider;

const CollabKeys = {
    resolved : "resolved",
    unresolved : "unresolved",
}


const CustomerCollaborations = () => {

    const url = new URL(window.location);
    const projectId = new URLSearchParams(url.search).get("project_id");
    const sceneId = new URLSearchParams(url.search).get("scene_id");
    const isProjectList = origin === "projects";
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(true);
    const [tabActiveKey, setTabActiveKey] = useState("unresolved");
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [resolveConfirmModal, setResolveConfirmModal] = useState(false);
    const [unresolveConfirmModal, setUnresolveConfirmModal] = useState(false);
    const [selectedCollab, setSelectedCollab] = useState(null);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [resolvedCollaborations, setResolvedCollaborations] = useState([]);
    const [unResolvedCollaborations, setUnresolvedCollaborations] = useState([]);
    const [productCollaborations, setProductCollaborations] = useState([]);
    const [dataPopulated, setDataPopulated] = useState(false);
    const [mspData, setMspData] = useState([]);
    const [filteredMspData, setFilteredMspData] = useState([]);
    const [productCollabLoader, setProductCollabLoader] = useState(true);
    const [unresolvedCollabLoader, setUnresolvedCollabLoader] = useState(true);
    const [sharedCollabTabType, setSharedCollabTabType] = useState('scene');
    const [tabCount, setTabCount] = useState({ resolved: -1, unresolved: -1 });

    useEffect(() => {
        const fetchData = async () => {
            if (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME) {
                await getMspCollaborations();
                setTabActiveKey('msp')
            }
            await getResolvedCollaborations();
            await getUnresolvedCollaborations();
            if (!sceneId) {
                await getProductCollaborations();
            }
            setLoader(false);
        }
        fetchData();
    }, []);

    const updateTabCount = (count, tabName) => {
        setTabCount(prevTabCount => ({
            ...prevTabCount,
            [tabName]: count
        }));
    };

    useEffect(() => {
        applySearchFromQueryString();
      }, [resolvedCollaborations, unResolvedCollaborations]);

    const applySearchFromQueryString = () => {
        const url = new URL(window.location.href);
        const searchValue = url.searchParams.get('search');
        if (searchValue !== null) {
            let cleanedSearchValue = decodeURIComponent(searchValue);
            if (cleanedSearchValue) {
                setSearchValue(cleanedSearchValue);
            }
        }
      };

    const getUnresolvedCollaborations = async () => {
        const customerUsername = CUSTOMER_USERNAME;
        let payload = {
            "required_fields": ["id", "scene_id", "camera_name",
                "created_by", "created_on", "last_modified", "name", "platform","designed_for", "customer_username", "thumbnail", "render_path", "product_platform", "is_resolved"],
            "filter_string": `(username__exact='${customerUsername}')&&(access_level__exact='owner'||access_level__exact='edit'||access_level__exact='co-owner')&&(is_hidden__not=true&&is_resolved__not=true${projectId ? `&&project_id__exact='${projectId}'` : ''}${sceneId ? `&&scene_id__exact='${sceneId}'` : ''})`,
            "order_by": "last_modified desc"
        }
        await axios.post(ENVIRONMENT.GET_BATCH_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('Unresolved Collaborationsss Response', res.data)
                let collaborations = getCollaborationsDataWithKeywords(res.data);
                collaborations = insertCollabType(collaborations, "scene");
                setUnresolvedCollaborations(collaborations);
                setUnresolvedCollabLoader(false);
            })
    }

    const getProductCollaborations = async () => {
        const customerUsername = (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME) ? MANAGED_CUSTOMER_USERNAME : CUSTOMER_USERNAME;
        setLoader(true);
        let payload = {
            "required_fields": ["product_id", "last_modified", "customer_username", "platform", "name", "thumbnail", "is_resolved"],
            "filter_string": `(customer_username__exact='${customerUsername}')&&(access_level__exact='owner'||access_level__exact='edit'||access_level__exact='co-owner')&&(is_hidden__not=true&&is_resolved__not=true)`,
            "order_by": "last_modified desc"
        }
        if (COMPANY_ID) {
            payload['filter_string'] = payload["filter_string"] + `&&(company_id__exact='${COMPANY_ID}')`;
        }
        await axios.post(ENVIRONMENT.COLLABORATE_QA_IMAGERY, payload)
            .then(res => {
                console.log('Product Collaborations Response', res)
                let collaborations = getCollaborationsDataWithKeywords(res.data);
                console.log("Pre", collaborations)
                collaborations = insertCollabType(collaborations, "product");
                console.log(collaborations)
                setProductCollaborations(collaborations);
                setProductCollabLoader(false);
            })
    }

    const getMspCollaborations = async () => {
        let payload = {
            "required_fields": ["id", "scene_id", "camera_name",
                "created_by", "created_on", "last_modified", "name", "platform", "designed_for", "customer_username", "thumbnail", "product_platform", "is_resolved"],
            "filter_string": `(username__exact='${MANAGED_CUSTOMER_USERNAME}')&&(access_level__exact='owner'||access_level__exact='edit'||access_level__exact='co-owner')&&(is_hidden__not=true${projectId ? `&&project_id__exact='${projectId}'` : ''}${sceneId ? `&&scene_id__exact='${sceneId}'` : ''})`,
            "order_by": "last_modified desc"
        }
        await axios.post(ENVIRONMENT.GET_BATCH_COLLABORATE_ENTITY, payload)
            .then(res => {
                let collaborations = getCollaborationsDataWithKeywords(res.data);
                setMspData(collaborations);
                setFilteredMspData(collaborations);
            })
    }

    useEffect(() => {
        if (productCollaborations.length > 0 && !dataPopulated && !unresolvedCollabLoader && !productCollabLoader) {
            setDataPopulated(true);
            let unresolved_collaborations = unResolvedCollaborations;
            let product_collaborations = productCollaborations;
            let new_collaboration = unresolved_collaborations.concat(product_collaborations);
            let sorted_data = new_collaboration.sort((a, b) => b.last_modified - a.last_modified);
            // Sort the array based on the last_modified property
            sorted_data.sort((a, b) => {
                // Parse the last_modified values as dates for comparison
                const dateA = a.last_modified ? new Date(a.last_modified) : null;
                const dateB = b.last_modified ? new Date(b.last_modified) : null;
                
                // Compare dates
                if (dateA && dateB) {
                    return dateB - dateA;
                } else if (dateA) {
                    return -1; // Place items with a date first
                } else if (dateB) {
                    return 1; // Place items with a date first
                } else {
                    return 0; // No dates available, keep the order unchanged
                }
            });              
            setUnresolvedCollaborations(sorted_data);
        }
    }, [unResolvedCollaborations, productCollaborations, productCollabLoader, unresolvedCollabLoader]);


    const getResolvedCollaborations = async () => {
        const customerUsername = CUSTOMER_USERNAME;
        let payload = {
            "required_fields": ["id", "scene_id", "camera_name",
                "created_by", "created_on", "last_modified", "name", "platform", "designed_for", "customer_username", "thumbnail", "render_path", "product_platform", "is_resolved"],
            "filter_string": `(username__exact='${customerUsername}')&&(access_level__exact='owner'||access_level__exact='edit'||access_level__exact='co-owner')&&(is_hidden__not=true&&is_resolved__is=true${projectId ? `&&project_id__exact='${projectId}'` : ''}${sceneId ? `&&scene_id__exact='${sceneId}'` : ''})`,
            "order_by": "last_modified desc"
        }
        await axios.post(ENVIRONMENT.GET_BATCH_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('Resolved Collaborationssss Response', res.data)
                let collaborations = getCollaborationsDataWithKeywords(res.data);
                collaborations = insertCollabType(collaborations, "scene");
                setResolvedCollaborations(collaborations);
            })
    }


    const getCollaborationCount = () => {
        return unResolvedCollaborations.length + resolvedCollaborations.length;
    }


    const changeSearchValue = (value) => {
        setSearchValue(value);
        const url = new URL(window.location.href);
        if (value) {
          url.searchParams.set('search', encodeURIComponent(value));
        }
        else {
          url.searchParams.delete('search');
        }
        window.history.replaceState({}, "", url);
    }

    const getCollaborationsDataWithKeywords = (collaborations) => {
        let result = collaborations.map(obj => {
            let keywords = []
            for (let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
                if (!keywords.includes(keyword)) {
                    if (keyword.length > 1) {
                        keywords.push(keyword.toLowerCase())
                    }
                }
            }

            return { ...obj, 'keywords': keywords };
        });
        return result
    }

    const insertCollabType = (collaborations, type) => {
        let result = collaborations.map(obj => {
            return { ...obj, 'type': type };
        });
        return result
    }

    const openDeleteCollaboration = (element) => {
        setSelectedCollab(element);
        setDeleteConfirmModal(true);
    }

    const openMarkCollaborationAsResolved = (element) => {
        setResolveConfirmModal(true);
        setSelectedCollab(element);
    }

    const reopenCollab = (element) => {
        setUnresolveConfirmModal(true);
        setSelectedCollab(element);
    }

    const changeCollabTab = (key, event_type) => {
        setTabActiveKey(key);
        let url = new URL(window.location);
        url.searchParams.set("tab", key);
        window.history.replaceState({}, "", url);
    }

    useEffect(() => {
        setActiveTabFromQueryParams();
    },[])

    const setActiveTabFromQueryParams = () => {
        const url = new URL(window.location.href);
        let tab = url.searchParams.get("tab");
        if(Object.values(CollabKeys).includes(tab)){
            setTabActiveKey(tab)
        }
    }

    const onChangeSharedTabType = (e) => {
        setSharedCollabTabType(e.target.value);
    }

    const getUnresolvedCollab = () => {
        let collaborations = unResolvedCollaborations;
        if (sharedCollabTabType == 'product') {
            collaborations = collaborations.filter((ele) => ele.type == 'product');
        } else if (sharedCollabTabType == 'scene') {
            collaborations = collaborations.filter((ele) => ele.type == 'scene' && ele.scene_id);
        }
        else if(sharedCollabTabType == 'silo') {
            // collaboration with type scene and not having scene id means it is silo collaboration
            collaborations = collaborations.filter((ele) => ele.type == 'scene' && !ele.scene_id)
        }
        return collaborations;
    }

    const getCollabTabLength = (type) => {
        if (tabCount[type] !== -1) {
            return tabCount[type]
        } else {
            if (type === 'resolved') {
                return resolvedCollaborations.length;
            } else {
                return getUnresolvedCollab().length;
            }
        }
    }

    return (
        <div className="my-products">
            <CustomerMainLayout selected='20'>
                <Row className="justify-space-between mt-20 mb-20">
                    {loader ? ('') : (
                        <>
                            <Col lg={10} xl={10}>
                                <div
                                    className="manrope f-24 w-700"
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                    }}>

                                    Collaborations
                                    <span
                                        className="manrope f-20 ml-4 "
                                        style={{ paddingBottom: 2 }}>
                                        ({getCollaborationCount()} items)
                                    </span>
                                </div>
                            </Col>

                            <Col lg={8} xl={8}>
                                <Form>
                                    <Input.Group compact size="large" className="d-inline">
                                        <Input
                                            placeholder="Search Collaborations"
                                            value={searchValue}
                                            onChange={(e) => {
                                                changeSearchValue(e.target.value);
                                            }}
                                            className="manrope f-14 mat-search-bar"
                                        ></Input>
                                    </Input.Group>
                                </Form>
                            </Col>

                        </>
                    )}
                </Row>
                <div>
                    <div className="text-center"> {loader ? <DottedLoader /> : (
                        <Col span={24}>
                            <Tabs onChange={(key) => changeCollabTab(key, 'click')}
                                activeKey={tabActiveKey}
                                className="product-tab library"
                                tabBarGutter={16}
                                size="large">
                                {
                                   (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME) && 
                                   <TabPane forceRender={true} tab={`${MANAGED_CUSTOMER_USERNAME}'s Collaboration (${mspData.length})`} key={"msp"} style={{ padding: "10px" }}>
                                    {filteredMspData.length > 0 ?
                                            <CollaborationCard
                                                type='resolved'
                                                collaborations={filteredMspData}
                                                searchValue={searchValue}
                                                openMarkCollaborationAsResolved={openMarkCollaborationAsResolved}
                                                openDeleteCollaboration={openDeleteCollaboration}
                                                reopenCollab={reopenCollab}
                                                updateTabCount={updateTabCount}
                                            /> :
                                        <div className="empty-section" style={{ marginTop: "10%", position: "relative", textAlign: '-webkit-center' }}>
                                            <img id="empty-section-image" style={{ width: "10%" }} src="../../../../img/Spaces.svg" />
                                            <p className="empty-section-text manrope f-14 black-55">
                                                Nothing to show
                                            </p>
                                            <p style={{ marginTop: "-1%" }} className="empty-section-text manrope f-12 grey-6b">
                                                There are no resolved collaborations.
                                            </p>
                                        </div>
                                    }
                                </TabPane>}
                                <TabPane forceRender={true} tab={`Unresolved (${getCollabTabLength("unresolved")})`} key={"unresolved"} style={{ padding: "10px" }}>
                                    <>
                                    <Row>
                                        <div className="shared-models-tab manrope f-14 black-55 j-center-a-baseline">
                                            <span className="shared-models-tab-span">
                                                Show Items:
                                            </span>
                                            <Radio.Group onChange={onChangeSharedTabType} value={sharedCollabTabType}>
                                                <Radio value='all'>
                                                    All
                                                </Radio>
                                                <Radio value='product'>
                                                    Products
                                                </Radio>
                                                <Radio value='silo'>
                                                    Silo Images
                                                </Radio>
                                                <Radio value='scene'>
                                                    Scene Lifestyle Images
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </Row>
                                    {getUnresolvedCollab().length > 0 ?
                                                <CollaborationCard
                                                    type='unresolved'
                                                    collaborations={getUnresolvedCollab()}
                                                    searchValue={searchValue}
                                                    openMarkCollaborationAsResolved={openMarkCollaborationAsResolved}
                                                    openDeleteCollaboration={openDeleteCollaboration}
                                                    updateTabCount={updateTabCount}
                                                /> :
                                        <div className="empty-section" style={{ marginTop: "10%", position: "relative", textAlign: '-webkit-center' }}>
                                            <img id="empty-section-image" style={{ width: "10%" }} src="../../../../img/Spaces.svg" />
                                            <p className="empty-section-text manrope f-14 black-55">
                                                Nothing to show
                                            </p>
                                            <p style={{ marginTop: "-1%" }} className="empty-section-text manrope f-12 grey-6b">
                                                There are no unresolved collaborations.
                                            </p>
                                        </div>
                                    }
                                    </>
                                </TabPane>
                                <TabPane forceRender={true} tab={`Resolved (${getCollabTabLength("resolved")})`} key={"resolved"} style={{ padding: "10px" }}>
                                    {resolvedCollaborations.length > 0 ?
                                            <CollaborationCard
                                                type='resolved'
                                                collaborations={resolvedCollaborations}
                                                searchValue={searchValue}
                                                openMarkCollaborationAsResolved={openMarkCollaborationAsResolved}
                                                openDeleteCollaboration={openDeleteCollaboration}
                                                updateTabCount={updateTabCount}
                                                reopenCollab={reopenCollab}
                                            /> :
                                        <div className="empty-section" style={{ marginTop: "10%", position: "relative", textAlign: '-webkit-center' }}>
                                            <img id="empty-section-image" style={{ width: "10%" }} src="../../../../img/Spaces.svg" />
                                            <p className="empty-section-text manrope f-14 black-55">
                                                Nothing to show
                                            </p>
                                            <p style={{ marginTop: "-1%" }} className="empty-section-text manrope f-12 grey-6b">
                                                There are no resolved collaborations.
                                            </p>
                                        </div>
                                    }
                                </TabPane>
                            </Tabs>
                        </Col>
                    )}
                    </div>
                </div>
            </CustomerMainLayout>
            <CollaborationModals
                resolveConfirmModal={resolveConfirmModal}
                setResolveConfirmModal={setResolveConfirmModal}
                setSelectedCollab={setSelectedCollab}
                selectedCollab={selectedCollab}
                setDeleteConfirmModal={setDeleteConfirmModal}
                deleteConfirmModal={deleteConfirmModal}
                unresolveConfirmModal={unresolveConfirmModal}
                setUnresolveConfirmModal={setUnresolveConfirmModal}
            />
        </div>
    );
}


export default CustomerCollaborations;
